import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity64',
  templateUrl: './activity64.component.html',
  styleUrls: ['./activity64.component.css']
})
export class Activity64Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '64'; // se refiere al número de actividad
  activity: Activity = new Activity();
  $estados = [];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    var $items = $(".drag-64"), $drop = $(".drop-64");//variables usadas

    $items.draggable({
      revert: "invalid",
      stack: ".drag-64",//sobreponer imagen
      snap: ".drop-64",//ajustar a la imagen de encaje
      snapMode: "inner",//ajustar dentro del contorno
      snapTolerance: 40,//distancia de ajuste
    });


    $drop.droppable({
      activeClass: "ui-state-default",
      hoverClass: "ui-state-hover",
      drop: function (event, ui) {
        var $drag = ui.draggable.data("drag");//valor data draggable
        $drop = $(this).data("drop");//valor data draggable
        var $cont = ui.draggable.attr("Nm");

        if ($drop == $drag) {	//si valores del data.drag y data.drop son iguales
          ui.draggable.css("border", "solid green");//Estilo color true
          self.$estados[$cont - 1] = true;	//asigna en un array el true en su poscion segun el valor ejempl: data-drag="1"
          $(this).removeClass('drop-64');
          $(this).removeClass('ui-droppable');

        } else {
          ui.draggable.css("border", "solid red");//Estilo color false
          self.$estados[$cont - 1] = false;//asigna en un array el false en su poscion segun el valor ejempl: data-drag="1"
        }
        console.log(self.$estados);

      }
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var $finalizado = 0;//contador

    for (var i = 0; i < this.$estados.length; i++) {//recorrer todo el array segun la longitud de campos insertados
      if (this.$estados[i] == true) {// comprar si alguno es true
        $finalizado++;// si es verdadero sumar el contador 
      }
    }

    if ($finalizado == 8) {// si el contador es igual a 4 ya son 4 true
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else {
      $('#modalFail').modal('show');
      $('.ayudas').text('Verifica que esten las lamparas esten ubicadas correctamente.');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
