import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity65',
  templateUrl: './activity65.component.html',
  styleUrls: ['./activity65.component.css']
})
export class Activity65Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '65'; // se refiere al número de actividad
  activity: Activity = new Activity();
  arreglo1 = [];
  arreglo2 = [];
  resp12 = ['F', 'F', 'V', 'F'];
  resp13 = ["Padre", "secreto", "Padre", "secreto", "recompensará", "público"];

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;

    function verificarDRAG(ui) {
      var text = ui.draggable.attr("id");
      if (text === "drag1") {
        return "V";
      } else if (text === "drag2") {
        return "F";
      }
    }
    function verificarDRAG1(ui) {
      var text = ui.draggable.attr("id");
      if (text === "drag3") {
        return "Padre";
      } else if (text === "drag4") {
        return "secreto";
      } else if (text === "drag5") {
        return "recompensará";
      } else if (text === "drag6") {
        return "público";
      }
    }
    $(".draggable-4").draggable({ containment: "#containment-wrapper", scroll: false });
    $(".draggable-41").draggable({ containment: "#containment-wrapper", scroll: false });

    $('.draggable-4').draggable({ revert: "valid" });
    $('.draggable-41').draggable({ revert: "valid" });
    $('.draggable-42').draggable({ revert: true });

    $('.droppable-7').droppable({
      hoverClass: 'active',
      drop: function (e, ui) {
        var temp = $(this).attr('id');

        if (verificarDRAG(ui) == "F") {
          $(this).hide("explode", { pieces: 25 }, 400);
          $(this).show("explode", { pieces: 4 }, 800);
          $(this).switchClass("cuadrosFALSE", "cuadrosTRUE", 1000);
        } else {
          $(this).hide("explode", { pieces: 4 }, 400);
          $(this).show("explode", { pieces: 25 }, 800);
          $(this).switchClass("cuadrosTRUE", "cuadrosFALSE", 1000);
        }

        if (temp === "drop1") {
          var inputNombre = $('#nombre1');

          var temp1 = verificarDRAG(ui);
          inputNombre.val(temp1);
          self.arreglo1[0] = temp1;

        } else if (temp === "drop2") {
          var inputNombre = $('#nombre2');
          var temp1 = verificarDRAG(ui);
          inputNombre.val(temp1);
          self.arreglo1[1] = temp1;

        } else if (temp === "drop3") {
          var inputNombre = $('#nombre3');

          var temp1 = verificarDRAG(ui);
          inputNombre.val(temp1);
          self.arreglo1[2] = temp1;

        } else if (temp === "drop4") {
          var inputNombre = $('#nombre4');
          var temp1 = verificarDRAG(ui);
          inputNombre.val(temp1);
          self.arreglo1[3] = temp1;
        }
      }
    });
    $('.droppable-72').droppable({
      hoverClass: 'active',
      drop: function (e, ui) {
        var temp = $(this).attr('id');

        $(this).effect("highlight", { color: "#17a2b8" }, 2000,
          function () { $(this).css("border-radius", "100px") }
        );
        if (temp === "drop5") {
          var inputNombre = $('#nombre5');
          var temp1 = verificarDRAG1(ui);
          inputNombre.val(temp1);
          self.arreglo2[0] = temp1;

          $(inputNombre).css("border-radius", "100px");
          $(inputNombre).css("background-color", "#17a2b8");

        } else if (temp === "drop6") {
          var inputNombre = $('#nombre6');

          var temp1 = verificarDRAG1(ui);
          inputNombre.val(temp1);
          self.arreglo2[1] = temp1;

          $(inputNombre).css("border-radius", "100px");
          $(inputNombre).css("background-color", "#17a2b8");
        } else if (temp === "drop7") {
          var inputNombre = $('#nombre7');

          var temp1 = verificarDRAG1(ui);
          inputNombre.val(temp1);
          self.arreglo2[2] = temp1;

          $(inputNombre).css("border-radius", "100px");
          $(inputNombre).css("background-color", "#17a2b8");
        } else if (temp === "drop8") {
          var inputNombre = $('#nombre8');

          var temp1 = verificarDRAG1(ui);
          inputNombre.val(temp1);
          self.arreglo2[3] = temp1;

          $(inputNombre).css("border-radius", "100px");
          $(inputNombre).css("background-color", "#17a2b8");
        } else if (temp === "drop9") {
          var inputNombre = $('#nombre9');

          var temp1 = verificarDRAG1(ui);
          inputNombre.val(temp1);
          self.arreglo2[4] = temp1;

          $(inputNombre).css("border-radius", "100px");
          $(inputNombre).css("background-color", "#17a2b8");
        } else if (temp === "drop10") {
          var inputNombre = $('#nombre10');

          var temp1 = verificarDRAG1(ui);
          inputNombre.val(temp1);
          self.arreglo2[5] = temp1;

          $(inputNombre).css("border-radius", "100px");
          $(inputNombre).css("background-color", "#17a2b8");
        }
      }
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    console.log(this.arreglo1)
    console.log(this.arreglo2);


    var iguales = 0;
    var iguales1 = 0;
    var iguales2 = 0;


    if (this.resp12[0] == this.arreglo1[0]) {
      iguales++;
    }

    if (this.resp12[1] == this.arreglo1[1]) {
      iguales++;
    }

    if (this.resp12[2] == this.arreglo1[2]) {
      iguales++;
    }

    if (this.resp12[3] == this.arreglo1[3]) {
      iguales++;
    }
    //--------------------------------/
    if (this.resp13[0] == this.arreglo2[0]) {
      iguales1++;
    }

    if (this.resp13[1] == this.arreglo2[1]) {
      iguales1++;
    }

    if (this.resp13[2] == this.arreglo2[2]) {
      iguales1++;
    }

    if (this.resp13[3] == this.arreglo2[3]) {
      iguales1++;
    }

    if (this.resp13[4] == this.arreglo2[4]) {
      iguales1++;
    }

    if (this.resp13[5] == this.arreglo2[5]) {
      iguales1++;
    }


    if (iguales < 4) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('verifica que las afirmaciones falsas y verdaderas sean las correctas. ' + iguales + '/4');

      if (iguales1 < 6) {
        $('#modalFail').modal('show');
        $('.ayudas').css('font-size', "20px")
        $('.ayudas').text('verifica que las frases del verso (6) Seis sean las correctas. ' + iguales1 + '/6');
      }
    }

    if ((iguales == 4) && (iguales1 == 6)) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
