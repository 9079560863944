import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Heroe } from 'src/app/models/heroe';
import { User } from 'src/app/models/user.model';
import { ConstantsService } from 'src/app/services/constants.service';
import { HeroeService } from 'src/app/services/heroe.service';
import { UserService } from 'src/app/services/user.service';
declare var $: any;

@Component({
  selector: 'app-choose-heroe',
  templateUrl: './choose-heroe.component.html',
  styleUrls: ['./choose-heroe.component.css']
})
export class ChooseHeroeComponent implements OnInit {
  public user = new User();
  heroes: Heroe[] = [];
  loading: boolean = true;
  // Slides
  slides;
  current = 0;
  prev = 3;
  next = 1;

  @Input() heroe: Heroe;
  displayErros = "none";
  avatars: {nameFile:string}[];
  idAchievement: string = "";
  
  constructor(private _route: ActivatedRoute,
    public _constants: ConstantsService,
    public _heroeService: HeroeService, 
    public afAuth: AngularFireAuth, 
    public _userService: UserService,
    private router: Router) {
      this.avatars = _constants.avatars;
      this.idAchievement = this._route.snapshot.paramMap.get('id');
    }

  ngOnInit() {
    this.slides = document.querySelectorAll(".item");
    let uid = this.afAuth.auth.currentUser.uid;
    
    this._userService.getUser(uid).subscribe(val => {
      this.user = val.data() as User;
    });

    this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
      this.heroes = val;
      this.loading = false;
    });
  }

  ngOnChanges({ heroe: { currentValue } }: SimpleChanges) {
    this.avatars.forEach((item,index) => item.nameFile === currentValue.nameFileAvatar ? this.current = index : null);
  }

  redirect(idHero: string){
    let url = this.router.url;
    if(this.idAchievement !== null){
      this.router.navigate(['/achievement',this.idAchievement , idHero]);  
    }
    else if(this.router.url.indexOf('activity') !== -1){
      this.router.navigate([url , idHero]); 
    }
    else{
      this.router.navigate(['/dashboard']);
    }
  }

}
