import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { UserService } from "src/app/services/user.service";
import { NgForm } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ConstantsService } from 'src/app/services/constants.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  user : User;
  displayErros = "none";
  countries

  constructor(public _userService: UserService, 
    public _constants: ConstantsService,
    private router: Router,
    public afAuth: AngularFireAuth
    ) { 
      this.countries = _constants.countries;
      this.user = new User();
      //precargamos los datos de la cuenta en el formulario de registro
      this.afAuth.user.subscribe(val =>{
        
        if(val){
          this.user.uid = val.uid;

          let splitted = val.displayName.split(" "); 
          let name = "Nombre";
          let lastName = "Apellido";

          if(splitted.length == 4){
            name = splitted[0]+' '+splitted[1];
            lastName = splitted[2]+' '+splitted[3];
          }else{
            name = splitted[0];
            lastName = splitted[1];
          }
          
          this.user.name = name;
          this.user.lastName = lastName;

          this.user.email = val.email;
          this.user.urlAvatar = val.photoURL;
          this.user.terms = false;
          this.user.methodAuthenticate = val.providerData[0].providerId;

        }  
      });
      
  }

  ngOnInit() {
  }

  saveUser(form:NgForm){
    
    if(form.invalid || this.user.terms==false){
      this.displayErros="initial";
      return ;
    }
    
    this._userService.createUser(this.user).then(() => {
      this.router.navigate(['/dashboard']);
    });
  }

}
