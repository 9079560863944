import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity7',
  templateUrl: './activity7.component.html',
  styleUrls: ['./activity7.component.css']
})
export class Activity7Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '7'; // se refiere al número de actividad
  activity: Activity = new Activity();
  contador: number = 0;
  parejaAnterior: string = '';
  parejas: number = 0;

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    let self = this;
    $(document).ready(function () {
      $(".back").on("click", function () {
        self.contador++;
        if(self.contador === 1){
          let pareja = $(this).parent().attr('class').replace('pareja','');
          $('.pareja' + pareja + ' .back').hide();
          $('.pareja' + pareja + ' .front').show();
          console.log(pareja);
          self.parejaAnterior = pareja;
        }
        else{
          self.contador = 0;
            let pareja = $(this).parent().attr('class').replace('pareja','');
            $('.pareja' + pareja + ' .back').hide();
            $('.pareja' + pareja + ' .front').show();
            console.log(pareja);
          setTimeout(function () {
            if((pareja === '1' && self.parejaAnterior === '10' || pareja === '10' && self.parejaAnterior === '1') || 
            (pareja === '2' && self.parejaAnterior === '9' || pareja === '9' && self.parejaAnterior === '2') ||
            (pareja === '3' && self.parejaAnterior === '8' || pareja === '8' && self.parejaAnterior === '3') ||
            (pareja === '4' && self.parejaAnterior === '7' || pareja === '7' && self.parejaAnterior === '4') ||
            (pareja === '5' && self.parejaAnterior === '6' || pareja === '6' && self.parejaAnterior === '5')
            ){
              self.parejas++;
            }
            else{
              $('.pareja' + pareja + ' .back').show();
              $('.pareja' + pareja + ' .front').hide();
              $('.pareja' + self.parejaAnterior + ' .back').show();
              $('.pareja' + self.parejaAnterior + ' .front').hide();
            }
          }, 500);
        }
        
      });
    });
  }
  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    if(this.parejas === 5){
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else{
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad descubriendo todas las parejas');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
