import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity43',
  templateUrl: './activity43.component.html',
  styleUrls: ['./activity43.component.css']
})
export class Activity43Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '43'; // se refiere al número de actividad
  activity: Activity = new Activity();
  
  lstestados:any;
  

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    //Todos los Blockques crea y asigna un clase 'Active:flase'
    $(".block-alfarero").attr('active', 'false');
    //funcion de cliquear un bloque - color y Guardamos el estado
    $(".block-alfarero").click(function () {
      var estado = $(this).attr('active');
      //si el estado es false
      if (estado == 'false') {
        $(this).css("background-color", "#e3b631");
        $(this).attr('active', 'true');
      } else {
        //si el estado es true
        if (estado == 'true') {
          $(this).css("background-color", "");
          $(this).attr('active', 'false');
        }
      }
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var estados = [];
    var completado = 0;
    var contador = 0;
    var correctas = ['false', 'true', 'false', 'true', 'true', 'false', 'false', 'true'];
    $('.block-alfarero').each(function(i) {     
     
      this.lstestados = $(this).attr('active');
      estados[i] = this.lstestados;
      //console.log(estados);
      if (estados[i] == correctas[i] && estados[i] == 'true') {
        $(this).css("background-color", "#4caf50");
        completado++;
      } else {
        $(this).css("background-color", "red ");

      }
    });
    if (completado == 4) {
      $('.block-alfarero[active="false"]').css('background-color', '#ffffff38');
      $('#modalOk').modal('show');
      this.guardarActividad()
    }
    else {
      $('#modalFail').modal('show');
      $('.ayudas').text('Verifica que seleccionaste todos los correctos.');
    }
    ;
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
