import { Component, OnInit } from '@angular/core';
import { Heroe } from "src/app/models/heroe";
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { HeroeService } from 'src/app/services/heroe.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { ConstantsService } from 'src/app/services/constants.service';
declare var $: any;
@Component({
  selector: 'app-new-heroe',
  templateUrl: './new-heroe.component.html',
  styleUrls: ['./new-heroe.component.css']
})
export class NewHeroeComponent implements OnInit {

  // Slides
  slides;
  current = 0;
  prev = 3;
  next = 1;



  heroe = new Heroe();
  displayErros = "none";

  avatars;

  constructor(public _heroeService: HeroeService,
    private router: Router,
    public _constants: ConstantsService,
    public afAuth: AngularFireAuth
  ) {
    this.avatars = _constants.avatars;
  }

  ngOnInit() {
    this.current = 0;
    this.prev = 3;
    this.next = 1;

    this.heroe.nameFileAvatar = this.avatars[this.current].nameFile;

    this.slides = document.querySelectorAll(".item");
  }

  save(form: NgForm) {

    if (form.invalid) {
      this.displayErros = "initial";
      return;
    }

    this.heroe.uid_user = this.afAuth.auth.currentUser.uid;
    if (this.heroe.name == "") {
      this.router.navigate(['/dashboard']);
      $('#exampleModalCenter2').modal('hide');
    }
    this._heroeService.create(this.heroe).then(() => {
      this.heroe = new Heroe();
      this.current = 0;
      this.prev = 3;
      this.next = 1;
      this.heroe.nameFileAvatar = this.avatars[this.current].nameFile;
      this.router.navigate(['/dashboard']);
      $('#exampleModalCenter2').modal('hide');
    });

  }

  // Go to prev slide
  gotoPrev = () => this.current > 0 ? this.gotoNum(this.current - 1) : this.gotoNum(this.slides.length - 1);

  // Go to next slide
  gotoNext = () => this.current < 3 ? this.gotoNum(this.current + 1) : this.gotoNum(0);

  private gotoNum(number) {
    this.current = number;
    this.prev = this.current - 1;
    this.next = this.current + 1;

    for (let i = 0; i < this.slides.length; i++) {
      this.slides[i].classList.remove("active");
      this.slides[i].classList.remove("prev");
      this.slides[i].classList.remove("next");
    }

    if (this.next == 4) {
      this.next = 0;
    }

    if (this.prev == -1) {
      this.prev = 3;
    }

    this.slides[this.current].classList.add("active");
    this.slides[this.prev].classList.add("prev");
    this.slides[this.next].classList.add("next");

    this.heroe.nameFileAvatar = this.avatars[this.current].nameFile;
  }

}
