import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { LoginComponent } from './login/login.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HeaderComponent } from './shared/header/header.component';
import { PagesComponent } from './pages/pages.component';

import { AngularFireAuthGuard} from '@angular/fire/auth-guard';
import { RegisterComponent } from './pages/register/register.component';
import { UserService } from './services/user.service';
import { HeroeService } from './services/heroe.service';
import { HomeComponent } from './pages/home/home.component';
import { NewHeroeComponent } from './pages/new-heroe/new-heroe.component';
import { EditHeroeComponent } from './pages/edit-heroe/edit-heroe.component';
import { EditUserComponent } from './pages/edit-user/edit-user.component';
import { StatusHeroeComponent } from './pages/status-heroe/status-heroe.component';
import { TerminosycondicionesComponent } from './terminosycondiciones/terminosycondiciones.component';
import { RegisterEmailComponent } from './register-email/register-email.component';
import { LostPasswordComponent } from './lost-password/lost-password.component';
import { ActivityComponent } from './pages/activity/activity.component';
import { ChooseHeroeComponent } from './shared/choose-heroe/choose-heroe.component';
import { AchievementsComponent } from './pages/achievements/achievements.component';
import { Activity1Component } from './pages/activities/activity1/activity1.component';
import { Activity2Component } from './pages/activities/activity2/activity2.component';
import { Activity3Component } from './pages/activities/activity3/activity3.component';
import { Activity4Component } from './pages/activities/activity4/activity4.component';
import { Activity5Component } from './pages/activities/activity5/activity5.component';
import { Activity6Component } from './pages/activities/activity6/activity6.component';
import { Activity7Component } from './pages/activities/activity7/activity7.component';
import { Activity8Component } from './pages/activities/activity8/activity8.component';
import { Activity9Component } from './pages/activities/activity9/activity9.component';
import { Activity10Component } from './pages/activities/activity10/activity10.component';
import { Activity11Component } from './pages/activities/activity11/activity11.component';
import { Activity12Component } from './pages/activities/activity12/activity12.component';
import { Activity13Component } from './pages/activities/activity13/activity13.component';
import { Activity14Component } from './pages/activities/activity14/activity14.component';
import { Activity15Component } from './pages/activities/activity15/activity15.component';
import { Activity16Component } from './pages/activities/activity16/activity16.component';
import { Activity17Component } from './pages/activities/activity17/activity17.component';
import { Activity18Component } from './pages/activities/activity18/activity18.component';
import { Activity19Component } from './pages/activities/activity19/activity19.component';
import { Activity20Component } from './pages/activities/activity20/activity20.component';
import { Activity21Component } from './pages/activities/activity21/activity21.component';
import { Activity22Component } from './pages/activities/activity22/activity22.component';
import { Activity23Component } from './pages/activities/activity23/activity23.component';
import { Activity24Component } from './pages/activities/activity24/activity24.component';
import { Activity25Component } from './pages/activities/activity25/activity25.component';
import { Activity26Component } from './pages/activities/activity26/activity26.component';
import { Activity27Component } from './pages/activities/activity27/activity27.component';
import { Activity28Component } from './pages/activities/activity28/activity28.component';
import { Activity29Component } from './pages/activities/activity29/activity29.component';
import { Activity30Component } from './pages/activities/activity30/activity30.component';
import { Activity31Component } from './pages/activities/activity31/activity31.component';
import { Activity32Component } from './pages/activities/activity32/activity32.component';
import { Activity33Component } from './pages/activities/activity33/activity33.component';
import { Activity34Component } from './pages/activities/activity34/activity34.component';
import { Activity35Component } from './pages/activities/activity35/activity35.component';
import { Activity36Component } from './pages/activities/activity36/activity36.component';
import { Activity37Component } from './pages/activities/activity37/activity37.component';
import { Activity38Component } from './pages/activities/activity38/activity38.component';
import { Activity39Component } from './pages/activities/activity39/activity39.component';
import { Activity40Component } from './pages/activities/activity40/activity40.component';
import { Activity41Component } from './pages/activities/activity41/activity41.component';
import { Activity42Component } from './pages/activities/activity42/activity42.component';
import { Activity43Component } from './pages/activities/activity43/activity43.component';
import { Activity44Component } from './pages/activities/activity44/activity44.component';
import { Activity45Component } from './pages/activities/activity45/activity45.component';
import { Activity46Component } from './pages/activities/activity46/activity46.component';
import { Activity47Component } from './pages/activities/activity47/activity47.component';
import { Activity48Component } from './pages/activities/activity48/activity48.component';
import { Activity49Component } from './pages/activities/activity49/activity49.component';
import { Activity50Component } from './pages/activities/activity50/activity50.component';
import { Activity51Component } from './pages/activities/activity51/activity51.component';
import { Activity52Component } from './pages/activities/activity52/activity52.component';
import { Activity53Component } from './pages/activities/activity53/activity53.component';
import { Activity54Component } from './pages/activities/activity54/activity54.component';
import { Activity55Component } from './pages/activities/activity55/activity55.component';
import { Activity56Component } from './pages/activities/activity56/activity56.component';
import { Activity57Component } from './pages/activities/activity57/activity57.component';
import { Activity58Component } from './pages/activities/activity58/activity58.component';
import { Activity59Component } from './pages/activities/activity59/activity59.component';
import { Activity60Component } from './pages/activities/activity60/activity60.component';
import { Activity61Component } from './pages/activities/activity61/activity61.component';
import { Activity62Component } from './pages/activities/activity62/activity62.component';
import { Activity63Component } from './pages/activities/activity63/activity63.component';
import { Activity64Component } from './pages/activities/activity64/activity64.component';
import { Activity65Component } from './pages/activities/activity65/activity65.component';
import { Activity66Component } from './pages/activities/activity66/activity66.component';
import { Activity67Component } from './pages/activities/activity67/activity67.component';
import { Activity68Component } from './pages/activities/activity68/activity68.component';
import { Activity69Component } from './pages/activities/activity69/activity69.component';
import { Activity70Component } from './pages/activities/activity70/activity70.component';
import { Activity71Component } from './pages/activities/activity71/activity71.component';
import { Activity72Component } from './pages/activities/activity72/activity72.component';
import { Activity73Component } from './pages/activities/activity73/activity73.component';
import { Activity74Component } from './pages/activities/activity74/activity74.component';
import { Activity75Component } from './pages/activities/activity75/activity75.component';
import { Activity76Component } from './pages/activities/activity76/activity76.component';
import { Activity77Component } from './pages/activities/activity77/activity77.component';
import { Activity78Component } from './pages/activities/activity78/activity78.component';
import { Activity79Component } from './pages/activities/activity79/activity79.component';
import { Activity80Component } from './pages/activities/activity80/activity80.component';
import { Activity81Component } from './pages/activities/activity81/activity81.component';
import { Activity82Component } from './pages/activities/activity82/activity82.component';
import { Activity83Component } from './pages/activities/activity83/activity83.component';
import { Activity84Component } from './pages/activities/activity84/activity84.component';
import { Activity85Component } from './pages/activities/activity85/activity85.component';
import { Activity86Component } from './pages/activities/activity86/activity86.component';
import { Activity87Component } from './pages/activities/activity87/activity87.component';
import { Activity88Component } from './pages/activities/activity88/activity88.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NopagefoundComponent,
    DashboardComponent,
    HeaderComponent,
    PagesComponent,
    RegisterComponent,
    HomeComponent,
    NewHeroeComponent,
    EditHeroeComponent,
    EditUserComponent,
    StatusHeroeComponent,
    TerminosycondicionesComponent,
    RegisterEmailComponent,
    LostPasswordComponent,
    ActivityComponent,
    ChooseHeroeComponent,
    AchievementsComponent,
    Activity1Component,
    Activity2Component,
    Activity3Component,
    Activity4Component,
    Activity5Component,
    Activity6Component,
    Activity7Component,
    Activity8Component,
    Activity9Component,
    Activity10Component,
    Activity11Component,
    Activity12Component,
    Activity13Component,
    Activity14Component,
    Activity15Component,
    Activity16Component,
    Activity17Component,
    Activity18Component,
    Activity19Component,
    Activity20Component,
    Activity21Component,
    Activity22Component,
    Activity23Component,
    Activity24Component,
    Activity25Component,
    Activity26Component,
    Activity27Component,
    Activity28Component,
    Activity29Component,
    Activity30Component,
    Activity31Component,
    Activity32Component,
    Activity33Component,
    Activity34Component,
    Activity35Component,
    Activity36Component,
    Activity37Component,
    Activity38Component,
    Activity39Component,
    Activity40Component,
    Activity41Component,
    Activity42Component,
    Activity43Component,
    Activity44Component,
    Activity45Component,
    Activity46Component,
    Activity47Component,
    Activity48Component,
    Activity49Component,
    Activity50Component,
    Activity51Component,
    Activity52Component,
    Activity53Component,
    Activity54Component,
    Activity55Component,
    Activity56Component,
    Activity57Component,
    Activity58Component,
    Activity59Component,
    Activity60Component,
    Activity61Component,
    Activity62Component,
    Activity63Component,
    Activity64Component,
    Activity65Component,
    Activity66Component,
    Activity67Component,
    Activity68Component,
    Activity69Component,
    Activity70Component,
    Activity71Component,
    Activity72Component,
    Activity73Component,
    Activity74Component,
    Activity75Component,
    Activity76Component,
    Activity77Component,
    Activity78Component,
    Activity79Component,
    Activity80Component,
    Activity81Component,
    Activity82Component,
    Activity83Component,
    Activity84Component,
    Activity85Component,
    Activity86Component,
    Activity87Component,
    Activity88Component
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // for database features
    AngularFireAuthModule, // for auth features,
    AngularFireStorageModule, // for storage features
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [AngularFireAuthGuard, UserService, HeroeService],
  bootstrap: [AppComponent]
})
export class AppModule { }
