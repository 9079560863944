import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity59',
  templateUrl: './activity59.component.html',
  styleUrls: ['./activity59.component.css']
})
export class Activity59Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '59'; // se refiere al número de actividad
  activity: Activity = new Activity();

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;

    $('input[type=number][max]:not([max=""])').on('input', function (ev) {
      var $this = $(this);
      var maxlength = $this.attr('max').length;
      var value = $this.val();
      if (value && value.length >= maxlength) {
        $this.val(value.substr(0, maxlength));
      }
    });
    $(".frase").on("keyup ", function () {
      var seleccion;
      var lstfrase;
      lstfrase = $(this).val().toLowerCase();
      seleccion = $(this).attr('seleccion');
      seleccion = seleccion - 1;

      var myArray = ['5', '4', '7', '8', '2', '6', '3', '1'];

      for (var j = seleccion; j < (seleccion + 1); j++) {
        if (lstfrase == myArray[j]) {
          $(this).css({ "background-color": "green", "color": "white" });

        } else {
          $(this).css({ "background-color": "white", "color": "black" });
        }
      }
    })
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var $finalizado = 0;//contador

    var lstfrase = $('.frase');
    var arrayGuardar = [];
    for (var i = 0; i < lstfrase.length; i++) {
      arrayGuardar[i] = lstfrase[i].value.toLowerCase();
    }
    var myArray = ['5', '4', '7', '8', '2', '6', '3', '1'];
    var iguales = 0;
    for (var i = 0; i < arrayGuardar.length; i++) {
      for (var j = i; j < (i + 1); j++) {
        if (arrayGuardar[i] == myArray[i])
          iguales++;

      }
    }



    if (iguales == 8) {// si el contador es igual a 4 ya son 4 true
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else {
      $('#modalFail').modal('show');
      $('.ayudas').text('Verifica que contestaste todo correctamente.');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
