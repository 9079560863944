import { Component, OnInit } from '@angular/core';
import { Heroe } from 'src/app/models/heroe';
import { AngularFireAuth } from '@angular/fire/auth';
import { HeroeService } from 'src/app/services/heroe.service';
import { UserService } from "src/app/services/user.service";
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public user = new User();
  heroes: Heroe[] = [];
  heroeToEdit: Heroe = new Heroe();
  loading: boolean = true;
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private router: Router,
    public _userService: UserService
  ) {

  }

  ngOnInit() {
    document.body.classList.add('home-Inicio')
    let uid = this.afAuth.auth.currentUser.uid;
    
    this._userService.getUser(uid).subscribe(val => {
      this.user = val.data() as User;
    });

    this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
      this.heroes = val;
      this.loading = false;
    });
  }

  deleteHeroe(heroe: Heroe, i: number) {
    swal.fire({
      icon: "warning",
      text: `Estas seguro que deseas eliminar el Heroe: ${heroe.name}?`,
      showConfirmButton: true,
      showCancelButton: true
    }).then(resp => {
      if (resp.value) {
        this.heroes.splice(i, 1);
        this._heroeService.deleteHeroe(heroe.id);
      }
    });

  }
  logout() {
    /* swal.fire({
      icon:"warning",
      text:`Estas seguro que deseas cerrar session?`,
      showConfirmButton: true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        this.afAuth.auth.signOut().then( val => this.router.navigate(['/login'])  );
      }
    }); */
    this.afAuth.auth.signOut().then(val => this.router.navigate(['/login']));
  }

}
