import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity61',
  templateUrl: './activity61.component.html',
  styleUrls: ['./activity61.component.css']
})
export class Activity61Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '61'; // se refiere al número de actividad
  activity: Activity = new Activity();
  ArregloResp = ["0", "0", "0", "0"];
  Resp = ['drag4', 'drag1', 'drag3', 'drag2'];
  eventsClick = 0;
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    var completo = false;
    var iguales = 0;   
    let EsferaSelect = '';
    var arrayGuardar = [];

    $('.esfera-samuel1').click(function () {
      iguales = 0;

      if (self.eventsClick < 20) {
        EsferaSelect = $(this).attr('valor-esfera');
        console.log(EsferaSelect);

        self.eventsClick++;
        for (var i = (self.eventsClick - 1); i < self.eventsClick; i++) {
          arrayGuardar[i] = EsferaSelect;
        }
        var myArray = ['1', '4', '6', '7', '8', '9', '10', '12', '14', '16', '18', '19', '20', '21', '22', '23', '26', '27'];

        for (var j = (self.eventsClick - 1); j < self.eventsClick; j++) {

          if (arrayGuardar[j] == myArray[j]) {
            $(this).css("background-color", "#4caf50");
          } else {
            $(this).css("background-color", "red");
          }
        }
        $('.text-samuel').val(arrayGuardar.join(' '));
      }
    })

    $('#limpiar').click(function () {
      self.eventsClick = 0;
      EsferaSelect = '';
      $('.esfera-samuel1').css("background-color", "blanchedalmond");
      arrayGuardar = [];
      $('.text-samuel1').val('');

      $("#drag1").removeClass("newText");
      $("#drag2").removeClass("newText");
      $("#drag3").removeClass("newText");
      $("#drag4").removeClass("newText");

      $('.draggable-42').draggable("enable");
      $('.draggable-42').css({ 'left': "", 'top': "" });

    })
    $('.draggable-41').draggable({ revert: true });
    $('.draggable-42').draggable({ revert: false });

    $('.droppable-72').droppable({
      accept: '.draggable-42',
      hoverClass: 'active',
      drop: function (e, ui) {
        var temp = $(this).attr('id');
        var text = ui.draggable.attr("id");
        console.log(text);

        $(ui.draggable).draggable("disable");

        $(this).effect("highlight", { color: "#17a2b8" }, 2000,
          function () { $(this).css("border-radius", "100px") }
        );

        $(ui.draggable).addClass("newText");

        if (temp === "drop1") {
          self.ArregloResp[0] = text;

        } else if (temp === "drop2") {

          self.ArregloResp[1] = text;

        } else if (temp === "drop3") {

          self.ArregloResp[2] = text;

        } else if (temp === "drop4") {
          self.ArregloResp[3] = text;
        }
      }
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    console.log(this.ArregloResp)
    var iguales = 0;
    if (this.ArregloResp[0] === this.Resp[0]) {
      iguales++;
    }
    if (this.ArregloResp[1] === this.Resp[1]) {
      iguales++;
    }
    if (this.ArregloResp[2] === this.Resp[2]) {
      iguales++;
    }
    if (this.ArregloResp[3] === this.Resp[3]) {
      iguales++;
    }

    if (this.eventsClick < 18) {
      $('#modalFail').modal('show');
      $('.ayudas').text('Organiza bien los pasos que debe seguir Jésus. ' + this.eventsClick + '  /18');
    } else if (iguales < 4) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica que las frases coincidan con las imágenes. ' + iguales + '  /4');
    }


    if (this.eventsClick == 18 && iguales == 4) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }

  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
