import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity33',
  templateUrl: './activity33.component.html',
  styleUrls: ['./activity33.component.css']
})
export class Activity33Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '33'; // se refiere al número de actividad
  activity: Activity = new Activity();
  textos = ["", "", "", "", ""];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    var r1 = "";
    var r2 = "";
    var r3 = "";
    var r4 = "";
    var r5 = "";

    var testBandera1 = "Explicaban al pueblo el sentido de la ley";
    var testBandera2 = "Compartir con los que no tienen";
    var testBandera3 = "¡Amén!   ¡Amén!";
    var testBandera4 = "Bendijo la grandeza del Señor";
    var testBandera5 = "La ley de Moisés";

    var activado = [0, 0, 0, 0, 0];
    var confirmadas = [false, false, false, false, false];
    

    $.widget("custom.colorize", {
      options: {
        red: 30,
        green: 20,
        blue: 0,
        change: null,
        random: null
      },

      _create: function () {
        this.element
          .addClass("custom-colorize")
          .disableSelection();

        this.changer = $("<button>", {
          text: "@",
          "class": "custom-colorize-changer"
        })
          .appendTo(this.element)
          .button();
        this._on(this.changer, {
          click: "random"
        });

        this._refresh();
      },
      _refresh: function () {
        this.element.css("background-color", "rgb(" +
          this.options.red + "," +
          this.options.green + "," +
          this.options.blue + ")"
        );
        this._trigger("click");
      },

      random: function (event) {
        clean();

        var colors;
        var elem = this.options.red;
        var msg = "";
        let number = this.element.attr("id");
        let num = number[9];

        if (num == 1) {
          msg = testBandera1;
        } else if (num == 2) {
          msg = testBandera2;
        } else if (num == 3) {
          msg = testBandera3;
        } else if (num == 4) {
          msg = testBandera4;
        } else if (num == 5) {
          msg = testBandera5;
        }
        if (activado[num - 1] == 0) {

          activado[num - 1] = activado[num - 1] + 1;
          if (confirmadas[0] == false) {
            colors = {
              red: 3,
              green: 40,
              blue: 252
            };
          }
          if (confirmadas[0] == false) {

            $("#select-result1").text(msg);
            self.textos[0] = msg;


          } else {
            r1 = self.textos[0];
          }


        } else if (activado[num - 1] == 1) {

          activado[num - 1] = activado[num - 1] + 1;
          if (confirmadas[1] == false) {
            colors = {
              red: 138,
              green: 98,
              blue: 23
            };
          }
          if (confirmadas[1] == false) {
            $("#select-result2").text(msg);
            self.textos[1] = msg;
          } else {
            r2 = self.textos[1];
          }


        } else if (activado[num - 1] == 2) {

          activado[num - 1] = activado[num - 1] + 1;
          if (confirmadas[2] == false) {
            colors = {
              red: 224,
              green: 9,
              blue: 214
            };
          }

          if (confirmadas[2] == false) {
            $("#select-result3").text(msg);
            self.textos[2] = msg;
          } else {
            r3 = self.textos[2];
          }

        } else if (activado[num - 1] == 3) {

          activado[num - 1] = activado[num - 1] + 1;
          if (confirmadas[3] == false) {
            colors = {
              red: 5,
              green: 92,
              blue: 72
            };
          }
          if (confirmadas[3] == false) {
            $("#select-result4").text(msg);
            self.textos[3] = msg;

          } else {
            r4 = self.textos[3];
          }

        } else if (activado[num - 1] == 4) {

          activado[num - 1] = 0;
          if (confirmadas[4] == false) {
            colors = {
              red: 255,
              green: 60,
              blue: 51
            };
          }
          if (confirmadas[4] == false) {

            $("#select-result5").text(msg);
            self.textos[4] = msg;

          } else {
            r5 = self.textos[4];
          }
        }

        if (this._trigger("random", event, colors) !== false) {
          this.option(colors);
        }

      },
      _destroy: function () {
        this.changer.remove();
        this.element
          .removeClass("custom-colorize")
          .enableSelection()
          .css("background-color", "transparent");
      },
      _setOptions: function () {
        this._superApply(arguments);
        this._refresh();
      },
      _setOption: function (key, value) {
        this._super(key, value);
      }
    });

    $("#my-widget1").colorize();
    $("#my-widget2").colorize();
    $("#my-widget3").colorize();
    $("#my-widget4").colorize();
    $("#my-widget5").colorize();

    function clean() {
      r1 = "";
      r2 = "";
      r3 = "";
      r4 = "";
      r5 = "";

      if (confirmadas[0]) {
        r1 = self.textos[0];
      }
      if (confirmadas[1]) {
        r2 = self.textos[1];
      }
      if (confirmadas[2]) {
        r3 = self.textos[2];
      }
      if (confirmadas[3]) {
        r4 = self.textos[3];
      }
      if (confirmadas[4]) {
        r5 = self.textos[4];
      }

      $("#select-result1").text(r1);
      $("#select-result2").text(r2);
      $("#select-result3").text(r3);
      $("#select-result4").text(r4);
      $("#select-result5").text(r5);
    };

    $("#b1").click(function () {


      if (confirmadas[0]) {
        confirmadas[0] = false;
      }
      else {
        confirmadas[0] = true;
        self.textos[0];
      }


      if (confirmadas[0]) {
        $(this).text("confirmado");
      }
      else {
        $(this).text("Confirmar Respuesta")
        self.textos[0] = "";
      }

      clean();
    });

    $("#b2").click(function () {
      if (confirmadas[1]) {
        confirmadas[1] = false;
      }
      else {
        confirmadas[1] = true;
        self.textos[1];
      }


      if (confirmadas[1]) {
        $(this).text("confirmado");
      }
      else {
        $(this).text("Confirmar Respuesta");
        self.textos[1] = "";
      }

      clean();

    });

    $("#b3").click(function () {
      if (confirmadas[2]) {
        confirmadas[2] = false;
      }
      else {
        confirmadas[2] = true;
        self.textos[2];
      }


      if (confirmadas[2]) {
        $(this).text("confirmado");
      }
      else {
        $(this).text("Confirmar Respuesta");
        self.textos[2] = "";
      }

      clean();

    });

    $("#b4").click(function () {
      if (confirmadas[3]) {
        confirmadas[3] = false;
      }
      else {
        confirmadas[3] = true;
        self.textos[3];
      }


      if (confirmadas[3]) {
        $(this).text("confirmado");
      }
      else {
        $(this).text("Confirmar Respuesta");
        self.textos[3] = "";
      }
      clean();
    });

    $("#b5").click(function () {
      if (confirmadas[4]) {
        confirmadas[4] = false;
      }
      else {
        confirmadas[4] = true;
        self.textos[4];
      }


      if (confirmadas[4]) {
        $(this).text("confirmado");
      }
      else {
        $(this).text("Confirmar Respuesta");
        self.textos[4] = "";
      }

      clean();
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {


    var iguales = 0;
    if (this.textos[0] == "La ley de Moisés") {
      iguales++;
    }
    if (this.textos[1] == "Bendijo la grandeza del Señor") {
      iguales++;
    }
    if (this.textos[2] == "¡Amén!   ¡Amén!") {
      iguales++;
    }
    if (this.textos[3] == "Explicaban al pueblo el sentido de la ley") {
      iguales++;
    }
    if (this.textos[4] == "Compartir con los que no tienen") {
      iguales++;
    }

    if (iguales < 5) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica que los campos esten bien contestados. Confirma tus respuestas. ' + iguales + '/5');
    }

    if (iguales == 5) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    };
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
