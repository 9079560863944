import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
import Konva from 'konva';
declare var $: any;

@Component({
  selector: 'app-activity69',
  templateUrl: './activity69.component.html',
  styleUrls: ['./activity69.component.css']
})
export class Activity69Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '69'; // se refiere al número de actividad
  activity: Activity = new Activity();
  totalColors: any = [];

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var width = window.innerWidth;
    var height = window.innerHeight;

    if (width <= 500) {
      width = 340;
      height = 485;
    }else if(width >= 500 && width <= 1000) {
      width = 405;
      height = 663;
    }else{
      width = 605;
      height = 863;
    }

    var self = this;

    var stage = new Konva.Stage({
      container: 'canvas',
      width: width,
      height: height
    });

    var layer = new Konva.Layer();
    stage.add(layer);

    var background = new Konva.Rect({
      fill: '#FFF',
      name: 'background',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(background);



    var EscenarioNubesTrazoImg = new Image();
    EscenarioNubesTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/BLANCO/nubes.png';

    var EscenarioNubesTrazo = new Konva.Image({
      image: EscenarioNubesTrazoImg,
      name: 'EscenarioNubesTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(EscenarioNubesTrazo);

    var CabelloGrisTrazoImg = new Image();
    CabelloGrisTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/BLANCO/cabello-gris.png';

    var CabelloGrisTrazo = new Konva.Image({
      image: CabelloGrisTrazoImg,
      name: 'CabelloGrisTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(CabelloGrisTrazo);

    var CabelloNegroTrazoImg = new Image();
    CabelloNegroTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/BLANCO/cabello-negro.png';

    var CabelloNegroTrazo = new Konva.Image({
      image: CabelloNegroTrazoImg,
      name: 'CabelloNegroTrazoTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(CabelloNegroTrazo);

    var CabelloCafeTrazoImg = new Image();
    CabelloCafeTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/BLANCO/cabello-cafe.png';

    var CabelloCafeTrazo = new Konva.Image({
      image: CabelloCafeTrazoImg,
      name: 'CabelloCafeTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(CabelloCafeTrazo);

    var BocasTrazoImg = new Image();
    BocasTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/BLANCO/Boca.png';

    var BocasTrazo = new Konva.Image({
      image: BocasTrazoImg,
      name: 'BocasTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(BocasTrazo);

    var PielClaraTrazoImg = new Image();
    PielClaraTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/PIEL/CON_TRAZO/BLANCO/pie_clara.png';

    var PielClaraTrazo = new Konva.Image({
      image: PielClaraTrazoImg,
      name: 'PielClaraTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(PielClaraTrazo);

    var PielOscuraTrazoImg = new Image();
    PielOscuraTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/PIEL/CON_TRAZO/BLANCO/piel_oscura.png';

    var PielOscuraTrazo = new Konva.Image({
      image: PielOscuraTrazoImg,
      name: 'PielOscuraTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(PielOscuraTrazo);

    var RopaAmarilloTrazoImg = new Image();
    RopaAmarilloTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/amarillo.png';

    var RopaAmarilloTrazo = new Konva.Image({
      image: RopaAmarilloTrazoImg,
      name: 'RopaAmarilloTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(RopaAmarilloTrazo);

    var RopaAzulTrazoImg = new Image();
    RopaAzulTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/azul.png';

    var RopaAzulTrazo = new Konva.Image({
      image: RopaAzulTrazoImg,
      name: 'RopaAzulTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(RopaAzulTrazo);

    var RopaRojoTrazoImg = new Image();
    RopaRojoTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/rojo.png';

    var RopaRojoTrazo = new Konva.Image({
      image: RopaRojoTrazoImg,
      name: 'RopaRojoTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(RopaRojoTrazo);

    var RopaRosadoTrazoImg = new Image();
    RopaRosadoTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/rosado.png';

    var RopaRosadoTrazo = new Konva.Image({
      image: RopaRosadoTrazoImg,
      name: 'RopaRosadoTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(RopaRosadoTrazo);

    var RopaVerdeTrazoImg = new Image();
    RopaVerdeTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/verde.png';

    var RopaVerdeTrazo = new Konva.Image({
      image: RopaVerdeTrazoImg,
      name: 'RopaVerdeTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(RopaVerdeTrazo);

    var RopaCamisaJesusTrazoImg = new Image();
    RopaCamisaJesusTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/jesus_camiseta.png';

    var RopaCamisaJesusTrazo = new Konva.Image({
      image: RopaCamisaJesusTrazoImg,
      name: 'RopaCamisaJesus',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(RopaCamisaJesusTrazo);

    var RopaTunicaJesusTrazoImg = new Image();
    RopaTunicaJesusTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/jesus_tunica.png';

    var RopaTunicaJesusTrazo = new Konva.Image({
      image: RopaTunicaJesusTrazoImg,
      name: 'RopaTunicaJesusTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(RopaTunicaJesusTrazo);

    var BarcoalmohadaTrazoImg = new Image();
    BarcoalmohadaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/BLANCO/almohada.png';

    var BarcoalmohadaTrazo = new Konva.Image({
      image: BarcoalmohadaTrazoImg,
      name: 'BarcoalmohadaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(BarcoalmohadaTrazo);

    var BarcoPart1TrazoImg = new Image();
    BarcoPart1TrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/BLANCO/barco_1.png';

    var BarcoPart1Trazo = new Konva.Image({
      image: BarcoPart1TrazoImg,
      name: 'BarcoPart1Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(BarcoPart1Trazo);

    var BarcoPart2TrazoImg = new Image();
    BarcoPart2TrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/BLANCO/barco_2.png';

    var BarcoPart2Trazo = new Konva.Image({
      image: BarcoPart2TrazoImg,
      name: 'BarcoPart2Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(BarcoPart2Trazo);

    var BarcoCuerdaTrazoImg = new Image();
    BarcoCuerdaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/BLANCO/cuerda.png';

    var BarcoCuerdaTrazo = new Konva.Image({
      image: BarcoCuerdaTrazoImg,
      name: 'BarcoCuerdaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(BarcoCuerdaTrazo);

    var BarcoVelaTrazoImg = new Image();
    BarcoVelaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/BLANCO/vela.png';

    var BarcoVelaTrazo = new Konva.Image({
      image: BarcoVelaTrazoImg,
      name: 'BarcoVelaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(BarcoVelaTrazo);

    var EscenarioAguaTrazoImg = new Image();
    EscenarioAguaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/BLANCO/agua.png';

    var EscenarioAguaTrazo = new Konva.Image({
      image: EscenarioAguaTrazoImg,
      name: 'EscenarioAguaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(EscenarioAguaTrazo);

    var EscenarioCieloTrazoImg = new Image();
    EscenarioCieloTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/BLANCO/cielo.png';

    var EscenarioCieloTrazo = new Konva.Image({
      image: EscenarioCieloTrazoImg,
      name: 'EscenarioCieloTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(EscenarioCieloTrazo);

    var EscenarioLunaTrazoImg = new Image();
    EscenarioLunaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/BLANCO/luna.png';

    var EscenarioLunaTrazo = new Konva.Image({
      image: EscenarioLunaTrazoImg,
      name: 'EscenarioLunaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(EscenarioLunaTrazo);



    var colors = new Konva.Stage({
      container: 'colors',
      width: window.innerWidth >= 1000 ? 800 : 350,
      height: window.innerWidth <= 500 ? 150 : 220,
    });

    var layerColors = new Konva.Layer();

    var backgroundColors = new Konva.Rect({
      fill: '#FFF',
      name: 'colors',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layerColors.add(backgroundColors);

    var cafe = new Konva.Circle({
      x: stage.width() / 8,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#692b19',
      stroke: 'black',
      strokeWidth: 4,
      name: 'cafe'
    });

    cafe.on('click', function (evt) {
      CabelloCafeTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/COLOR/cabello_cafe.png';
      BarcoPart1TrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/COLOR/barco_1.png';
      BarcoPart2TrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/COLOR/barco_2.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    cafe.on('touchend', function (evt) {
      CabelloCafeTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/COLOR/cabello_cafe.png';
      BarcoPart1TrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/COLOR/barco_1.png';
      BarcoPart2TrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/COLOR/barco_2.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(cafe);

    var gris = new Konva.Circle({
      x: stage.width() / 8 * 2,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#616155',
      stroke: 'black',
      strokeWidth: 4,
      name: 'gris'
    });

    gris.on('click', function (evt) {
      CabelloGrisTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/COLOR/cabello_gris.png';
      EscenarioNubesTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/COLOR/nubes.png';
      EscenarioLunaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/COLOR/luna.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    gris.on('touchend', function (evt) {
      CabelloGrisTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/COLOR/cabello_gris.png';
      EscenarioNubesTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/COLOR/nubes.png';
      EscenarioLunaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/COLOR/luna.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(gris);

    var negro = new Konva.Circle({
      x: stage.width() / 8 * 3,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: 'black',
      stroke: 'black',
      strokeWidth: 4,
      name: 'negro'
    });

    negro.on('click', function (evt) {
      CabelloNegroTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/COLOR/cabello_negro.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    negro.on('touchend', function (evt) {
      CabelloNegroTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/COLOR/cabello_negro.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(negro);

    var PielClara = new Konva.Circle({
      x: stage.width() / 8 * 4,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#ffc68e',
      stroke: 'black',
      strokeWidth: 4,
      name: 'PielClara'
    });

    PielClara.on('click', function (evt) {
      PielClaraTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/PIEL/CON_TRAZO/COLOR/piel_clara.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    PielClara.on('touchend', function (evt) {
      PielClaraTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/PIEL/CON_TRAZO/COLOR/piel_clara.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(PielClara);

    var PielOscura = new Konva.Circle({
      x: stage.width() / 8 * 5,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#f57257',
      stroke: 'black',
      strokeWidth: 4,
      name: 'PielOscura'
    });

    PielOscura.on('click', function (evt) {
      PielOscuraTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/PIEL/CON_TRAZO/COLOR/piel_oscura.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    PielOscura.on('touchend', function (evt) {
      PielOscuraTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/PIEL/CON_TRAZO/COLOR/piel_oscura.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(PielOscura);

    var Amarillo = new Konva.Circle({
      x: stage.width() / 8 * 6,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#cd7f4b',
      stroke: 'black',
      strokeWidth: 4,
      name: 'Amarillo'
    });

    Amarillo.on('click', function (evt) {
      RopaAmarilloTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/COLOR/amarillo.png';
      BarcoCuerdaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/COLOR/cuerda.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    Amarillo.on('touchend', function (evt) {
      RopaAmarilloTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/COLOR/amarillo.png';
      BarcoCuerdaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/COLOR/cuerda.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(Amarillo);

    var Azul = new Konva.Circle({
      x: stage.width() / 8 * 7,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#005d72',
      stroke: 'black',
      strokeWidth: 4,
      name: 'Azul'
    });

    Azul.on('click', function (evt) {
      RopaTunicaJesusTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/COLOR/jesus_tunica.png';
      EscenarioAguaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/COLOR/agua.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    Azul.on('touchend', function (evt) {
      RopaTunicaJesusTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/COLOR/jesus_tunica.png';
      EscenarioAguaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/COLOR/agua.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(Azul);

    var Rojo = new Konva.Circle({
      x: stage.width() / 8,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: '#ce343f',
      stroke: 'black',
      strokeWidth: 4,
      name: 'Rojo'
    });

    Rojo.on('click', function (evt) {
      RopaRojoTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/COLOR/rojo.png';
      BocasTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/COLOR/Boca.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    Rojo.on('touchend', function (evt) {
      RopaRojoTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/COLOR/rojo.png';
      BocasTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/COLOR/Boca.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(Rojo);

    var Rosado = new Konva.Circle({
      x: stage.width() / 8 * 2,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: '#e0b1ae',
      stroke: 'black',
      strokeWidth: 4,
      name: 'Rosado'
    });

    Rosado.on('click', function (evt) {
      RopaRosadoTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/COLOR/rosado.png';

      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    Rosado.on('touchend', function (evt) {
      RopaRosadoTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/COLOR/rosado.png';

      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(Rosado);

    var Verde = new Konva.Circle({
      x: stage.width() / 8 * 3,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: '#a4ce5d',
      stroke: 'black',
      strokeWidth: 4,
      name: 'Verde'
    });

    Verde.on('click', function (evt) {
      RopaVerdeTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/COLOR/verde.png';
      BarcoVelaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/COLOR/vela.png';
      RopaCamisaJesusTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/COLOR/jesus_camiseta.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    Verde.on('touchend', function (evt) {
      RopaVerdeTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/COLOR/verde.png';
      BarcoVelaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/COLOR/vela.png';
      RopaCamisaJesusTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/COLOR/jesus_camiseta.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(Verde);

    var badge = new Konva.Circle({
      x: stage.width() / 8 * 4,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: '#eabd6f',
      stroke: 'black',
      strokeWidth: 4,
      name: 'badge'
    });

    badge.on('click', function (evt) {
      BarcoalmohadaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/COLOR/almohada.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    badge.on('touchend', function (evt) {
      BarcoalmohadaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/COLOR/almohada.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(badge);

    var AzulClaro = new Konva.Circle({
      x: stage.width() / 8 * 5,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: '#1c79c3',
      stroke: 'black',
      strokeWidth: 4,
      name: 'AzulClaro'
    });

    AzulClaro.on('click', function (evt) {
      EscenarioCieloTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/COLOR/cielo.png';
      RopaAzulTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/COLOR/azul.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    AzulClaro.on('touchend', function (evt) {
      EscenarioCieloTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/COLOR/cielo.png';
      RopaAzulTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/COLOR/azul.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(AzulClaro);

    var white = new Konva.Circle({
      x: stage.width() / 8 * 6,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: 'white',
      stroke: 'black',
      strokeWidth: 4,
      name: 'white'
    });

    white.on('click', function (evt) {
      EscenarioNubesTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/BLANCO/nubes.png';
      CabelloGrisTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/BLANCO/cabello-gris.png';
      CabelloNegroTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/BLANCO/cabello-negro.png';
      CabelloCafeTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/BLANCO/cabello-cafe.png';
      BocasTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/BLANCO/Boca.png';
      PielClaraTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/PIEL/CON_TRAZO/BLANCO/pie_clara.png';
      PielOscuraTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/PIEL/CON_TRAZO/BLANCO/piel_oscura.png';
      RopaAmarilloTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/amarillo.png';
      RopaAzulTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/azul.png';
      RopaRojoTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/rojo.png';
      RopaRosadoTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/rosado.png';
      RopaVerdeTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/verde.png';
      RopaCamisaJesusTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/jesus_camiseta.png';
      RopaTunicaJesusTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/jesus_tunica.png';
      BarcoalmohadaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/BLANCO/almohada.png';
      BarcoPart1TrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/BLANCO/barco_1.png';
      BarcoPart2TrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/BLANCO/barco_2.png';
      BarcoCuerdaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/BLANCO/cuerda.png';
      BarcoVelaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/BLANCO/vela.png';
      EscenarioAguaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/BLANCO/agua.png';
      EscenarioCieloTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/BLANCO/cielo.png';
      EscenarioLunaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/BLANCO/luna.png';

      self.totalColors = [];
    });

    white.on('touchend', function (evt) {
      EscenarioNubesTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/BLANCO/nubes.png';
      CabelloGrisTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/BLANCO/cabello-gris.png';
      CabelloNegroTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/BLANCO/cabello-negro.png';
      CabelloCafeTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/BLANCO/cabello-cafe.png';
      BocasTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/CABELLO/CON_TRAZO/BLANCO/Boca.png';
      PielClaraTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/PIEL/CON_TRAZO/BLANCO/pie_clara.png';
      PielOscuraTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/PIEL/CON_TRAZO/BLANCO/piel_oscura.png';
      RopaAmarilloTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/amarillo.png';
      RopaAzulTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/azul.png';
      RopaRojoTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/rojo.png';
      RopaRosadoTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/rosado.png';
      RopaVerdeTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/verde.png';
      RopaCamisaJesusTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/jesus_camiseta.png';
      RopaTunicaJesusTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/PERSONAJES/ROPA/CON_TRAZO/BLANCO/jesus_tunica.png';
      BarcoalmohadaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/BLANCO/almohada.png';
      BarcoPart1TrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/BLANCO/barco_1.png';
      BarcoPart2TrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/BLANCO/barco_2.png';
      BarcoCuerdaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/BLANCO/cuerda.png';
      BarcoVelaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/BARCO/CON_TRAZO/BLANCO/vela.png';
      EscenarioAguaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/BLANCO/agua.png';
      EscenarioCieloTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/BLANCO/cielo.png';
      EscenarioLunaTrazoImg.src = './assets/img/actividades/69TerrTempest2/Partes/ESCENARIO/CON_TRAZO/BLANCO/luna.png';

      self.totalColors = [];
    });

    layerColors.add(white);



    colors.add(layerColors);
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    console.log(this.totalColors);
    if(this.totalColors.length === 0){
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad coloreando toda la imagen');
    }
    else if(
      this.totalColors.indexOf('cafe') !== -1 &&
      this.totalColors.indexOf('gris') !== -1 &&
      this.totalColors.indexOf('negro') !== -1 &&
      this.totalColors.indexOf('PielClara') !== -1 &&
      this.totalColors.indexOf('PielOscura') !== -1 &&
      this.totalColors.indexOf('Amarillo') !== -1 &&
      this.totalColors.indexOf('Azul') !== -1 &&
      this.totalColors.indexOf('Rojo') !== -1 &&
      this.totalColors.indexOf('Rosado') !== -1 &&
      this.totalColors.indexOf('Verde') !== -1 &&
      this.totalColors.indexOf('badge') !== -1 &&
      this.totalColors.indexOf('AzulClaro') !== -1
    ){
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else{
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad coloreando toda la imagen');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
