import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity85',
  templateUrl: './activity85.component.html',
  styleUrls: ['./activity85.component.css']
})
export class Activity85Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '85'; // se refiere al número de actividad
  activity: Activity = new Activity();
  $opciones = ['cita1', 'cita2', 'cita3', 'cit4'];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
   
    $('input[type=number][max]:not([max=""])').on('input', function (ev) {
      var $this = $(this);
      var maxlength = $this.attr('max').length;
      var value = $this.val();
      if (value && value.length >= maxlength) {
        $this.val(value.substr(0, maxlength));
      }
    });
    $(".connectedSortableLis .cita1").sortable({
      connectWith: ".connectedSortableOpc1",
      update: function (event, ui) {
        self.$opciones.splice(0, 1);
      }
    }).disableSelection();
    $(".connectedSortableLis .cita2").sortable({
      connectWith: ".connectedSortableOpc2",
      update: function (event, ui) {
        self.$opciones.splice(0, 1);
      }
    }).disableSelection();
    $(".connectedSortableLis .cita3").sortable({
      connectWith: ".connectedSortableOpc3",
      update: function (event, ui) {
        self.$opciones.splice(0, 1);
      }
    }).disableSelection();
    $(".connectedSortableLis .cita4").sortable({
      connectWith: ".connectedSortableOpc4",
      update: function (event, ui) {
        self.$opciones.splice(0, 1);
      }
    }).disableSelection();
    $(".connectedSortableOpc1").sortable().disableSelection();
    $(".connectedSortableOpc2").sortable().disableSelection();
    $(".connectedSortableOpc3").sortable().disableSelection();
    $(".connectedSortableOpc4").sortable().disableSelection();    

  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var numeracion = $('.input-circle');
    var secuencia = ['2', '4', '1', '3'];
    var arraynumeracion = [];
    var iguales = 0;
    for (var i = 0; i < numeracion.length; i++) { 
      arraynumeracion[i] = numeracion[i].value.toLowerCase();
    }
    for(var i=0;i<arraynumeracion.length;i++) 
    {
      for(var j=i;j<(i+1);j++) 
      {
        if(arraynumeracion[i]==secuencia[i])  
          iguales++; 
  
      }
    }
  
  
    if (this.$opciones.length === 0 && iguales==4) {
      
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else{
      if(this.$opciones.length == 0 ){
        $('#modalFail').modal('show');
        $('.ayudas').text('Verifica que la secuencia de numeracion esta correcta.');
        $('.circ-opcion64').css('border','solid yellow');
      }
      if(iguales==4){
        $('#modalFail').modal('show');
        $('.ayudas').text('Verifica que esten ubicadas las citas correctamente.');
  
      }
      if(this.$opciones.length != 0 && iguales!=4){
        $('#modalFail').modal('show');
        $('.ayudas').text('Verifica que esten ubicados correctamente.');
      }
      
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
