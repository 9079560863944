import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.css']
})
export class LostPasswordComponent implements OnInit {

  form:FormGroup;
  displayErros:string = "none";
  constructor(public afAuth: AngularFireAuth,
    private router: Router) {
    this.form = new FormGroup({
      'email':new FormControl('',[Validators.required, Validators.email])
    });
   }

  ngOnInit() {
    document.body.className = ''
  }

  resetPass(){
    let email = this.form.value['email'];
    if(this.form.invalid ){
      this.displayErros="initial";
      return ;
    }
    return this.afAuth.auth.sendPasswordResetEmail(email)
    .then(() => {
      alert('Se envio el correo para reincio de clave, revisa la bandeja de entrada o de no deseados.');
      this.router.navigate(['/login']);
    }).catch((error) => {
      alert(error)
    })
  }

}
