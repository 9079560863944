import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity81',
  templateUrl: './activity81.component.html',
  styleUrls: ['./activity81.component.css']
})
export class Activity81Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '81'; // se refiere al número de actividad
  activity: Activity = new Activity();
  productOrder = [1, 2, 3, 4];
  resp12 = [4, 1, 2, 3];

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    
    $("#sortable1").sortable({
      update: function (event, ui) {
        self.productOrder = $(this).sortable('toArray').toString();
        //console.log(productOrder);

      }
    }).disableSelection();

  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {

   

    var iguales = 0;

    if (this.resp12[0] == this.productOrder[0]) {
      iguales++;
    }

    if (this.resp12[1] == this.productOrder[2]) {
      iguales++;
    }

    if (this.resp12[2] == this.productOrder[4]) {
      iguales++;
    }

    if (this.resp12[3] == this.productOrder[6]) {
      iguales++;
    }
    //console.log(iguales);
    if (iguales < 4) {

      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica que el orden de las imágenes sea el correcto. ' + iguales + '/4');
    }

    if (iguales == 4) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}

