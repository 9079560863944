import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity16',
  templateUrl: './activity16.component.html',
  styleUrls: ['./activity16.component.css']
})
export class Activity16Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '16'; // se refiere al número de actividad
  activity: Activity = new Activity();
  respuestas = [];
  correctas = ['draggable4', 'draggable5', 'draggable1', 'draggable2', 'draggable3'];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self=this;
    $("#draggable1").draggable({ containment: "#containment-wrapper", scroll: false });
    $("#draggable2").draggable({ containment: "#containment-wrapper", scroll: false });
    $("#draggable3").draggable({ containment: "#containment-wrapper", scroll: false });
    $("#draggable4").draggable({ containment: "#containment-wrapper", scroll: false });
    $("#draggable5").draggable({ containment: "#containment-wrapper", scroll: false });

    $("#draggable1").draggable({ revert: true });
    $("#draggable2").draggable({ revert: true });
    $("#draggable3").draggable({ revert: true });
    $("#draggable4").draggable({ revert: true });
    $("#draggable5").draggable({ revert: true });


    $("#droppable1").droppable({
      hoverClass: "ui-state-hover",
      drop: function (event, ui) {
        var temp = $(this).attr('id');
        var text = ui.draggable.text();
        var iddrag = ui.draggable.attr("id");
        self.respuestas[0] = iddrag;

        $(this).text("");

        $(this)
          .addClass("ui-state-highlight");

        $(this).text("Siete  veces ")
        $(this).prepend("<p1>" + text.toUpperCase() + "</p1>").addClass("rec-oper2");
      }
    });
    $("#droppable2").droppable({
      hoverClass: "ui-state-hover",
      drop: function (event, ui) {
        var temp = $(this).attr('id');
        var text = ui.draggable.text();
        var iddrag = ui.draggable.attr("id");
        self.respuestas[4] = iddrag;

        $(this).text("");

        $(this)
          .addClass("ui-state-highlight");

        $(this).text("Rodear y caminar alrededor de la ciudad ")
        $(this).prepend("<p1>" + text.toUpperCase() + "</p1>").addClass("rec-oper2");
      }
    });

    $("#droppable3").droppable({

      hoverClass: "ui-state-hover",
      drop: function (event, ui) {
        var temp = $(this).attr('id');
        var text = ui.draggable.text();
        var iddrag = ui.draggable.attr("id");
        self.respuestas[1] = iddrag;

        $(this)
          .addClass("ui-state-highlight");

        $(this).text("");

        $(this)
          .addClass("ui-state-highlight");

        $(this).text("No tocar ni tomar nada de lo condenado ")
        $(this).prepend("<p1>" + text.toUpperCase() + "</p1>").addClass("rec-oper2");
      }
    });

    $("#droppable5").droppable({

      hoverClass: "ui-state-hover",
      drop: function (event, ui) {
        var temp = $(this).attr('id');
        var text = ui.draggable.text();
        var iddrag = ui.draggable.attr("id");
        self.respuestas[2] = iddrag;

        $(this)
          .addClass("ui-state-highlight");
        $(this).text("");

        $(this)
          .addClass("ui-state-highlight");

        $(this).text("Siete sacerdotes ")
        $(this).prepend("<p1>" + text.toUpperCase() + "</p1>").addClass("rec-oper2");
      }
    });

    $("#droppable7").droppable({

      hoverClass: "ui-state-hover",
      drop: function (event, ui) {
        var temp = $(this).attr('id');
        var text = ui.draggable.text();
        var iddrag = ui.draggable.attr("id");
        self.respuestas[3] = iddrag;


        $(this)
          .addClass("ui-state-highlight");
        $(this).text("");

        $(this)
          .addClass("ui-state-highlight");

        $(this).text("Gritar con todas sus fuerzas ")
        $(this).prepend("<p1>" + text.toUpperCase() + "</p1>").addClass("rec-oper2");
      }
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    console.log(this.respuestas);
    console.log(this.correctas);

    var iguales = 0;

    for (var i = 0; i < this.respuestas.length; i++) {
      if (this.respuestas[i] == this.correctas[i])
        iguales++;
    }

    if (iguales < 5) {

      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica que las operaciones esten bien emparejadas. ' + iguales + '/5');
    }

    if (iguales == 5) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
