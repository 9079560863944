import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import * as firebase from "firebase/app";
import { ConstantsService } from 'src/app/services/constants.service';
import { User } from '../models/user.model';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import {Md5} from 'ts-md5/dist/md5';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-register-email',
  templateUrl: './register-email.component.html',
  styleUrls: ['./register-email.component.css']
})
export class RegisterEmailComponent implements OnInit {

  form:FormGroup;
  countries;
  displayErros = "none";
  user : User;


  constructor(
    public afAuth: AngularFireAuth,
    public _userService: UserService,
    public _constants: ConstantsService,
    private router: Router
  ) {
    this.user = new User();
    this.countries = _constants.countries;
    this.form = new FormGroup({
      'name':new FormControl('',Validators.required),
      'lastName':new FormControl('',Validators.required),
      'email':new FormControl('',[Validators.required, Validators.email]),
      'country':new FormControl('',Validators.required),
      'password':new FormControl('',Validators.required),
      'terms':new FormControl('',Validators.required),
    });
  }

  ngOnInit() {
    document.body.classList.add('Login-Inicio')
  }

  registerUser(){

    this.user.name = this.form.value['name'];
    this.user.lastName = this.form.value['lastName'];
    this.user.email = this.form.value['email'];
    this.user.country = this.form.value['country'];
    this.user.password = this.form.value['password'];
    this.user.terms = this.form.value['terms'];

    this.user.methodAuthenticate = "email";
    this.user.urlAvatar = "../../../assets/img/avatars/avatar_unisex.jpg";

    if(this.form.invalid || this.user.terms==false){
      this.displayErros="initial";
      return ;
    }

    this.createUser(this.user.email, this.user.password);

  }

  createUser(email:string, password:string){
    firebase.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      alert(errorMessage);
      console.log(error);
    }).then(val => {
      this.user.uid = firebase.auth().currentUser.uid

      this.user.password = Md5.hashStr(this.user.password).toString();
      this._userService.createUser(this.user).then(() => {
        this.router.navigate(['/dashboard']);
      });
    });

  }

  //TODO sacar a un componente
  login(provider:string = 'google') {

    let authProvider = new auth.GoogleAuthProvider();

    if(provider=="facebook"){
      authProvider = new auth.FacebookAuthProvider();
    }

    this.afAuth.auth.signInWithPopup(authProvider)
    .then( val => {
      this._userService.getUser(val.user.uid).subscribe(val =>{
        if(val === undefined){
          this.router.navigate(['/register']);
        }else{
          this.router.navigate(['/dashboard']);
        }
      });
    });
  }

}
