import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity27',
  templateUrl: './activity27.component.html',
  styleUrls: ['./activity27.component.css']
})
export class Activity27Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '27'; // se refiere al número de actividad
  activity: Activity = new Activity();

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var EsferaSelect;
    var Block = '';
    $('.block-saul').click(function () {
      Block = $(this).attr('active');
      if (Block == 'false') {
        base();
        $(this).css("background-color", "#4caf50");
        $(this).attr('active', 'true');
        $('.esfera-samuel').css("background-color", "#4caf50");
      } else {
        if (Block == 'true') {
          base();
        }
      }
      $('.esfera-samuel').click(function () {
        EsferaSelect = $(this).attr('valor-esfera');
        $('div[active="true"]').text(EsferaSelect)
        $('div[active="true"]').attr('value', EsferaSelect);
      })
      $('#limpiar').click(function () {
        base();
        $('.block-saul').text('')
      })
    })
    function base() {
      $('.block-saul').css("background-color", "#ffffff7a");
      $('.esfera-samuel').css("background-color", "#ffffff7a");
      $('.block-saul').attr('active', 'false');
    }
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var afirmaciones = []; 
		$('.block-saul').each(function(i){
			let NumAfir = $(this).attr('value');
			afirmaciones[i] = NumAfir;
		});
		var  Correctas = ['Comprueba','mismo','Señor','manos','cueva','hombres','insistían','matara','te','perdoné','no','hacerle','mal','Dios','consagró','rey'];
		//console.log(afirmaciones);
		//console.log(Correctas);
		var iguales=0;
		for(var i=0;i<afirmaciones.length;i++) 
		{
			for(var j=i;j<(i+1);j++) 
			{
				if(afirmaciones[i]==Correctas[i])  
					iguales++; 

			}
		}
		//console.log (iguales); 
		if (iguales==16) {
			$('#modalOk').modal('show');
      this.guardarActividad();
		}else{
			$('#modalFail').modal('show');
			$('.ayudas').text('verifica que los campos sean los correctos.'+iguales+'/16');
		}
    
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
