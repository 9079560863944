import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
import Konva from 'konva';
declare var $: any;

@Component({
  selector: 'app-activity19',
  templateUrl: './activity19.component.html',
  styleUrls: ['./activity19.component.css']
})
export class Activity19Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '19'; // se refiere al número de actividad
  activity: Activity = new Activity();
  afirmaciones: any = [];
  Correctas: any = ['t','u','d','i','o','s','s','e','r','á','m','i','d','i','o','s'];
  correctArray: any = [];
  incorrectArray: any = [];
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    let self = this;
    $(document).ready(function () {
      $(".letra-rut").on("keyup ", function () {
        $('.letra-rut').each(function (i) {
          let palabras = $(this).val().toLowerCase();
          self.afirmaciones[i] = palabras;
          if (self.afirmaciones[i] == self.Correctas[i]) {
            $(this).css("border-bottom", "3px solid #4caf50");
          } else {
            $(this).css("border-bottom", "");
          }
        });
      });
      console.log(self.Correctas.length);
    });

    var width = 400;
    var height = 650;

    var stage = new Konva.Stage({
      container: 'canvas',
      width: width,
      height: height,
    });

    var layer = new Konva.Layer();
    stage.add(layer);

    var mapaImg = new Image();
    mapaImg.src = './assets/img/actividades/19EjemRut2/mapa.png';

    var mapa = new Konva.Image({
      image: mapaImg,
      name: 'mapa',
      x: 0,
      y: 0,
      width: 500,
      height: 730,
      draggable: false,
    });
    layer.add(mapa);

    var inicioImg = new Image();
    inicioImg.src = './assets/img/actividades/19EjemRut2/1.png';

    var inicio = new Konva.Image({
      image: inicioImg,
      name: 'inicio',
      x: 0,
      y: 0,
      border:'solid red 30px',
      width: 80,
      height: 80,
      draggable: true,
    });
    layer.add(inicio);

    var belenImg = new Image();
    belenImg.src = './assets/img/actividades/19EjemRut2/3.png';

    var belen = new Konva.Image({
      image: belenImg,
      name: 'belen',
      x: 85,
      y: 0,
      width: 80,
      height: 80,
      draggable: true,
    });
    layer.add(belen);

    var rutImg = new Image();
    rutImg.src = './assets/img/actividades/19EjemRut2/2.png';

    var rut = new Konva.Image({
      image: rutImg,
      name: 'rut',
      x: 170,
      y: 0,
      width: 80,
      height: 80,
      draggable: true,
    });
    layer.add(rut);

    layer.on('dragmove', function(evt) {
      var shape = evt.target;
      document.body.style.cursor = 'pointer';
      shape.scaleX(1.2);
      shape.scaleY(1.2);
    });

    layer.on('dragend', function(evt) {
      var shape = evt.target;
      document.body.style.cursor = 'pointer';
      shape.scaleX(1);
      shape.scaleY(1);

      console.log(shape.attrs);
      self.correctArray = self.correctArray.filter((word) => word !== shape.attrs.name);
      self.incorrectArray = self.incorrectArray.filter((word) => word !== shape.attrs.name);
      
      if(shape.attrs.name === 'inicio'){
        if(shape.attrs.y >= 455 && shape.attrs.y <= 505){
          if(shape.attrs.x >= 260 && shape.attrs.x <= 330){
            if(self.correctArray.indexOf(shape.attrs.name) === -1){
              self.correctArray.push(shape.attrs.name);
            }
          }
          else{
            if(self.incorrectArray.indexOf(shape.attrs.name) === -1){
              self.incorrectArray.push(shape.attrs.name);
            }
          }
        }
        else{
          if(self.incorrectArray.indexOf(shape.attrs.name) === -1){
            self.incorrectArray.push(shape.attrs.name);
          }
        }
      }

      if(shape.attrs.name === 'belen'){
        if(shape.attrs.y >= 370 && shape.attrs.y <= 430){
          if(shape.attrs.x >= 145 && shape.attrs.x <= 180){
            if(self.correctArray.indexOf(shape.attrs.name) === -1){
              self.correctArray.push(shape.attrs.name);
            }
          }
          else{
            if(self.incorrectArray.indexOf(shape.attrs.name) === -1){
              self.incorrectArray.push(shape.attrs.name);
            }
          }
        }
        else{
          if(self.incorrectArray.indexOf(shape.attrs.name) === -1){
            self.incorrectArray.push(shape.attrs.name);
          }
        }
      }

      if(shape.attrs.name === 'rut'){
        if(shape.attrs.y >= 325 && shape.attrs.y <= 385){
          if(shape.attrs.x >= 150 && shape.attrs.x <= 190){
            if(self.correctArray.indexOf(shape.attrs.name) === -1){
              self.correctArray.push(shape.attrs.name);
            }
          }
          else{
            if(self.incorrectArray.indexOf(shape.attrs.name) === -1){
              self.incorrectArray.push(shape.attrs.name);
            }
          }
        }
        else{
          if(self.incorrectArray.indexOf(shape.attrs.name) === -1){
            self.incorrectArray.push(shape.attrs.name);
          }
        }
      }

      console.log('self.correctArray', self.correctArray);
      console.log('self.incorrectArray', self.incorrectArray);
    });
  }

  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    let self = this;

    if(this.correctArray.length === 0 && this.incorrectArray.length === 0){
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad arrastrando las imagenes al punto exacto');
    }
    else if(
      this.correctArray.indexOf('inicio') !== -1 &&
      this.correctArray.indexOf('belen') !== -1 &&
      this.correctArray.indexOf('rut') !== -1
    ){
      let iguales=0;
      $('.letra-rut').each(function(i){
        if(self.afirmaciones[i]==self.Correctas[i] ){
          iguales++; 
        }
      });
      if (iguales==self.Correctas.length) {
        $('#modalOk').modal('show');
        this.guardarActividad();
      }else{
        $('#modalFail').modal('show');
        $('.ayudas').text('Verifica que los campos sean los correctos. '+iguales+'/'+self.Correctas.length);
      };
    }
    else{
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad arrastrando las imagenes al punto exacto');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
