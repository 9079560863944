import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity6',
  templateUrl: './activity6.component.html',
  styleUrls: ['./activity6.component.css']
})
export class Activity6Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '6'; // se refiere al número de actividad
  activity: Activity = new Activity();

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad


    var presionado = true;

    $('.draggable-4').draggable({ revert: true });

    $('.droppable-7').droppable({

      hoverClass: 'active',
      drop: function (e, ui) {
        var temp = $(this).attr('id');

        $(this).effect("bounce", { times: 5, direction: "up", distance: 40 }, 800,
          function () {
            $(this).css("background", "radial-gradient(gold,transparent 100%)");
            $(this).css("vertical-align", "middle");
            $(this).css("left", "0");
          });


        if (temp === "drop1") {
          var inputNombre = $("#nombre1");          
          inputNombre.val(verificarDRAG(ui));
        } else if (temp === "drop2") {
          var inputNombre = $("#nombre2");
          inputNombre.val(verificarDRAG(ui));
        } else if (temp === "drop3") {
          var inputNombre = $("#nombre3");
          inputNombre.val(verificarDRAG(ui));
        } else if (temp === "drop4") {
          var inputNombre = $("#nombre4");
          inputNombre.val(verificarDRAG(ui));
        } else if (temp === "drop5") {
          var inputNombre = $("#nombre5");
          inputNombre.val(verificarDRAG(ui));
        } else if (temp === "drop6") {
          var inputNombre = $("#nombre6");
          inputNombre.val(verificarDRAG(ui));
        } else if (temp === "drop7") {
          var inputNombre = $("#nombre7");
          inputNombre.val(verificarDRAG(ui));
        } else if (temp === "drop8") {
          var inputNombre = $("#nombre8");
          inputNombre.val(verificarDRAG(ui));
        }
      }
    });


    function verificarDRAG(ui) {
      var text = ui.draggable.attr("id");
      if (text === "drag1") {
        return "B";
      } else if (text === "drag2") {
        return "O";
      } else if (text === "drag3") {
        return "C";
      } else if (text === "drag4") {
        return "E";
      } else if (text === "drag5") {
        return "D";
      } else if (text === "drag6") {
        return "R";
      }

    }
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {

    var iguales = 0;

    let lstfrase = $('.Letra-arca');
    var arrayGuardar = [];
    for (var i = 0; i < lstfrase.length; i++) {
      arrayGuardar[i] = lstfrase[i].value.toLowerCase();
      /*console.log (lstfrase[i].value); */
    }
    var myArray = ['o', 'b', 'e', 'd', 'e', 'c', 'e', 'r'];

    console.log(arrayGuardar);
    for (var i = 0; i < arrayGuardar.length; i++) {
      for (var j = i; j < (i + 1); j++) {
        if (arrayGuardar[i] == myArray[i])
          iguales++;
      }
    }

    console.log(iguales);
    if (iguales == 8) {
      $('#modalOk').modal('show');
    } else {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica que la palabra sea la correcta.' + iguales + '/8');
    }

  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
