import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity37',
  templateUrl: './activity37.component.html',
  styleUrls: ['./activity37.component.css']
})
export class Activity37Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '37'; // se refiere al número de actividad
  activity: Activity = new Activity();
  $opciones= ['sabio', 'alegra', 'padre', 'necio', 'entristece', 'madre', 'versiculo'];

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var $drags = $(".drags_37"), $drops = $('.drops_37'), $pieza;//elemetos arrastrables y objetivos
    //var $opciones = ['sabio', 'alegra', 'padre', 'necio', 'entristece', 'madre', 'versiculo']//nombre de las 7 opciones
    var self = this;
    $drags.draggable({
      revert: "invalid",//devolver elemento posicion inicial
      stack: ".prags_37",//sobreponer elemto arrastable
      snap: ".drops_37",//ajustar a la imagen de encaje
      snapMode: "inner",//ajustar dentro del contorno
      snapTolerance: 30,//distancia de ajuste
    })

    $drops.droppable({
      accept: function (item) {
        return $(this).data("pieza") == item.data("pieza"); // identificador donde el arrastrable es igual al objetivo
      },
      activeClass: "ui-state-default",
      hoverClass: "ui-state-hover",
      drop: function (event, ui) {
        $pieza = $(this).data("pieza");//nombre de la pieza que se solto y acepto en el objetivo

        for (var i = 0; i < self.$opciones.length; i++) {//identificar la pieza contra las opciones
          if (self.$opciones[i] == $pieza) {//si encunentra una similitud
            self.$opciones.splice(i, 1); //elimina la opcio por si hay un mismo arrastre
          }
        }

      }
    });

  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {

    if (this.$opciones.length === 0) {// si ya no hay mas opciones disponibles finaiza
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else {
      $('#modalFail').modal('show');
      $('.ayudas').text('Verifica que esten ubicados correctamente.');
    }
    
    
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
