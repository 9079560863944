import { Injectable } from '@angular/core';
import { User } from '../models/user.model';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument  } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Statement } from '@angular/compiler';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private usersCollection: AngularFirestoreCollection<User>;
  private userDoc: AngularFirestoreDocument<User>;
  userFire: Observable<User>;
  user: User;

  constructor(private afs: AngularFirestore) { 
    this.usersCollection = afs.collection<User>('users');
  }

  updateUser(user: User){
    this.userDoc = this.afs.doc<User>('users/'+user.uid);
    return this.userDoc.update(user);
  }

  createUser(user: User){
    user.createDate = Date.now();
    return this.usersCollection.doc(user.uid).set(Object.assign({},user));
  }

  getUser_old(uid:string){
    this.userDoc = this.afs.doc<User>('users/'+uid);
    this.userFire = this.userDoc.valueChanges();
    return this.userFire
  }

  getUser(uid:string){
    this.userDoc = this.afs.doc<User>('users/'+uid);
    return this.userDoc.get();
  }

  userHaveUpdateProfile(uid:string):boolean{
    let state = true;
    this.getUser(uid).subscribe(val =>{
      let state = true;
      
      if(val === undefined){
        state=false;
      }
      return state;
    });

    return state;
  }

}
