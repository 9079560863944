import { Injectable } from '@angular/core';
import { Heroe } from '../models/heroe';
import { v1 as uuid } from "uuid";

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument  } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeroeService {
  private heroesCollection: AngularFirestoreCollection<Heroe>;
  private heroeDoc: AngularFirestoreDocument<Heroe>;
  heroeFire: Observable<Heroe>;
  heroe: Heroe;

  constructor(private afs: AngularFirestore) {
    this.heroesCollection = afs.collection<Heroe>('heroes');
  }

  getHeroesByUserId(uid_user){
    return this.afs.collection('heroes', ref => ref.where('uid_user', '==', uid_user)).get();
  }

  getHeroesByUserId_reactive(uid_user){
    return this.afs.collection('heroes', ref => ref.where('uid_user', '==', uid_user)).valueChanges();
  }

  getHeroe(id:string){
    this.heroeDoc = this.afs.doc<Heroe>('heroes/'+id);
    return this.heroeDoc.get();
  }

  async deleteHeroe(id:string){
    this.heroeDoc = this.afs.doc<Heroe>('heroes/'+id);
    await this.heroeDoc.delete();
  }

  create(heroe:Heroe){
    heroe.createDate = Date.now();
    heroe.id = uuid();
    return this.heroesCollection.doc(heroe.id).set(Object.assign({},heroe));
  }

  updateHeroe(heroe: Heroe){
    this.heroeDoc = this.afs.doc<Heroe>('heroes/'+heroe.id);
    return this.heroeDoc.update(heroe);
  }
  updateHeroe1(heroe: Heroe){
    this.heroeDoc = this.afs.doc<Heroe>('heroes/'+heroe.id);
    return this.heroeDoc.set(heroe);
  }
}
