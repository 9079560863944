import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
import Konva from 'konva';
declare var $: any;

@Component({
  selector: 'app-activity39',
  templateUrl: './activity39.component.html',
  styleUrls: ['./activity39.component.css']
})
export class Activity39Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '39'; // se refiere al número de actividad
  activity: Activity = new Activity();
  afirmaciones: any = []; 
  Correctas: any = ['a','c','u','e','r','d','a','t','e','d','e' ,'t','u' ,'c','r','e','a','d','o','r','a','h','o','r','a' ,'q','u','e' ,'e','r','e','s' ,'j','o','v','e','n'];
  leftWords: any = [];
  rightWords: any = [];
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    let self = this;
    $(document).ready(function() {
      $("#ElegirNino").modal("show");
      let iguales=0;
      let estado = '';
      $(".letra").on("keyup ", function(){	
        $('.letra').each(function(i){
          let palabras = $(this).val().toLowerCase();
          self.afirmaciones[i] = palabras;
          if(self.afirmaciones[i]==self.Correctas[i] ){
            $(this).css("border-bottom", "3px solid #4caf50");
          }else{
            $(this).css("border-bottom", "");
          }
        });
      });
      console.log(self.Correctas.length);
    });

    var width = 400;
    var height = 500;

    var stage = new Konva.Stage({
      container: 'canvas',
      width: width,
      height: height,
    });

    var layer = new Konva.Layer();
    stage.add(layer);

    var basculeImg = new Image();
    basculeImg.src = './assets/img/actividades/39BusquedaDios2/1.png';

    var bascule = new Konva.Image({
      image: basculeImg,
      name: 'bascule',
      x: -20,
      y: 150,
      width: 400,
      height: 300,
      draggable: false,
    });
    layer.add(bascule);

    var amigos = new Konva.Group({
      name: 'amigos',
      draggable: true,
    });

    var amigosText = new Konva.Text({
      x: 0,
      y: 10,
      text: 'AMIGOS',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var amigosRect = new Konva.Rect({
      x: 0,
      y: 10,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: amigosText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    amigos.add(amigosRect);
    amigos.add(amigosText);

    layer.add(amigos);

    var jugar = new Konva.Group({
      name: 'jugar',
      draggable: true,
    });

    var jugarText = new Konva.Text({
      x: 130,
      y: 10,
      text: 'JUGAR',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var jugarRect = new Konva.Rect({
      x: 130,
      y: 10,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: jugarText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    jugar.add(jugarRect);
    jugar.add(jugarText);

    layer.add(jugar);

    var estudiar = new Konva.Group({
      name: 'estudiar',
      draggable: true,
    });

    var estudiarText = new Konva.Text({
      x: 260,
      y: 10,
      text: 'ESTUDIAR',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var estudiarRect = new Konva.Rect({
      x: 260,
      y: 10,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: estudiarText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    estudiar.add(estudiarRect);
    estudiar.add(estudiarText);

    layer.add(estudiar);

    var dinero = new Konva.Group({
      name: 'dinero',
      draggable: true,
    });

    var dineroText = new Konva.Text({
      x: 0,
      y: 80,
      text: 'DINERO',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var dineroRect = new Konva.Rect({
      x: 0,
      y: 80,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: dineroText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    dinero.add(dineroRect);
    dinero.add(dineroText);

    layer.add(dinero);

    var musica = new Konva.Group({
      name: 'musica',
      draggable: true,
    });

    var musicaText = new Konva.Text({
      x: 130,
      y: 80,
      text: 'MÚSICA',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var musicaRect = new Konva.Rect({
      x: 130,
      y: 80,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: musicaText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    musica.add(musicaRect);
    musica.add(musicaText);

    layer.add(musica);

    var viajar = new Konva.Group({
      name: 'viajar',
      draggable: true,
    });

    var viajarText = new Konva.Text({
      x: 260,
      y: 80,
      text: 'VIAJAR',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var viajarRect = new Konva.Rect({
      x: 260,
      y: 80,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: viajarText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    viajar.add(viajarRect);
    viajar.add(viajarText);

    layer.add(viajar);

    layer.on('dragend', function(evt) {
      var shape = evt.target;
      document.body.style.cursor = 'pointer';

      self.leftWords = self.leftWords.filter((word) => word !== shape.attrs.name);
      self.rightWords = self.rightWords.filter((word) => word !== shape.attrs.name);

      if(shape.attrs.name === 'amigos' || shape.attrs.name === 'dinero'){
        if(shape.attrs.y >= 100){
          if(shape.attrs.x >= -30 && shape.attrs.x < 180){
            if(self.leftWords.indexOf(shape.attrs.name) === -1){
              self.leftWords.push(shape.attrs.name);
            }
          }
          else if(shape.attrs.x >= 180){
            if(self.rightWords.indexOf(shape.attrs.name) === -1){
              self.rightWords.push(shape.attrs.name);
            }
          }
        }
      }
      else if(shape.attrs.name === 'jugar' || shape.attrs.name === 'musica'){
        if(shape.attrs.y >= 100){
          if(shape.attrs.x >= -160 && shape.attrs.x < 50){
            if(self.leftWords.indexOf(shape.attrs.name) === -1){
              self.leftWords.push(shape.attrs.name);
            }
          }
          else if(shape.attrs.x >= 50){
            if(self.rightWords.indexOf(shape.attrs.name) === -1){
              self.rightWords.push(shape.attrs.name);
            }
          }
        }
      }
      else if(shape.attrs.name === 'estudiar' || shape.attrs.name === 'viajar'){
        if(shape.attrs.y >= 100){
          if(shape.attrs.x >= -280 && shape.attrs.x < -80){
            if(self.leftWords.indexOf(shape.attrs.name) === -1){
              self.leftWords.push(shape.attrs.name);
            }
          }
          else if(shape.attrs.x >= -80){
            if(self.rightWords.indexOf(shape.attrs.name) === -1){
              self.rightWords.push(shape.attrs.name);
            }
          }
        }
      }

      console.log('left:', self.leftWords);
      console.log('right:', self.rightWords);

      if(self.leftWords.length > self.rightWords.length) {
         basculeImg.src = './assets/img/actividades/39BusquedaDios2/2.png';
      }
      else if(self.rightWords.length > self.leftWords.length) {
        basculeImg.src = './assets/img/actividades/39BusquedaDios2/3.png';
      }
      else {
        basculeImg.src = './assets/img/actividades/39BusquedaDios2/1.png';
      }
      
    });
  }

  getInfoHeroe(){
    if (this.idHero) {

      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    let self = this;

    if(self.leftWords.length > self.rightWords.length) {
      $('#modalFail').modal('show');
      $('.ayudas').text('Equilibra la balanza, tiene mas peso del lado izquierdo');
    }
    else if(self.rightWords.length > self.leftWords.length) {
      $('#modalFail').modal('show');
      $('.ayudas').text('Equilibra la balanza, tiene mas peso del lado derecho');
    }
    else {
      let iguales=0;
      $('.letra').each(function(i){
        if(self.afirmaciones[i]==self.Correctas[i] ){
          iguales++; 
        }
      });
      if (iguales==self.Correctas.length) {
        $('#modalOk').modal('show');
        this.guardarActividad();
      }else{
        $('#modalFail').modal('show');
        $('.ayudas').text('Verifica que los campos sean los correctos.'+iguales+'/'+self.Correctas.length);
      };
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
