import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity34',
  templateUrl: './activity34.component.html',
  styleUrls: ['./activity34.component.css']
})
export class Activity34Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '34'; // se refiere al número de actividad
  activity: Activity = new Activity();
  $resp_corta = false;
  $opciones = ['adoracion', 'llanto', 'atencion'];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    var $items = $(".drag-34"), $drop = $(".drop-34");

    $items.draggable({
      revert: "invalid",
      stack: ".drop-34",
      snap: ".drop-34",//ajustar a la imagen de encaje
      snapMode: "inner",//ajustar dentro del contorno
      snapTolerance: 10,//distancia de ajuste
    });

    $drop.droppable({
      accept: function (item) {
        for (var i = 0; i < self.$opciones.length; i++) {//identificar la pieza contra las opciones
          if (self.$opciones[i] == item.data("drag")) {//si encunentra una similitud
            return self.$opciones[i] == item.data("drag");
          }
        }
      },
      activeClass: "ui-state-default",
      hoverClass: "ui-state-hover",
      drop: function (event, ui) {
        for (var i = 0; i < self.$opciones.length; i++) {//identificar la pieza contra las opciones
          if (self.$opciones[i] == ui.draggable.attr("data-drag")) {//si encunentra una similitud
            self.$opciones.splice(i, 1);
          }
        }
      }
    });


    $('.resp-corta').click(function () {
      var $estado = $(this).attr('status');
      if ($estado == 'false') {
        $('.resp-corta').attr('status', 'false').css({ "background-color": "#00000070", "border": "none" });
        $(this).attr('status', 'true').css("background-color", "green");
        self.$resp_corta = true;
      }
    })
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    if (this.$opciones.length === 0 && this.$resp_corta==true) {// si ya no hay mas opciones disponibles finaiza
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else{
      if (this.$resp_corta != true) {
        $('.resp-corta').css("border","solid red");
      }
      if($('.res2-libroley').val().length <= 0){
        $('.res2-libroley').css("border","solid red");	
      }else{
        $('.res2-libroley').css("border","solid green");	
      }
      $('#modalFail').modal('show');
      $('.ayudas').text('Verifica que contestaste todo correctamente.');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
