import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity88',
  templateUrl: './activity88.component.html',
  styleUrls: ['./activity88.component.css']
})
export class Activity88Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '88'; // se refiere al número de actividad
  activity: Activity = new Activity();
  compl1 = false; 
  compl2 = false; 
  compl3 = false; 
  compl4 = false;
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    $("#PergaLlega").css({ 'border': 'solid', 'height': '100px' });
    $(".contenido-llegMet").css('max-width', 'none');

    var $items = $(".palabras-block"),$pergamino = $("#PergaLlega"),$pergamino2 = $("#PergaLlega2");

    
    var IdElemnt;
    $items.draggable({
      revert: "invalid",
      stack: ".palabras-block",
      snap: true
    });

    $pergamino.draggable({

    })
    $pergamino.droppable({
      //accept: ".vers27",
      activeClass: "ui-state-default",
      hoverClass: "ui-state-hover",
      drop: function (event, ui) {
        IdElemnt = ui.draggable.attr('id')
        //ui.draggable.css({'font-size':'0.5rem','width':'75%','display':'block'})
        if (IdElemnt == 'llegaMet-elem1') {
          self.compl1 = true;
          ui.draggable.css('border', 'solid #58dd1c');
        } else {
          if (IdElemnt == 'llegaMet-elem2') {
            self.compl2 = true;
            ui.draggable.css('border', 'solid #58dd1c');
          } else {
            if (IdElemnt == 'llegaMet-elem3') {
              self.compl3 = true;
              ui.draggable.css('border', 'solid #58dd1c');
            } else {
              if (IdElemnt == 'llegaMet-elem4') {
                self.compl4 = false;
                ui.draggable.css('border', 'solid red');
              }
            }
          }

        }
      }
    });




    $("#PergaLlega2").css({ 'border': 'solid', 'height': '100px' });
    $pergamino2.droppable({
      //accept: ".vers27",
      activeClass: "ui-state-default",
      hoverClass: "ui-state-hover",
      drop: function (event, ui) {
        IdElemnt = ui.draggable.attr('id')
        //ui.draggable.css({'font-size':'0.5rem','width':'75%','display':'block'})
        if (IdElemnt == 'llegaMet-elem1' || IdElemnt == 'llegaMet-elem2' || IdElemnt == 'llegaMet-elem3') {
          self.compl1 = false, self.compl2 = false, self.compl3 = false;
          ui.draggable.css('border', 'solid red');
        } else {
          if (IdElemnt == 'llegaMet-elem4') {
            self.compl4 = true;
            ui.draggable.css('border', 'solid #58dd1c');
          }

        }
        if (IdElemnt == 'llegaMet-elem1') {
          self.compl1 = false;
          ui.draggable.css('border', 'solid red');
        } else {
          if (IdElemnt == 'llegaMet-elem2') {
            self.compl2 = false;
            ui.draggable.css('border', 'solid red');
          } else {
            if (IdElemnt == 'llegaMet-elem3') {
              self.compl3 = false;
              ui.draggable.css('border', 'solid red');
            } else {
              if (IdElemnt == 'llegaMet-elem4') {
                self.compl4 = true;
                ui.draggable.css('border', 'solid #58dd1c');
              }
            }
          }

        }

      }
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    if (this.compl1==true && this.compl2==true && this.compl3==true  && this.compl4==true ) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else{
  
      $('#modalFail').modal('show');
      $('.ayudas').text('Verifica que esten ubicados correctamente.');
    }
   
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
