import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
import Konva from 'konva';
declare var $: any;


@Component({
  selector: 'app-activity4',
  templateUrl: './activity4.component.html',
  styleUrls: ['./activity4.component.css']
})
export class Activity4Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '4'; // se refiere al número de actividad
  activity: Activity = new Activity();

  boyCompleted: boolean = false;
  girlCompleted: boolean = false;
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    var width = 400;
    var height = 650;

    var offset = 20;
    var arrayBoyCompleted = [];
    var self = this;

    function loadImages(sources, callback) {
      var assetDir = './assets/img/actividades/4DiosCreoalH/';
      var images = {};
      var loadedImages = 0;
      var numImages = 0;
      for (var src in sources) {
        numImages++;
      }
      for (var src in sources) {
        images[src] = new Image();
        images[src].onload = function () {
          if (++loadedImages >= numImages) {
            callback(images);
          }
        };
        images[src].src = assetDir + sources[src];
      }
    }

    function isNearOutline(part, outline) {
      var a = part;
      var o = outline;
      var ax = a.x();
      var ay = a.y();

      if (ax > o.x - offset && ax < o.x + offset && ay > o.y - offset && ay < o.y + offset) {
        return true;
      } else {
        return false;
      }
    }

    function drawBackground(background, text) {
      var context = background.getContext();
      context.setAttr('font', '12pt Calibri');
      context.setAttr('textAlign', 'center');
      context.setAttr('fillStyle', 'green');
      context.fillText(text, 120, 12);
    }

    function initStageBoy(images) {
      var stageBoy = new Konva.Stage({
        container: 'nino',
        width: width,
        height: height,
      });
      var background = new Konva.Layer();
      var boyLayer = new Konva.Layer();
      var boyShapes = [];
      var score = 0;

      // posiciones de las imágenes
      var bodyParts = {
        faceBoy: {x: 50, y: 30},
        hair1: {x: 0, y: 350},
        hair2: {x: 200, y: 350},
        eyes1: {x: 30, y: 520},
        eyes2: {x: 200, y: 520},
        mouth1: {x: 40, y: 570},
        mouth2: {x: 200, y: 570}
      };

      // posiciones finales de las imágenes
      var outlines = {
        faceBoy_black: {x: 50, y: 0},
        hair1_black: {x: 87, y: 15},
        hair2_black: {x: 75, y: 5},
        eyes1_black: {x: 112, y: 95},
        eyes2_black: {x: 110, y: 95},
        mouth1_black: {x: 110, y: 140},
        mouth2_black: {x: 130, y: 150}
      };

      // creacion de partes arrastables
      for (var key in bodyParts) {
        (function () {
          var privKey = key;
          var bpart = bodyParts[key];

          var part = new Konva.Image({
            image: images[key],
            x: bpart.x,
            y: bpart.y,
            draggable: true,
          });

          if(privKey === 'faceBoy') {
            part.draggable(false);
          }

          part.on('dragstart', function () {
            let name = privKey.substring(0, privKey.length - 1);
            if(arrayBoyCompleted.indexOf(name) !== -1){
              //deshabilitar si la otra parte ya está colocada
              part.draggable(false);
              part.stroke('red');
            }
            else{
              this.moveToTop();
            }
          });

          //Verifica si la imagen está en la posición final
          part.on('dragend', function () {
            var outline = outlines[privKey + '_black'];
            if (isNearOutline(part, outline)) {
              part.position({
                x: outline.x,
                y: outline.y,
              });

              arrayBoyCompleted.push(privKey.substring(0, privKey.length - 1));
              console.log(arrayBoyCompleted);

              if (++score >= 3) {
                var text = '¡Completado, muy bien!';
                self.boyCompleted = true;
                var audio = new Audio('assets/audio/KidsCheering.mp3');
                audio.play();
                drawBackground(background, text);
              }

              // deshabilitar arrastre de la imagen
              setTimeout(function () {
                part.draggable(false);
              }, 50);
            }
          });

          part.on('mouseover', function () {
            document.body.style.cursor = 'pointer';
          });

          part.on('mouseout', function () {
            document.body.style.cursor = 'default';
          });

          part.on('dragmove', function () {
            document.body.style.cursor = 'pointer';
          });

          boyLayer.add(part);
          boyShapes.push(part);
        })();
      }

      for (var key in outlines) {
        (function () {
          var imageObj = images[key];
          var out = outlines[key];

          var outline = new Konva.Image({
            image: imageObj,
            x: out.x,
            y: out.y,
          });

          boyLayer.add(outline);
        })();
      }

      stageBoy.add(background);
      stageBoy.add(boyLayer);
    }

    var sourcesBoy = {
      faceBoy: 'Nino/Nino.png',
      hair1: 'Nino/Cabello-1.png',
      hair2: 'Nino/Cabello-2.png',
      eyes1: 'Nino/Ojos-1.png',
      eyes2: 'Nino/Ojos-2.png',
      mouth1: 'Nino/Boca-1.png',
      mouth2: 'Nino/Boca-2.png'
    };
    loadImages(sourcesBoy, initStageBoy);

    function initStageGirl(images) {
      var stageBoy = new Konva.Stage({
        container: 'nina',
        width: width,
        height: height,
      });
      var background = new Konva.Layer();
      var boyLayer = new Konva.Layer();
      var boyShapes = [];
      var score = 0;

      // posiciones de las imágenes
      var bodyParts = {
        faceGirl: {x: 50, y: 30},
        haireGirl1: {x: 0, y: 350},
        haireGirl2: {x: 200, y: 350},
        eyeseGirl1: {x: 30, y: 520},
        eyeseGirl2: {x: 200, y: 520},
        moutheGirl1: {x: 70, y: 570},
        moutheGirl2: {x: 230, y: 570}
      };

      // posiciones finales de las imágenes
      var outlines = {
        faceGirl_black: {x: 50, y: 0},
        haireGirl1_black: {x: 63, y: 26},
        haireGirl2_black: {x: 75, y: 20},
        eyeseGirl1_black: {x: 100, y: 105},
        eyeseGirl2_black: {x: 103, y: 102},
        moutheGirl1_black: {x: 135, y: 154},
        moutheGirl2_black: {x: 135, y: 155}
      };

      // creacion de partes arrastables
      for (var key in bodyParts) {
        (function () {
          var privKey = key;
          var bpart = bodyParts[key];

          var part = new Konva.Image({
            image: images[key],
            x: bpart.x,
            y: bpart.y,
            draggable: true,
          });

          if(privKey === 'faceGirl') {
            part.draggable(false);
          }

          part.on('dragstart', function () {
            let name = privKey.substring(0, privKey.length - 1);
            if(arrayBoyCompleted.indexOf(name) !== -1){
              //deshabilitar si la otra parte ya está colocada
              part.draggable(false);
              part.stroke('red');
            }
            else{
              this.moveToTop();
            }
          });

          //Verifica si la imagen está en la posición final
          part.on('dragend', function () {
            var outline = outlines[privKey + '_black'];
            if (isNearOutline(part, outline)) {
              part.position({
                x: outline.x,
                y: outline.y,
              });

              arrayBoyCompleted.push(privKey.substring(0, privKey.length - 1));
              console.log(arrayBoyCompleted);

              if (++score >= 3) {
                var text = '¡Completada, muy bien!';
                self.girlCompleted = true;
                var audio = new Audio('assets/audio/KidsCheering.mp3');
                audio.play();
                drawBackground(background, text);
              }

              // deshabilitar arrastre de la imagen
              setTimeout(function () {
                part.draggable(false);
              }, 50);
            }
          });

          part.on('mouseover', function () {
            document.body.style.cursor = 'pointer';
          });

          part.on('mouseout', function () {
            document.body.style.cursor = 'default';
          });

          part.on('dragmove', function () {
            document.body.style.cursor = 'pointer';
          });

          boyLayer.add(part);
          boyShapes.push(part);
        })();
      }

      for (var key in outlines) {
        (function () {
          var imageObj = images[key];
          var out = outlines[key];

          var outline = new Konva.Image({
            image: imageObj,
            x: out.x,
            y: out.y,
          });

          boyLayer.add(outline);
        })();
      }

      stageBoy.add(background);
      stageBoy.add(boyLayer);
    }

    var sourcesGirl = {
      faceGirl: 'Nina/Nina.png',
      haireGirl1: 'Nina/Cabello-1.png',
      haireGirl2: 'Nina/Cabello-2.png',
      eyeseGirl1: 'Nina/Ojos-1.png',
      eyeseGirl2: 'Nina/Ojos-2.png',
      moutheGirl1: 'Nina/Boca-1.png',
      moutheGirl2: 'Nina/Boca-2.png'
    };
    loadImages(sourcesGirl, initStageGirl);
   
  }

  getInfoHeroe(){
    if (this.idHero) {

      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    console.log('boyCompleted', this.boyCompleted);
    console.log('girlCompleted', this.girlCompleted);
    if($('#caracteristica1').val().length >= 3 && $('#caracteristica2').val().length >= 3 && $('#caracteristica3').val().length >= 3){
      if(this.boyCompleted){
        if(this.girlCompleted){
          $('#modalOk').modal('show');
          this.guardarActividad();
        }
        else{
          $('#modalFail').modal('show');
          $('.ayudas').text('Completa la actividad de completar la silueta femenina');
        }
      }
      else{
        $('#modalFail').modal('show');
        $('.ayudas').text('Completa la actividad de completar la silueta masculina');
      }
    }
    else{
        $('#modalFail').modal('show');
        $('.ayudas').text('Verifica que hayas ingresado las características que te hacen distinto a otros.');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
