import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity45',
  templateUrl: './activity45.component.html',
  styleUrls: ['./activity45.component.css']
})
export class Activity45Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '45'; // se refiere al número de actividad
  activity: Activity = new Activity();
  afirmaciones = [];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self=this;
    $(".block-DNL3").attr('active', 'false');
  
    $(".block-DNL3").click(function () {
      var estado = $(this).attr('active');
      var num = $(this).attr('num');
      var varText = $(this).html();
      $('div[num="' + num + '"]').attr("value", "false");
      $('div[num="' + num + '"]').css("background-color", "");
      if (estado == 'false') {

        $(this).css("background-color", "#4caf50");
        $(this).attr('active', 'true');
        $(this).attr('text', varText);
        self.afirmaciones[num - 1] = $(this).attr('text');
      } else {
        if (estado == 'true') {
          $(this).attr('active', 'false');
          $(this).attr('text', '');
          $(this).css("background-color", "");
        }
      }
      //console.log(afirmaciones);
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var iguales = 0;
    var Correctas = ['REY', 'BABILONIA', 'MAJESTAD', 'SERVIMOS', 'LIBRARNOS', 'IRA', 'SEMBLANTE', 'ORDENÓ']
    for (var i = 0; i < this.afirmaciones.length; i++) {
      for (var j = i; j < (i + 1); j++) {
        if (this.afirmaciones[i] == Correctas[i])
          iguales++;
      }
    }
    //console.log(iguales);
    if (iguales == 8) {
      $('#modalOk').modal('show');
    this.guardarActividad();
    } else {
      $('#modalFail').modal('show');
      $('.ayudas').text('verifica que los palabras sean las correctos.' + iguales + '/8');
    };
    
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
