import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity48',
  templateUrl: './activity48.component.html',
  styleUrls: ['./activity48.component.css']
})
export class Activity48Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '48'; // se refiere al número de actividad
  activity: Activity = new Activity();
  input1: any;
  input2: any;
  input3: any;
  cont = 1;
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    var perc = 100;
    $(".draggable41").draggable({ containment: "#containment-wrapper", scroll: false });


    $(".draggable-41").draggable({
      revert: "invalid",
      start: function (event, ui) {
        $(this).css("transform", "rotate(0deg)");
      }
    });


    $('.droppable-73').droppable({
      accept: '#drag10',
      hoverClass: 'active',
      drop: function (e, ui) {
        var temp = $(this).attr('id');
        $(ui.draggable).draggable("disable");

        if (self.cont < 4) {
          $(this).effect("scale", { percent: perc, direction: 'both' }, 500,
            function () {
              $(this).css("background", "radial-gradient(lightgreen,transparent 70%)");
              $(this).css("left", "0px");

            });
          var temp2 = ui.draggable.attr('id1');
          ui.draggable.effect("scale", { percent: 90, direction: 'vertical' }, 1000,
            function () {
              $(ui.draggable).css("background", "radial-gradient(lightgreen,transparent 70%)");
              $(ui.draggable).css("vertical-align", "middle");
              $(ui.draggable).css("align", "middle");
              if (self.cont == 2) {
                $(ui.draggable).css("transform", "rotate(0deg)");
                if (temp2 == "drag11") {
                  $(ui.draggable).css("left", "-40px");
                  $(ui.draggable).css("top", "-420px");
                } else if (temp2 == "drag12") {
                  $(ui.draggable).css("left", "-40px");
                  $(ui.draggable).css("top", "-520px");
                } else {
                  $(ui.draggable).css("left", "-40px");
                  $(ui.draggable).css("top", "-620px");
                }
              }
              else if (self.cont == 3) {
                $(ui.draggable).css("transform", "rotate(-15deg)");
                if (temp2 == "drag11") {
                  $(ui.draggable).css("left", "0px");
                  $(ui.draggable).css("top", "-420px");
                } else if (temp2 == "drag12") {
                  $(ui.draggable).css("left", "0px");
                  $(ui.draggable).css("top", "-520px");
                } else {
                  $(ui.draggable).css("left", "0px");
                  $(ui.draggable).css("top", "-620px");
                }

              } else if (self.cont == 4) {
                $(ui.draggable).css("transform", "rotate(15deg)");
                if (temp2 == "drag11") {
                  $(ui.draggable).css("left", "50px");
                  $(ui.draggable).css("top", "-420px");
                } else if (temp2 == "drag12") {
                  $(ui.draggable).css("left", "50px");
                  $(ui.draggable).css("top", "-520px");
                } else {
                  $(ui.draggable).css("left", "50px");
                  $(ui.draggable).css("top", "-620px");
                }
              }
            }
          );
          self.cont++;
          perc = perc + 5;
        }
      }
    });
    self.input1 = $('#in1');
    self.input2 = $('#in2');
    self.input3 = $('#in3');
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var iguales = 0;
    console.log(this.cont - 1, iguales)
    

    if (this.input1.val().length > 2) {
      iguales++;
    }
    if (this.input2.val().length > 2) {
      iguales++;
    }
    if (this.input3.val().length > 2) {
      iguales++;
    }

    if (this.cont < 4) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica que todos los lapices esten en el Vaso ' + (this.cont - 1) + '/3');

    }

    if (iguales < 3) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica que hayas escrito palabras válidas en la segunda sección. ' + iguales + '/3');
    }

    if ((this.cont >= 4) && (iguales == 3)) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }

  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
