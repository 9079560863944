import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity10',
  templateUrl: './activity10.component.html',
  styleUrls: ['./activity10.component.css']
})
export class Activity10Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '10'; // se refiere al número de actividad
  activity: Activity = new Activity();
  words: any = [];
  sacrificios: any;
  desierto: any;
  gracia: any;
  miedo: any;
  egipto: any;
  rey: any;
  herir: any;
  dios: any;
  faraon: any;
  amor: any;
  moises: any;
  clamor: any;
  afirmaciones: any = [];
  Correctas: any = ['d','i','o','s','m','e','a','m','a','y','m','e','c','u','b','r','e','c','o','n','s','u','g','r','a','c','i','a'];

  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    let self = this;
    this.sacrificios = document.getElementById('sacrificios') as HTMLInputElement | null;
    this.desierto = document.getElementById('desierto') as HTMLInputElement | null;
    this.gracia = document.getElementById('gracia') as HTMLInputElement | null;
    this.miedo = document.getElementById('miedo') as HTMLInputElement | null;
    this.egipto = document.getElementById('egipto') as HTMLInputElement | null;
    this.rey = document.getElementById('rey') as HTMLInputElement | null;
    this.herir = document.getElementById('herir') as HTMLInputElement | null;
    this.dios = document.getElementById('dios') as HTMLInputElement | null;
    this.faraon = document.getElementById('faraon') as HTMLInputElement | null;
    this.amor = document.getElementById('amor') as HTMLInputElement | null;
    this.moises = document.getElementById('moises') as HTMLInputElement | null;
    this.clamor = document.getElementById('clamor') as HTMLInputElement | null;

    $(document).ready(function () {
      //$("#ElegirNino").modal("show");
      let iguales = 0;
      let estado = '';
      $(".letra").on("keyup ", function () {
        $('.letra').each(function (i) {
          let palabras = $(this).val().toLowerCase();
          self.afirmaciones[i] = palabras;
          if (self.afirmaciones[i] == self.Correctas[i]) {
            $(this).css("border-bottom", "3px solid #4caf50");
          } else {
            $(this).css("border-bottom", "");
          }
        });
      });
      console.log(self.Correctas.length);
    });
  }

  check(cell: string) {
    document.getElementById(cell).style.background = '#4d9c26';
    this.words.push(cell);

    if(
      this.words.indexOf('A3') !== -1 &&
      this.words.indexOf('A4') !== -1 &&
      this.words.indexOf('A5') !== -1 &&
      this.words.indexOf('A6') !== -1 &&
      this.words.indexOf('A7') !== -1
    )
    {
      //this.miedo = true;
      if (this.miedo != null) {
        this.miedo.checked = true;
        this.miedo.disabled = true;
      }
    }

    if(
      this.words.indexOf('B2') !== -1 &&
      this.words.indexOf('B3') !== -1 &&
      this.words.indexOf('B4') !== -1 &&
      this.words.indexOf('B5') !== -1 &&
      this.words.indexOf('B6') !== -1 &&
      this.words.indexOf('B7') !== -1
    )
    {
      //this.egipto = true;
      if (this.egipto != null) {
        this.egipto.checked = true;
        this.egipto.disabled = true;
      }
    }

    if(
      this.words.indexOf('C3') !== -1 &&
      this.words.indexOf('C4') !== -1 &&
      this.words.indexOf('C5') !== -1
    )
    {
      //this.rey = true;
      if (this.rey != null) {
        this.rey.checked = true;
        this.rey.disabled = true;
      }
    }

    if(
      this.words.indexOf('J4') !== -1 &&
      this.words.indexOf('J5') !== -1 &&
      this.words.indexOf('J6') !== -1 &&
      this.words.indexOf('J7') !== -1
    )
    {
      //this.dios = true;
      if (this.dios != null) {
        this.dios.checked = true;
        this.dios.disabled = true;
      }
    }

    if(
      this.words.indexOf('L1') !== -1 &&
      this.words.indexOf('L2') !== -1 &&
      this.words.indexOf('L3') !== -1 &&
      this.words.indexOf('L4') !== -1 &&
      this.words.indexOf('L5') !== -1 &&
      this.words.indexOf('L6') !== -1
    )
    {
      //this.faraon = true;
      if (this.faraon != null) {
        this.faraon.checked = true;
        this.faraon.disabled = true;
      }
    }

    if(
      this.words.indexOf('A1') !== -1 &&
      this.words.indexOf('B1') !== -1 &&
      this.words.indexOf('C1') !== -1 &&
      this.words.indexOf('D1') !== -1 &&
      this.words.indexOf('E1') !== -1 &&
      this.words.indexOf('F1') !== -1 &&
      this.words.indexOf('G1') !== -1 &&
      this.words.indexOf('H1') !== -1 &&
      this.words.indexOf('I1') !== -1 &&
      this.words.indexOf('J1') !== -1 &&
      this.words.indexOf('K1') !== -1
    )
    {
      //this.sacrificios = true;
      if (this.sacrificios != null) {
        this.sacrificios.checked = true;
        this.sacrificios.disabled = true;
      }
    }

    if(
      this.words.indexOf('D2') !== -1 &&
      this.words.indexOf('E2') !== -1 &&
      this.words.indexOf('F2') !== -1 &&
      this.words.indexOf('G2') !== -1 &&
      this.words.indexOf('H2') !== -1 &&
      this.words.indexOf('I2') !== -1 &&
      this.words.indexOf('J2') !== -1 &&
      this.words.indexOf('K2') !== -1
    )
    {
      //this.desierto = true;
      if (this.desierto != null) {
        this.desierto.checked = true;
        this.desierto.disabled = true;
      }
    }

    if(
      this.words.indexOf('B3') !== -1 &&
      this.words.indexOf('C3') !== -1 &&
      this.words.indexOf('D3') !== -1 &&
      this.words.indexOf('E3') !== -1 &&
      this.words.indexOf('F3') !== -1 &&
      this.words.indexOf('G3') !== -1
    )
    {
      //this.gracia = true;
      if (this.gracia != null) {
        this.gracia.checked = true;
        this.gracia.disabled = true;
      }
    }

    if(
      this.words.indexOf('D5') !== -1 &&
      this.words.indexOf('E5') !== -1 &&
      this.words.indexOf('F5') !== -1 &&
      this.words.indexOf('G5') !== -1
    )
    {
      //this.amor = true;
      if (this.amor != null) {
        this.amor.checked = true;
        this.amor.disabled = true;
      }
    }

    if(
      this.words.indexOf('D4') !== -1 &&
      this.words.indexOf('E4') !== -1 &&
      this.words.indexOf('F4') !== -1 &&
      this.words.indexOf('G4') !== -1 &&
      this.words.indexOf('H4') !== -1 &&
      this.words.indexOf('I4') !== -1
    )
    {
      //this.clamor = true;
      if (this.clamor != null) {
        this.clamor.checked = true;
        this.clamor.disabled = true;
      }
    }

    if(
      this.words.indexOf('A7') !== -1 &&
      this.words.indexOf('B7') !== -1 &&
      this.words.indexOf('C7') !== -1 &&
      this.words.indexOf('D7') !== -1 &&
      this.words.indexOf('E7') !== -1 &&
      this.words.indexOf('F7') !== -1
    )
    {
      //this.moises = true;
      if (this.moises != null) {
        this.moises.checked = true;
        this.moises.disabled = true;
      }
    }

    if(
      this.words.indexOf('A8') !== -1 &&
      this.words.indexOf('B8') !== -1 &&
      this.words.indexOf('C8') !== -1 &&
      this.words.indexOf('D8') !== -1 &&
      this.words.indexOf('E8') !== -1
    )
    {
      //this.herir = true;
      if (this.herir != null) {
        this.herir.checked = true;
        this.herir.disabled = true;
      }
    }
  }

  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    if(
      this.sacrificios.checked &&
      this.desierto.checked &&
      this.gracia.checked &&
      this.miedo.checked &&
      this.egipto.checked &&
      this.rey.checked &&
      this.herir.checked &&
      this.dios.checked &&
      this.faraon.checked &&
      this.amor.checked &&
      this.clamor.checked &&
      this.moises.checked
    ){
      let self = this;
      let iguales=0;
      $('.letra').each(function(i){
        if(self.afirmaciones[i]==self.Correctas[i] ){
          iguales++; 
        }
      });
      if (iguales==self.Correctas.length) {
        $('#modalOk').modal('show');
        this.guardarActividad();
      }else{
        $('#modalFail').modal('show');
        $('.ayudas').text('Verifica que los campos sean los correctos. '+iguales+'/'+self.Correctas.length);
      };
    }
    else{
      $('#modalFail').modal('show');
      $('.ayudas').text('Encuentra todas las palabras en la sopa de letras');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
