import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity21',
  templateUrl: './activity21.component.html',
  styleUrls: ['./activity21.component.css']
})
export class Activity21Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '21'; // se refiere al número de actividad
  activity: Activity = new Activity();
  afirmaciones: any = [];
  Correctas: any = ['s','t','e','s','a','c','e','r','d','o','t','e','s','a','m','v','l','m','p','i','c','u','l','r','a','e','o','n','e','l','i','a','n','a'];
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    let self = this;
    $(document).ready(function () {
      //$("#ElegirNino").modal("show");
      let iguales = 0;
      let estado = '';
      $(".letra").on("keyup ", function () {
        $('.letra').each(function (i) {
          let palabras = $(this).val().toLowerCase();
          self.afirmaciones[i] = palabras;
          if (self.afirmaciones[i] == self.Correctas[i]) {
            $(this).css("border-bottom", "3px solid #4caf50");
          } else {
            $(this).css("border-bottom", "");
          }
        });
      });
      console.log(self.Correctas.length);
    });
  }

  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    let self = this;
    let iguales=0;
      $('.letra').each(function(i){
        if(self.afirmaciones[i]==self.Correctas[i] ){
          iguales++; 
        }
      });
      if (iguales==self.Correctas.length) {
        $('#modalOk').modal('show');
        this.guardarActividad();
      }else{
        $('#modalFail').modal('show');
        $('.ayudas').text('Verifica que los campos sean los correctos. '+iguales+'/'+self.Correctas.length);
      };
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
