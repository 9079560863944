import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from 'firebase/app';
import 'firebase/functions';

import { Heroe } from 'src/app/models/heroe';
import { HeroeService } from 'src/app/services/heroe.service';
import { Achievement } from 'src/app/models/achievement';
import { AchievementService } from 'src/app/services/achievement.service';
import { RelationChallengesService } from 'src/app/services/relation-challenges.service';
import { ChallengeService } from 'src/app/services/challenge.service';
import { AngularFireAuth } from '@angular/fire/auth';
import * as jsPDF from "jspdf";
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.model';
import { Activity } from 'src/app/models/activity';

declare var $: any;

@Component({
  selector: 'app-status-heroe',
  templateUrl: './status-heroe.component.html',
  styleUrls: ['./status-heroe.component.css']
})
export class StatusHeroeComponent implements OnInit, OnDestroy {

  heroe: Heroe = new Heroe();
  achievements: Achievement[] = [];
  loading: boolean = true;
  idHero: string = "";
  totalChallenges: number = 0;
  heroChallenges: number = 0;
  loadingCertificate: boolean;
  user: User = new User();
  avatarPercentSprite: number;
  userActivities: string[] = [];
  deletedHero: boolean = false;

  constructor(private _route: ActivatedRoute,
    public _heroeService: HeroeService,
    public _achievementService: AchievementService,
    public _userService: UserService,
    public _challengeService: ChallengeService,
    public afAuth: AngularFireAuth,
    private _relHeroeChallenge: RelationChallengesService,
    public router: Router
  ) {
  }

  ngOnInit() {
    document.body.classList.add('home-Inicio')

    this.idHero = this._route.snapshot.paramMap.get('id');

    this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
        this.userActivities = this.heroe.activities;
        this.heroChallenges = this.heroe.activities.length;

        //this.avatarPercentSprite = this.traslatePercent(this.heroe.progressbarGrl);

        this._achievementService.getAll().subscribe(val => {

          val.docs.forEach(achievementTemp => {
            let achievement = achievementTemp.data() as Achievement;
            if(achievement.online){
              let srcImg = achievement.img;
              achievement.img = 'sin_completar.png';
              this.achievements.push(achievement);

              let numActivities = 0;
              achievement.activities.forEach(element => {
                element.get().then(result => {
                  if(result !== undefined){
                    let act = result.data() as Activity;
                    if(act.online){
                      this.totalChallenges++;
                      if(this.userActivities.indexOf(act.id) !== -1){
                        numActivities++;
                      }
                      if (numActivities === 0){
                        achievement.img = 'sin_completar.png';
                      }
                      else if(numActivities < achievement.activities.length){
                        achievement.img = 'medio.png';
                      }
                      else{
                        achievement.img = srcImg;
                      }
                    }
                  }
                });
              });

            }
            /*let totalChallengesByAchievement: number = 0;
            let heroChallengesByPiece: number = 0;
            this._challengeService.getAllByPieceId(piece.id).subscribe((val: any) => {

              totalChallengesByPiece = val.length;
              this.totalChallenges = this.totalChallenges + totalChallengesByPiece;

              this._relHeroeChallenge.getAllCompleteChallenge(piece.id, this.idHero, this.afAuth.auth.currentUser.uid).subscribe(val => {
                heroChallengesByPiece = val['docs'].length;
                this.heroChallenges = this.heroChallenges + heroChallengesByPiece;
                piece.progressBar = Math.round((heroChallengesByPiece * 100) / totalChallengesByPiece);

                this.heroe.progressbarGrl = Math.round((this.heroChallenges * 100) / this.totalChallenges);
                this.avatarPercentSprite = this.traslatePercent(this.heroe.progressbarGrl);

              });
            });*/


          });
          let interval = setInterval(()=>{
            if(this.totalChallenges > 0){
              this.heroe.progressbarGrl = Math.round((this.heroChallenges * 100) / this.totalChallenges);
              clearInterval(interval);
              this.ngOnDestroy();
            }
          }, 1000);
          
          this.loading = false;
        });
      });
  }

  traslatePercent(originalPercent: number) {

    let newPercent = 0;

    if (originalPercent >= 18 && originalPercent < 36) {
      newPercent = 18;
    } else if (originalPercent >= 36 && originalPercent < 52) {
      newPercent = 36;
    } else if (originalPercent >= 52 && originalPercent < 68) {
      newPercent = 52;
    } else if (originalPercent >= 68 && originalPercent < 84) {
      newPercent = 68;
    } else if (originalPercent >= 84 && originalPercent < 100) {
      newPercent = 84;
    } else if (originalPercent >= 100) {
      newPercent = 100;
    }

    return newPercent;

  }

  async generateCertificateEmail() {

    this._userService.getUser(this.afAuth.auth.currentUser.uid).subscribe(val => {
      this.user = val.data() as User;
      this.loadingCertificate = true;
      let addMessage = firebase.functions().httpsCallable('sendCertificate');
      let userFullName = this.user.name + " " + this.user.lastName;
      let heroeFullName = this.heroe.name + " " + this.heroe.lastName;
      let request = {
        "userName": this.user.name,
        "heroeName": this.heroe.name,
        "pieceName": "N/A",
        "certificateType": "grl",
        "nameImageCertificate": `grl_${this.heroe.nameFileAvatar}.jpg`,
        "fullNameHeroe": heroeFullName.toUpperCase(),
        "fullNameUser": userFullName.toUpperCase(),
        "userEmail": this.user.email,
      };

      addMessage(request).then((val) => {
        this.loadingCertificate = false;
        $('#exampleModalCenter').modal('hide');
      });

    });
  }

  async generateCertificate() {

    var doc = new jsPDF({
      orientation: 'landscape',
      format: 'letter'
    })

    let img = `../../../assets/img/diplomas/grl_${this.heroe.nameFileAvatar}_ok.jpg`;

    this.loadingCertificate = true;

    this._userService.getUser(this.afAuth.auth.currentUser.uid).subscribe(val => {
      this.user = val.data() as User;
      this.toDataURL(img, function (data, dataURL) {
        console.log(data);

        doc.addImage(dataURL, "JPGE", 1, 1, 279, 215);
        doc.setFontSize(30);
        let heroeName = data['heroe'].name + " " + data['heroe'].lastName;
        doc.text(heroeName.toUpperCase(), 74, 97);
        let fullName = data['user'].name + " " + data['user'].lastName;
        doc.setFontSize(11);
        doc.setTextColor('#FFFFFF');
        doc.text(fullName.toUpperCase(), 206, 195);
        doc.setFontSize(9);
        doc.text(data['date'], 133, 210);
        doc.save('certificado_final.pdf');
        $('#exampleModalCenter').modal('hide');
      }).then(() => this.loadingCertificate = false);
    });
  }

  async toDataURL(src, callback) {

    let date: Date;
    this._relHeroeChallenge.getAllCompleteChallenge_v3(this.idHero, this.afAuth.auth.currentUser.uid).subscribe(val => {
      date = new Date(val[0]['createDate']);
      let dateFormat = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()

      let extraData = {
        "heroe": this.heroe,
        "user": this.user,
        "date": dateFormat,
      }

      var xhttp = new XMLHttpRequest();
      function callback1(tempData) {
        return function () {
          var fileReader = new FileReader();
          fileReader.onloadend = function () {
            callback(tempData, fileReader.result);
          }
          fileReader.readAsDataURL(xhttp.response);
        };
      }

      xhttp.onload = callback1(extraData);

      xhttp.responseType = 'blob';
      xhttp.open('GET', src, true);
      xhttp.send();
    });
  }

  ngOnDestroy() {
    if(!this.deletedHero){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        let heroe = val.ref;
        heroe.update({ "progressbarGrl": this.heroe.progressbarGrl });
      });
    }
  }

  async deleteHeroe() {
    if (this.heroe) {
      await this._heroeService.deleteHeroe(this.heroe.id);
      $('#BorrarPerfil').modal('hide');
      this.router.navigate(['/dashboard']);
      this.deletedHero = true;
    }
  }
}
