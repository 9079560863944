import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity44',
  templateUrl: './activity44.component.html',
  styleUrls: ['./activity44.component.css']
})
export class Activity44Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '44'; // se refiere al número de actividad
  activity: Activity = new Activity();
  productOrder = [];

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    $("#sortable").sortable({
      update: function (event, ui) {
        self.productOrder = $(this).sortable('toArray').toString();
        var iguales = 0, iguales1 = 0;
        console.log(self.productOrder);
        var resp = ['7', ',', '5', ',', '8', ',', '3', ',', '1', ',', '6', ',', '4', ',', '2'];


        for (var i = 0; i < 8; i++) {
          if (self.productOrder[i] != ",") {
            if (self.productOrder[i] == resp[i]) {
              iguales++;
            }
          }
        }

        if (iguales == 8) {
          $('#modalOk').modal('show');
        }


      }
    })
    $("#sortable").disableSelection();

  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {

    var iguales = 0, iguales1 = 0;
    var resp = ['7', ',', '5', ',', '8', ',', '3', ',', '1', ',', '6', ',', '4', ',', '2'];
    var input1;
    var input2;
    input1 = $('#in1').val();
    input2 = $('#in2').val();

    //console.log(this.productOrder);

    for (var i = 0; i < 15; i++) {
      if (this.productOrder[i] != ",") {
        if (this.productOrder[i] == resp[i]) {
          iguales++;
        }
      }
    }

    if (input1.length > 2) {
      iguales1++;
    }
    if (input2.length > 2) {
      iguales1++;
    }
    if (input2.length == input1.length) {
      iguales1++;
    }
    //console.log(input1);
    //console.log(input2);
    //console.log(iguales1);


    if (iguales == 8 && iguales1 == 3) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    } else if (iguales < 8) {
      $('#modalFail').modal('show');
      $('.ayudas').text('Verifica que las imágenes esten en el orden exacto ' + iguales + '/8');
    } else if (iguales1 < 3) {
      $('#modalFail').modal('show');
      $('.ayudas').text('Verifica que hayas escrito tu nombre en ambos campos ' + iguales1 + '/3');
    }

  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
