import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity54',
  templateUrl: './activity54.component.html',
  styleUrls: ['./activity54.component.css']
})
export class Activity54Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '54'; // se refiere al número de actividad
  activity: Activity = new Activity();

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    $('input[type=text][max]:not([max=""])').on('input', function (ev) {
      var $this = $(this);
      var maxlength = $this.attr('max').length;
      var value = $this.val();
      if (value && value.length >= maxlength) {
        $this.val(value.substr(0, maxlength));
      }
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {

    var lstfrase = $('.letra');
    var arrayGuardar = [];
    for (var i = 0; i < lstfrase.length; i++) {
      arrayGuardar[i] = lstfrase[i].value.toLowerCase();
    }
    var myArray = ['p', 'a', 'r', 'a', 'd', 'i', 'o', 's', 'n', 'o', 'h', 'a', 'y', 'n', 'a', 'd', 'a', 'i', 'm', 'p', 'o', 's', 'i', 'b', 'l', 'e'];
    var iguales = 0;
    console.log(arrayGuardar);
    console.log(myArray);

    for (var i = 0; i < myArray.length; i++) {
      if (arrayGuardar[i] == myArray[i])
        iguales++;
    }
    console.log(iguales);
    var delay = 2000;
    if (iguales == 26) {
      $('#modalOk').modal('show');
      this.guardarActividad();
      $("#texto1").effect("pulsate", { times: 5 }, 3500,
        function () {
          $(this).css("border-radius", "100px")
          $(this).css("font-size", "18px")
          $(this).css("color", "turquoise")
          //$( this ).css( "text-shadow", "snow")
          $(this).css("background", "repeating-radial-gradient(lightcoral,transparent 95%)")
        }
      );


    } else {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('TU AVANCE ES: ' + iguales + '/26');

    }
    
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
