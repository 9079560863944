import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity5',
  templateUrl: './activity5.component.html',
  styleUrls: ['./activity5.component.css']
})
export class Activity5Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '5'; // se refiere al número de actividad
  activity: Activity = new Activity();

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var pincel;
    var verde = '#0d8937';
    var rojo = '#e51b20';
    $("#circuloV").click(function () {
      pincel = verde;
      $('#circuloR').css('border', '');
      $('#circuloV').css('border', 'solid #00bcd4');
    });
    $("#circuloR").click(function () {
      pincel = rojo;
      $('#circuloV').css('border', '');
      $("#circuloR").css('border', 'solid #00bcd4');
    });

    $(".cuadrosarca").click(function () {
      $(this).css("background-color", pincel);
      if (pincel == verde) {
        $(this).attr('afirmacion', 'v')
      } else {
        if (pincel == rojo) {
          $(this).attr('afirmacion', 'f')
        }
      }

    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var afirmaciones = [];
    $(".cuadrosarca").each(function (i) {
      var NumAfir = $(this).attr("afirmacion");
      afirmaciones[i] = NumAfir;
    });
    var Correctas = ['v', 'f', 'v', 'v', 'f', 'v'];
    console.log(afirmaciones);
    console.log(Correctas);
    var iguales = 0;
    for (var i = 0; i < afirmaciones.length; i++) {
      for (var j = i; j < (i + 1); j++) {
        if (afirmaciones[i] == Correctas[i])
          iguales++;

      }
    }
    console.log(iguales);
    if (iguales == 6) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    } else {
      $('#modalFail').modal('show');
      $('.ayudas').text('verifica que los campos sean los correctos.');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
