import { Injectable } from '@angular/core';
import { RelHeroeChallenge } from '../models/relHeroeChallenge';
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class RelationChallengesService {
  private collection: AngularFirestoreCollection<RelHeroeChallenge>;
  private document: AngularFirestoreDocument<RelHeroeChallenge>;
  constructor(private afs: AngularFirestore) { 
    this.collection = afs.collection<RelHeroeChallenge>('rel_heroes_challenges');
  }
  
  getSpecificChallenge(relHeroeChallenge:RelHeroeChallenge){
    
    return this.afs.collection('rel_heroes_challenges', ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('uid', '==', relHeroeChallenge.uid)
                     .where('id_heroe', '==', relHeroeChallenge.id_heroe)
                     .where('id_piece', '==', relHeroeChallenge.id_achievement)
                     .where('id_challenge', '==', relHeroeChallenge.id_challenge);
      return query;
    }).get();

  }

  create(relHeroeChallenge:RelHeroeChallenge){
    return this.collection.add(Object.assign({},relHeroeChallenge));
  }

  delete(id:string){
    this.document = this.afs.doc<RelHeroeChallenge>('rel_heroes_challenges/'+id);
    this.document.delete();
  }

  getAllCompleteChallenge(id_piece:string, id_heroe:string, uid:string){
    return this.afs.collection('rel_heroes_challenges', ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('uid', '==', uid)
                     .where('id_heroe', '==', id_heroe)
                     .where('id_piece', '==', id_piece).orderBy('createDate');
      return query;
    }).get();
  }

  getAllCompleteChallenge_v2(id_piece:string, id_heroe:string, uid:string){
    return this.afs.collection('rel_heroes_challenges', ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('uid', '==', uid)
                     .where('id_heroe', '==', id_heroe)
                     .where('id_piece', '==', id_piece).orderBy('createDate');
      return query;
    }).valueChanges();
  }

  getAllCompleteChallenge_v3(id_heroe:string, uid:string){
    return this.afs.collection('rel_heroes_challenges', ref => {
      let query : firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
        query = query.where('uid', '==', uid)
                     .where('id_heroe', '==', id_heroe).orderBy('createDate');
      return query;
    }).valueChanges();
  }

}
