import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
import Konva from 'konva';
declare var $: any;

@Component({
  selector: 'app-activity13',
  templateUrl: './activity13.component.html',
  styleUrls: ['./activity13.component.css']
})
export class Activity13Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '13'; // se refiere al número de actividad
  activity: Activity = new Activity();
  fishArray: any = [];
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    var width = 400;
    var height = 650;

    var stage = new Konva.Stage({
      container: 'container',
      width: width,
      height: height,
    });

    var layer = new Konva.Layer();
    stage.add(layer);

    var self = this;

    var verdadImg = new Image();
    verdadImg.src = './assets/img/actividades/13LosDiezMandamientos2/1-03.png';

    var respetoImg = new Image();
    respetoImg.src = './assets/img/actividades/13LosDiezMandamientos2/1-01.png';

    var mentiraImg = new Image();
    mentiraImg.src = './assets/img/actividades/13LosDiezMandamientos2/1-02.png';

    var honraImg = new Image();
    honraImg.src = './assets/img/actividades/13LosDiezMandamientos2/1-04.png';

    var pelearImg = new Image();
    pelearImg.src = './assets/img/actividades/13LosDiezMandamientos2/1-05.png';

    var calumniaImg = new Image();
    calumniaImg.src = './assets/img/actividades/13LosDiezMandamientos2/1-07.png';

    var enganioImg = new Image();
    enganioImg.src = './assets/img/actividades/13LosDiezMandamientos2/1-08.png';

    var jugarImg = new Image();
    jugarImg.src = './assets/img/actividades/13LosDiezMandamientos2/1-06.png';

    var pesceraImg = new Image();
    pesceraImg.src = './assets/img/actividades/13LosDiezMandamientos2/pecera-2.png';

    var pescera = new Konva.Image({
      image: pesceraImg,
      name: 'pescera',
      x: 50,
      y: 280,
      width: 250,
      height: 170,
      draggable: false,
    });
    layer.add(pescera);

    var verdadFish = new Konva.Image({
      image: verdadImg,
      name: 'verdad',
      x: 10,
      y: 88,
      width: 140,
      height: 85,
      draggable: true,
    });
    layer.add(verdadFish);

    var respetoFish = new Konva.Image({
      image: respetoImg,
      name: 'respeto',
      x: 190,
      y: 88,
      width: 140,
      height: 85,
      draggable: true,
    });
    layer.add(respetoFish);

    var mentiraFish = new Konva.Image({
      image: mentiraImg,
      name: 'mentira',
      x: 10,
      y: 180,
      width: 140,
      height: 85,
      draggable: true,
    });
    layer.add(mentiraFish);

    var honraFish = new Konva.Image({
      image: honraImg,
      name: 'honra',
      x: 190,
      y: 180,
      width: 140,
      height: 85,
      draggable: true,
    });
    layer.add(honraFish);

    var pelearFish = new Konva.Image({
      image: pelearImg,
      name: 'pelear',
      x: 10,
      y: 480,
      width: 140,
      height: 85,
      draggable: true,
    });
    layer.add(pelearFish);

    var calumniaFish = new Konva.Image({
      image: calumniaImg,
      name: 'calumnia',
      x: 190,
      y: 480,
      width: 140,
      height: 85,
      draggable: true,
    });
    layer.add(calumniaFish);

    var enganioFish = new Konva.Image({
      image: enganioImg,
      name: 'engaño',
      x: 10,
      y: 580,
      width: 140,
      height: 85,
      draggable: true,
    });
    layer.add(enganioFish);

    var jugarFish = new Konva.Image({
      image: jugarImg,
      name: 'jugar',
      x: 190,
      y: 580,
      width: 140,
      height: 85,
      draggable: true,
    });
    layer.add(jugarFish);

    layer.on('dragmove', function(evt) {
      var shape = evt.target;
      document.body.style.cursor = 'pointer';
      shape.scaleX(1.2);
      shape.scaleY(1.2);
    });

    layer.on('dragend', function(evt) {
      var shape = evt.target;
      document.body.style.cursor = 'pointer';
      shape.scaleX(1);
      shape.scaleY(1);

      if(haveIntersection(pescera, shape)){
        if(self.fishArray.indexOf(shape.attrs.name) === -1){
          self.fishArray.push(shape.attrs.name);
        }
      }
      else{
        self.fishArray = self.fishArray.filter((fish) => fish !== shape.attrs.name);
      }
    });

    function haveIntersection(r1, r2) {
      return !(
        r2.attrs.x > r1.attrs.x + r1.attrs.width ||
        r2.attrs.x + r2.attrs.width < r1.attrs.x ||
        r2.attrs.y > r1.attrs.y + r1.attrs.height ||
        r2.attrs.y + r2.attrs.height < r1.attrs.y
      );
    }
    
  }

  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    console.log('pescera: ', this.fishArray);
    if(this.fishArray.length === 0){
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad arrastrando los peces a la pescera');
    }
    else if(
      this.fishArray.indexOf('verdad') !== -1 &&
      this.fishArray.indexOf('respeto') !== -1 &&
      this.fishArray.indexOf('mentira') === -1 &&
      this.fishArray.indexOf('honra') !== -1 &&
      this.fishArray.indexOf('pelear') === -1 &&
      this.fishArray.indexOf('calumnia') === -1 &&
      this.fishArray.indexOf('engaño') === -1 &&
      this.fishArray.indexOf('jugar') !== -1
      ){
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else{
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad dejando solo los peces correctos en la pescera');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
