import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
import Konva from 'konva';
declare var $: any;

@Component({
  selector: 'app-activity26',
  templateUrl: './activity26.component.html',
  styleUrls: ['./activity26.component.css']
})
export class Activity26Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '26'; // se refiere al número de actividad
  activity: Activity = new Activity();
  afirmaciones: any = [];
  Correctas: any = ['f','v','v','f','v'];
  correctArray: any = [];
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    let self = this;
    $(document).ready(function () {
      $(".cuadrosDavGol").on("keyup ", function () {
        $('.cuadrosDavGol').each(function (i) {
          let palabras = $(this).val().toLowerCase();
          self.afirmaciones[i] = palabras;
          if (self.afirmaciones[i] == self.Correctas[i]) {
            $(this).css("border-bottom", "3px solid #4caf50");
          } else {
            $(this).css("border-bottom", "");
          }
        });
      });
      console.log(self.Correctas.length);
    });

    var width = 400;
    var height = 650;

    var stage = new Konva.Stage({
      container: 'canvas',
      width: width,
      height: height,
    });

    var layer = new Konva.Layer();
    stage.add(layer);

    var cuadroImg = new Image();
    cuadroImg.src = './assets/img/actividades/26DavidGoliat3/marco.png';

    var cuadro = new Konva.Image({
      image: cuadroImg,
      name: 'cuadro',
      x: 0,
      y: 0,
      width: 379,
      height: 223,
      draggable: false,
    });
    layer.add(cuadro);

    var pieza1Img = new Image();
    pieza1Img.src = './assets/img/actividades/26DavidGoliat3/1-img.png';

    var pieza1 = new Konva.Image({
      image: pieza1Img,
      name: 'pieza1',
      x: 0,
      y: 250,
      width: 151,
      height: 55,
      draggable: true,
    });
    layer.add(pieza1);

    var pieza2Img = new Image();
    pieza2Img.src = './assets/img/actividades/26DavidGoliat3/2-img.png';

    var pieza2 = new Konva.Image({
      image: pieza2Img,
      name: 'pieza2',
      x: 180,
      y: 250,
      width: 228,
      height: 56,
      draggable: true,
    });
    layer.add(pieza2);

    var pieza3Img = new Image();
    pieza3Img.src = './assets/img/actividades/26DavidGoliat3/3-img.png';

    var pieza3 = new Konva.Image({
      image: pieza3Img,
      name: 'pieza3',
      x: 0,
      y: 320,
      width: 76,
      height: 166,
      draggable: true,
    });
    layer.add(pieza3);

    var pieza4Img = new Image();
    pieza4Img.src = './assets/img/actividades/26DavidGoliat3/4-img.png';

    var pieza4 = new Konva.Image({
      image: pieza4Img,
      name: 'pieza4',
      x: 90,
      y: 320,
      width: 152,
      height: 166,
      draggable: true,
    });
    layer.add(pieza4);

    var pieza5Img = new Image();
    pieza5Img.src = './assets/img/actividades/26DavidGoliat3/5-img.png';

    var pieza5 = new Konva.Image({
      image: pieza5Img,
      name: 'pieza5',
      x: 250,
      y: 320,
      width: 151,
      height: 111,
      draggable: true,
    });
    layer.add(pieza5);

    var pieza6Img = new Image();
    pieza6Img.src = './assets/img/actividades/26DavidGoliat3/6-img.png';

    var pieza6 = new Konva.Image({
      image: pieza6Img,
      name: 'pieza6',
      x: 0,
      y: 500,
      width: 76,
      height: 111,
      draggable: true,
    });
    layer.add(pieza6);

    var pieza7Img = new Image();
    pieza7Img.src = './assets/img/actividades/26DavidGoliat3/7-img.png';

    var pieza7 = new Konva.Image({
      image: pieza7Img,
      name: 'pieza7',
      x: 90,
      y: 500,
      width: 151,
      height: 111,
      draggable: true,
    });
    layer.add(pieza7);

    layer.on('dragend', function(evt) {
      var shape = evt.target;
      document.body.style.cursor = 'pointer';
      shape.scaleX(1);
      shape.scaleY(1);

      console.log(shape.attrs);
      self.correctArray = self.correctArray.filter((word) => word !== shape.attrs.name);
      
      if(shape.attrs.name === 'pieza1'){
        if(shape.attrs.y >= -7 && shape.attrs.y <= 7){
          if(shape.attrs.x >= 219 && shape.attrs.x <= 240){
            if(self.correctArray.indexOf(shape.attrs.name) === -1){
              self.correctArray.push(shape.attrs.name);
            }
          }
        }
      }

      if(shape.attrs.name === 'pieza2'){
        if(shape.attrs.y >= 162 && shape.attrs.y <= 172){
          if(shape.attrs.x >= 67 && shape.attrs.x <= 89){
            if(self.correctArray.indexOf(shape.attrs.name) === -1){
              self.correctArray.push(shape.attrs.name);
            }
          }
        }
      }

      if(shape.attrs.name === 'pieza3'){
        if(shape.attrs.y >= 44 && shape.attrs.y <= 64){
          if(shape.attrs.x >= 294 && shape.attrs.x <= 311){
            if(self.correctArray.indexOf(shape.attrs.name) === -1){
              self.correctArray.push(shape.attrs.name);
            }
          }
        }
      }

      if(shape.attrs.name === 'pieza4'){
        if(shape.attrs.y >= 43 && shape.attrs.y <= 68){
          if(shape.attrs.x >= -5 && shape.attrs.x <= 9){
            if(self.correctArray.indexOf(shape.attrs.name) === -1){
              self.correctArray.push(shape.attrs.name);
            }
          }
        }
      }

      if(shape.attrs.name === 'pieza5'){
        if(shape.attrs.y >= -5 && shape.attrs.y <= 14){
          if(shape.attrs.x >= 67 && shape.attrs.x <= 88){
            if(self.correctArray.indexOf(shape.attrs.name) === -1){
              self.correctArray.push(shape.attrs.name);
            }
          }
        }
      }

      if(shape.attrs.name === 'pieza6'){
        if(shape.attrs.y >= -9 && shape.attrs.y <= 11){
          if(shape.attrs.x >= -8 && shape.attrs.x <= 13){
            if(self.correctArray.indexOf(shape.attrs.name) === -1){
              self.correctArray.push(shape.attrs.name);
            }
          }
        }
      }

      if(shape.attrs.name === 'pieza7'){
        if(shape.attrs.y >= 45 && shape.attrs.y <= 67){
          if(shape.attrs.x >= 141 && shape.attrs.x <= 169){
            if(self.correctArray.indexOf(shape.attrs.name) === -1){
              self.correctArray.push(shape.attrs.name);
            }
          }
        }
      }

      console.log('self.correctArray', self.correctArray);
    });
  }

  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    let self = this;

    if(this.correctArray.length === 0){
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad arrastrando las imagenes al punto exacto');
    }
    else if(
      this.correctArray.indexOf('pieza1') !== -1 &&
      this.correctArray.indexOf('pieza2') !== -1 &&
      this.correctArray.indexOf('pieza3') !== -1 &&
      this.correctArray.indexOf('pieza4') !== -1 &&
      this.correctArray.indexOf('pieza5') !== -1 &&
      this.correctArray.indexOf('pieza6') !== -1 &&
      this.correctArray.indexOf('pieza7') !== -1
    ){
      let iguales=0;
      $('.cuadrosDavGol').each(function(i){
        if(self.afirmaciones[i]==self.Correctas[i] ){
          iguales++; 
        }
      });
      if (iguales==self.Correctas.length) {
        $('#modalOk').modal('show');
        this.guardarActividad();
      }else{
        $('#modalFail').modal('show');
        $('.ayudas').text('Verifica que las preguntas tengan las respuestas correctas. '+iguales+'/'+self.Correctas.length);
      };
    }
    else{
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad arrastrando las imagenes al punto exacto');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
