import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;
declare var wordfindgame: any;
declare var wordfind: any;
@Component({
  selector: 'app-activity71',
  templateUrl: './activity71.component.html',
  styleUrls: ['./activity71.component.css']
})
export class Activity71Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '71'; // se refiere al número de actividad
  activity: Activity = new Activity();
  $opciones = ['pez1', 'pez2', 'pan1', 'pan2', 'pan3', 'pan4', 'pan5'];
  words = [
    'gente',
    'cinco',
    'ojos',
    'levantó',
    'todos',
    'jesús',
    'cielo',
    'dos',
    'pescados'
  ];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    var $items = $(".drag-71"), $drop = $(".drop-71");
    $items.draggable({
      revert: "invalid",
      stack: ".drag-71",
      snap: ".drop-71",//ajustar a la imagen de encaje
      snapMode: "inner",//ajustar dentro del contorno
      snapTolerance: 10,//distancia de ajuste
    });
    $drop.droppable({
      activeClass: "ui-state-default",
      hoverClass: "ui-state-hover",
      drop: function (event, ui) {
        for (var i = 0; i < self.$opciones.length; i++) {//identificar la pieza contra las opciones
          if (self.$opciones[i] == ui.draggable.attr("data-drag")) {//si encunentra una similitud
            self.$opciones.splice(i, 1);
          }
         
        }
      }
    });
    let words = this.words;
    var gamePuzzle = wordfindgame.create(words, '#juego', '#Palabras'); 
    var puzzle = wordfind.newPuzzle(words,{height: 18, width:18, fillBlanks: false});
    wordfind.print(puzzle);   
    $('#solve').click( function() {wordfindgame.solve(gamePuzzle, words);});

    document.body.addEventListener("touchmove", ev => {
      if (ev.touches.length > 1) {
        ev.preventDefault();
        ev.stopImmediatePropagation();
      }
    }, false);
  }

  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    console.log(this.$opciones);
    var iguales = 0;
    let Correctas = this.words;
    var afirmaciones = [];
    $('.word').each(function (i) {
      let palabras = $(this).attr('text');
      afirmaciones[i] = palabras;
      if (Correctas.indexOf(palabras) !== -1) {
        iguales++;
        $(this).css("background-color", "#4caf50");
      } else {
        $(this).css("background-color", "red");
      }
    });
    if(this.$opciones.length==0){
      
      if (iguales == this.words.length) {
        $('#modalOk').modal('show');
        this.guardarActividad();
      } else {
        $('#modalFail').modal('show');
        $('.ayudas').text('Encuentra todas las palabras. Llevas ' + iguales + ' de ' + this.words.length);
      };
    }else{
      $('#modalFail').modal('show');
      $('.ayudas').text('Coloca todos los Alimentos en la canasta');
    }
   
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
