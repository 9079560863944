import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity36',
  templateUrl: './activity36.component.html',
  styleUrls: ['./activity36.component.css']
})
export class Activity36Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '36'; // se refiere al número de actividad
  activity: Activity = new Activity();
  correctas = ["con", "tu", "vara", "de", "pastor", "me", "infundes", "nuevo", "aliento"];
  arrayGuardar = [];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    var contador = 0;
    
    var pos = 0

    var cont = 0;
    
    $(".draggable-41").draggable({ containment: "#containment-wrapper", scroll: false });

    $(".draggable-41").draggable({ revert: "invalid" });

    $('.droppable-73').droppable({
      accept: '.draggable-41',
      hoverClass: 'active',
      drop: function (e, ui) {
        var temp = $(this).attr('id1');

        if (cont < 9) {

          $(ui.draggable).css({ "padding": "0.5em" });
          $(ui.draggable).animate({ width: 100, height: 100 }, 500);


          // $(ui.draggable).draggable("disable"); 
          $(ui.draggable).css({ "top": "100px;" });

          if ((ui.draggable).attr("id") == "drag1") {
            self.arrayGuardar[cont] = "aliento";

          } else if ((ui.draggable).attr("id") == "drag2") {
            self.arrayGuardar[cont] = "con";
          } else if ((ui.draggable).attr("id") == "drag3") {
            self.arrayGuardar[cont] = "de";
          } else if ((ui.draggable).attr("id") == "drag4") {
            self.arrayGuardar[cont] = "infundes";
          } else if ((ui.draggable).attr("id") == "drag5") {
            self.arrayGuardar[cont] = "me";
          } else if ((ui.draggable).attr("id") == "drag6") {
            self.arrayGuardar[cont] = "nuevo";
          } else if ((ui.draggable).attr("id") == "drag7") {
            self.arrayGuardar[cont] = "pastor";
          } else if ((ui.draggable).attr("id") == "drag8") {
            self.arrayGuardar[cont] = "tu";
          } else if ((ui.draggable).attr("id") == "drag9") {
            self.arrayGuardar[cont] = "vara";
          }
          cont++;
          console.log(self.arrayGuardar)
          $('.text-samuel1').val(self.arrayGuardar.join(' ')).effect("highlight", { color: "#17a2b8" }, 1000,
            function () {
              $(this).css("border-radius", "100px")
            }

          );
        }
      }
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var posx = [-100, 0, 100]
    var posy = [-425, -550, -675, -675, -800, -925, -900, -1025, -1150]
    var iguales = 0;
    for (var i = 0; i < 9; i++) {
      if (this.correctas[i] == this.arrayGuardar[i]) {
        iguales++
      }
    }
    if (iguales < 9) {
      $('#myModal2').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica que las obejas hayan ingresado en el orden correcto ' + iguales + '/9');
      var cont = 0;
      $('.text-samuel1').val(' ');
      this.arrayGuardar = [];
      $("#drag1").draggable("enable");
      $("#drag1").draggable({ revert: "invalid" });
      $("#drag2").draggable("enable");
      $("#drag2").draggable({ revert: "invalid" });
      $("#drag3").draggable("enable");
      $("#drag3").draggable({ revert: "invalid" });
      $("#drag4").draggable("enable");
      $("#drag4").draggable({ revert: "invalid" });
      $("#drag5").draggable("enable");
      $("#drag5").draggable({ revert: "invalid" });
      $("#drag6").draggable("enable");
      $("#drag6").draggable({ revert: "invalid" });
      $("#drag7").draggable("enable");
      $("#drag7").draggable({ revert: "invalid" });
      $("#drag8").draggable("enable");
      $("#drag8").draggable({ revert: "invalid" });
      $("#drag9").draggable("enable");
      $("#drag9").draggable({ revert: "invalid" });


      $("#drag1").animate({ width: 125, height: 125 }, 1000);
      $("#drag2").animate({ width: 125, height: 125 }, 1000);
      $("#drag3").animate({ width: 125, height: 125 }, 1000);
      $("#drag4").animate({ width: 125, height: 125 }, 1000);
      $("#drag5").animate({ width: 125, height: 125 }, 1000);
      $("#drag6").animate({ width: 125, height: 125 }, 1000);
      $("#drag7").animate({ width: 125, height: 125 }, 1000);
      $("#drag8").animate({ width: 125, height: 125 }, 1000);
      $("#drag9").animate({ width: 125, height: 125 }, 1000);


      $(".draggable4-1").css({ "height": "125px", "width": "125px" })
      $("#drag1").css({ 'left': posx[1] + "px", 'top': posy[0] + "px" });
      $("#drag2").css({ 'left': posx[1] + "px", 'top': posy[0] + "px" });
      $("#drag3").css({ 'left': posx[1] + "px", 'top': posy[0] + "px" });
      $("#drag4").css({ 'left': posx[1] + "px", 'top': posy[0] + "px" });
      $("#drag5").css({ 'left': posx[1] + "px", 'top': posy[0] + "px" });
      $("#drag6").css({ 'left': posx[1] + "px", 'top': posy[0] + "px" });
      $("#drag7").css({ 'left': posx[1] + "px", 'top': posy[0] + "px" });
      $("#drag8").css({ 'left': posx[1] + "px", 'top': posy[0] + "px" });
      $("#drag9").css({ 'left': posx[1] + "px", 'top': posy[0] + "px" });


    }

    if ((iguales == 9)) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }

  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
