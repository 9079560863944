import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Achievement } from '../models/achievement';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AchievementService {
  
  private collection: AngularFirestoreCollection<Achievement>;
  private document: AngularFirestoreDocument<Achievement>;
  queryResult: Observable<Achievement>;

  constructor(private afs: AngularFirestore) { 
    this.collection = afs.collection<Achievement>('achievements');
  }
  getAll(){
    return this.afs.collection('achievements', ref => ref.orderBy('order')).get();
  }
  getById(id:string){
    this.document = this.afs.doc<Achievement>('achievements/'+id);
    return this.document.get();
  }
}
