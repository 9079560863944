import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity57',
  templateUrl: './activity57.component.html',
  styleUrls: ['./activity57.component.css']
})
export class Activity57Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '57'; // se refiere al número de actividad
  activity: Activity = new Activity();
  resp11 = ["_", "_", "_", "_"];
  resp12 = ['drop5', 'drop8', 'drop9'];
  resp13 = ['drop6', 'drop7', 'drop10'];
  arreglo3 = ["_", "_", "_", "_", "_", "_"];
  arreglo4 = ["_", "_", "_", "_", "_", "_"];

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;

    var inputNombre1 = self.resp11[0];
    var inputNombre2 = self.resp11[1];
    var inputNombre3 = self.resp11[2];
    var inputNombre4 = self.resp11[3];


    $('.draggable-41').draggable({ revert: true });
    $('.draggable-43').draggable({ revert: true });
    $('.draggable-42').draggable({ revert: true });

    $('.droppable-72').droppable({
      hoverClass: 'active',
      drop: function (e, ui) {
        var temp = $(this).attr('id');
        if (temp === "drop1") {
          inputNombre1 = $('#nombre1');
          var temp1 = verificarDRAG1(ui);

          /*
          var l = resp11.indexOf(temp1);
        if ( l !== -1 ) {
           resp11.splice( l, 1 );
          }*/

          for (var i = 0; i < 4; i++) {
            if (temp1 === self.resp11[i]) {
              self.resp11[i] = "_";
            }
          }

          self.resp11[0] = temp1;

          $(inputNombre1).css("border-radius", "100px");
          $(inputNombre1).css("background-color", "#e83e8c");
        } else if (temp === "drop2") {
          inputNombre2 = $('#nombre2');
          var temp1 = verificarDRAG1(ui);

          /*
          var l = resp11.indexOf(temp1 );
        if ( l !== -1 ) {
           resp11.splice( l, 1 );
          }*/

          for (var i = 0; i < 4; i++) {
            if (temp1 === self.resp11[i]) {
              self.resp11[i] = "_";
            }
          }
          self.resp11[1] = temp1;


          $(inputNombre2).css("border-radius", "100px");
          $(inputNombre2).css("background-color", "#e83e8c");
        } else if (temp === "drop3") {
          inputNombre3 = $('#nombre3');
          var temp1 = verificarDRAG1(ui);

          /*
            var l = resp11.indexOf(temp1 );
          if ( l !== -1 ) {
             resp11.splice( l, 1 );
            }*/

          for (var i = 0; i < 4; i++) {
            if (temp1 === self.resp11[i]) {
              self.resp11[i] = "_";
            }
          }
          self.resp11[2] = temp1;

          $(inputNombre3).css("border-radius", "100px");
          $(inputNombre3).css("background-color", "#e83e8c")

        } else if (temp === "drop4") {
          inputNombre4 = $('#nombre4');
          var temp1 = verificarDRAG1(ui);

          /*
            var l = resp11.indexOf(temp1 );
          if ( l !== -1 ) {
             resp11.splice( l, 1 );
            }*/

          for (var i = 0; i < 4; i++) {
            if (temp1 === self.resp11[i]) {
              self.resp11[i] = "_";
            }
          }
          self.resp11[3] = temp1;

          $(inputNombre4).css("border-radius", "100px");
          $(inputNombre4).css("background-color", "#e83e8c");
        }

        $(inputNombre1).val(self.resp11[0]);
        $(inputNombre2).val(self.resp11[1]);
        $(inputNombre3).val(self.resp11[2]);
        $(inputNombre4).val(self.resp11[3]);

      }


    });
    $('.droppable-73').droppable({
      hoverClass: 'active',
      drop: function (e, ui) {
        var temp = $(this).attr('id');
        var flag = true;
        if (verificarDRAG(ui) == "AM") {
          $(this).effect("shake", { times: 4, direction: "up" }, 1000,
            function () {
              $(this).css("background", "radial-gradient(gold,transparent 100%)");
              $(this).css("vertical-align", "middle");
              $(this).css("left", "0");
            }
          );

          for (var i = 0; i < 6; i++) {
            if (temp === self.arreglo4[i]) {
              self.arreglo4[i] = "_";
            }
          }

          for (var i = 0; i < 6; i++) {
            if (temp === self.arreglo3[i]) {
              flag = false;
            }

            if ("_" === self.arreglo3[i] && flag) {
              self.arreglo3[i] = temp;
              break;
            }
          }
          console.log(self.arreglo3);
        } else {
          flag = true;
          $(this).effect("shake", { times: 4, direction: "left" }, 1000,
            function () {
              $(this).css("background", "radial-gradient(darkblue,transparent 100%)");
              $(this).css("vertical-align", "middle");
            });

          for (var i = 0; i < 4; i++) {
            if (temp === self.arreglo3[i]) {
              self.arreglo3[i] = "_";
            }
          }

          for (var i = 0; i < 6; i++) {
            if (temp === self.arreglo4[i]) {
              flag = false;
            }

            if ("_" === self.arreglo4[i] && flag) {
              self.arreglo4[i] = temp;
              break;
            }
          }
          console.log(self.arreglo4);
        }
      }
    });

    function verificarDRAG1(ui) {
      var text = ui.draggable.attr("id");
      if (text === "drag1") {
        return "GRACIA";
      } else if (text === "drag2") {
        return "ESPERANZA";
      } else if (text === "drag3") {
        return "SABIDURÍA";
      } else if (text === "drag4") {
        return "SENCILLEZ";
      } else if (text === "drag5") {
        return "ESTATURA";
      } else if (text === "drag6") {
        return "SUTILEZA";
      } else if (text === "drag7") {
        return "DIFERENCIA";
      } else if (text === "drag8") {
        return "OBEDIENCIA";
      }
    };
    function verificarDRAG(ui) {
      var text = ui.draggable.attr("id");
      if (text === "drag10") {
        return "AM";
      } else if (text === "drag20") {
        return "AZ";
      }
    };
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    console.log(this.resp11);
    console.log(this.arreglo3);
    console.log(this.arreglo4);

    var iguales = 0;
    var iguales1 = 0;
    var iguales2 = 0;

    for (var i = 0; i < 4; i++) {
      if ("GRACIA" === this.resp11[i]) {
        iguales++;
      }
      if ("ESTATURA" === this.resp11[i]) {
        iguales++;
      }
      if ("OBEDIENCIA" === this.resp11[i]) {
        iguales++;
      }
      if ("SABIDURÍA" === this.resp11[i]) {
        iguales++;
      }
    }



    /*var k = resp11.indexOf("GRACIA");
    if(k !== -1){
    iguales++;
    }*/



    for (var i = 0; i < 4; i++) {
      if ("drop5" === this.arreglo3[i]) {
        iguales1++;
      }
      if ("drop8" === this.arreglo3[i]) {
        iguales1++;
      }
      if ("drop9" === this.arreglo3[i]) {
        iguales1++;
      }
    }



    for (var i = 0; i < 4; i++) {
      if ("drop6" === this.arreglo4[i]) {
        iguales2++;
      }
      if ("drop7" === this.arreglo4[i]) {
        iguales2++;
      }
      if ("drop10" === this.arreglo4[i]) {
        iguales2++;
      }
    }




    if (iguales < 3) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica que las palabras sean acorde con el versículo principal ' + iguales + '/4');
    }
    else if (iguales1 < 2) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica que las acciones que NO te hacen crecer sean las indicadas. ' + iguales1 + '/3');
    }
    else if (iguales2 < 2) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('verifica que las acciones que te hacen crecer sean las indicadas. ' + iguales2 + '/3');
    }



    if ((iguales == 4) && (iguales1 == 3) && (iguales2 == 3)) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }


  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
