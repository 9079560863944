import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { HeroeService } from 'src/app/services/heroe.service';
import { Heroe } from 'src/app/models/heroe';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = "";
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
  ) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
    this.idActivity = this._route.snapshot.paramMap.get('id');
  }
  ngOnInit() {

    if(this.idActivity !== ''){
      let route = '/activity_' + this.idActivity + '/' + this.idHero;
      this.router.navigate([route]);
    }

    if (this.idHero) {
      //TODO
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
      
    }

  }

}
