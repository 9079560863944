import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity66',
  templateUrl: './activity66.component.html',
  styleUrls: ['./activity66.component.css']
})
export class Activity66Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '66'; // se refiere al número de actividad
  activity: Activity = new Activity();
  arreglo = [];
  arreglo1 = [];
  arreglo3 = [];
  arreglo4 = [];
  resp12 = ['drop4', 'drop6', 'drop8'];
  resp13 = ['drop3', 'drop5', 'drop7'];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    $('.draggable-4').draggable({ revert: false });
    $('.draggable-41').draggable({ revert: "valid" });
    $('.draggable-42').draggable({ revert: "valid" });

    $('.droppable-72').droppable({
      hoverClass: 'active',
      drop: function (e, ui) {
        var temp = $(this).attr('id');

        $(this).effect("highlight", { color: "#17a2b8" }, 2000,
          function () { $(this).css("border-radius", "100px") }
        );

        var text = ui.draggable.text();

        if (temp === "drop1") {
          var text1 = text + "<br>";
          var j = self.arreglo.indexOf(text1);
          if (j == -1) {
            var text1 = text + "<br>";
            $("#select-result1").append(text1);
            self.arreglo.push(text1);
          }
        } else if (temp === "drop2") {
          var text1 = text + "<br>";
          var j = self.arreglo1.indexOf(text1);
          if (j == -1) {
            var text1 = text + "<br>";
            $("#select-result2").append(text1);
            self.arreglo1.push(text1);
          }
        }
      },
      out: function (event, ui) {
        $(this)
          .addClass("ui-state-highlight")

        var temp = $(this).attr('id');
        var text1 = ui.draggable.text();
        if (temp === "drop1") {
          $("#select-result1").text("");
          var text2 = text1 + "<br>";
          var i = self.arreglo.indexOf(text2);
          if (i !== -1) {
            self.arreglo.splice(i, 1);
          }
          $("#select-result1").append(self.arreglo);
        } else if (temp === "drop2") {
          $("#select-result2").text("");
          var text2 = text1 + "<br>";
          var i = self.arreglo1.indexOf(text2);
          if (i !== -1) {
            self.arreglo1.splice(i, 1);
          }
          $("#select-result2").append(self.arreglo1);
        }
      }
    });

    $('.droppable-73').droppable({
      hoverClass: 'active',
      drop: function (e, ui) {
        var temp = $(this).attr('id');

        if (verificarDRAG(ui) == "V") {
          var k = self.arreglo3.indexOf(temp);
          if (k == -1) {

            $(this).effect("scale", { percent: 150, direction: 'both' }, 1000,
              function () {
                $(this).css("background", "radial-gradient(lightgreen,transparent 70%)");
                $(this).css("vertical-align", "middle");
                $(this).css("left", "0");
              }
            );
            var l = self.arreglo4.indexOf(temp);
            if (l !== -1) {
              self.arreglo4.splice(l, 1);
            }

            self.arreglo3.push(temp);
            console.log(self.arreglo3);

          }
        } else {
          var k = self.arreglo4.indexOf(temp)
          if (k == -1) {
            $(this).effect("scale", { percent: 70, direction: 'both' }, 1000,
              function () {
                $(this).css("background", "radial-gradient(gold,transparent 70%)");
                $(this).css("vertical-align", "middle");
              });

            var l = self.arreglo3.indexOf(temp);
            if (l !== -1) {
              self.arreglo3.splice(l, 1);
            }

            self.arreglo4.push(temp);
            console.log(self.arreglo4);
          }
        }
      }
    });
    function verificarDRAG(ui) {
      var text = ui.draggable.attr("id");
      if (text === "drag10") {
        return "V";
      } else if (text === "drag20") {
        return "A";
      }
    }
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    console.log(this.arreglo);
    console.log(this.arreglo1)
    console.log(this.arreglo3);
    console.log(this.arreglo4);

    var iguales = 0;
    var iguales4 = 0;
    var iguales1 = 0;
    var iguales2 = 0;

    var k = this.arreglo.indexOf(" Orden <br>");
    if (k !== -1) {
      iguales++;
    }
    k = this.arreglo.indexOf(" Comunicación entre dos <br>");
    if (k !== -1) {
      iguales++;
    }

    k = this.arreglo1.indexOf(" Incomunicación <br>");
    if (k !== -1) {
      iguales++;
    }

    k = this.arreglo1.indexOf(" Desorden <br>");
    if (k !== -1) {
      iguales++;
    }

    var h = this.arreglo4.indexOf(this.resp12[0]);
    if (h !== -1) {
      iguales2++;
    }
    h = this.arreglo4.indexOf(this.resp12[1]);
    if (h !== -1) {
      iguales2++;
    }
    h = this.arreglo4.indexOf(this.resp12[2]);
    if (h !== -1) {
      iguales2++;
    }

    h = this.arreglo3.indexOf(this.resp13[0])
    if (h !== -1) {
      iguales1++;
    }
    h = this.arreglo3.indexOf(this.resp13[1]);
    if (h !== -1) {
      iguales1++;
    }
    h = this.arreglo3.indexOf(this.resp13[2])
    if (h !== -1) {
      iguales1++;
    }


    if (iguales < 4) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('verifica que las palabras esten ubicadas en el corazón Indicado. ' + iguales + '/4');

      if (iguales1 < 3) {
        $('#modalFail').modal('show');
        $('.ayudas').css('font-size', "20px")
        $('.ayudas').text('verifica que las acciones que reflejan tu comunión con DIOS sean las indicadas. ' + iguales1 + '/3');
      }
      if (iguales2 < 3) {
        $('#modalFail').modal('show');
        $('.ayudas').css('font-size', "20px")
        $('.ayudas').text('verifica que las acciones que NO reflejan tu comunión con DIOS sean las indicadas. ' + iguales2 + '/3');
      }
    }

    if ((iguales == 4) && (iguales1 == 3) && (iguales2 == 3)) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }

  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
