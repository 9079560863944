import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity23',
  templateUrl: './activity23.component.html',
  styleUrls: ['./activity23.component.css']
})
export class Activity23Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '23'; // se refiere al número de actividad
  activity: Activity = new Activity();
  words: any = [];
  profeta: any;
  eli: any;
  israel: any;
  llamo: any;
  casa: any;
  temia: any;
  habla: any;
  siervo: any;
  samuel: any;
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    this.profeta = document.getElementById('profeta') as HTMLInputElement | null;
    this.eli = document.getElementById('eli') as HTMLInputElement | null;
    this.israel = document.getElementById('israel') as HTMLInputElement | null;
    this.llamo = document.getElementById('llamo') as HTMLInputElement | null;
    this.casa = document.getElementById('casa') as HTMLInputElement | null;
    this.temia = document.getElementById('temia') as HTMLInputElement | null;
    this.habla = document.getElementById('habla') as HTMLInputElement | null;
    this.siervo = document.getElementById('siervo') as HTMLInputElement | null;
    this.samuel = document.getElementById('samuel') as HTMLInputElement | null;
  }

  check(cell: string) {
    document.getElementById(cell).style.background = 'red';
    this.words.push(cell);

    if(this.words.indexOf('A1') !== -1 &&
      this.words.indexOf('A2') !== -1 &&
      this.words.indexOf('A3') !== -1 &&
      this.words.indexOf('A4') !== -1 &&
      this.words.indexOf('A5') !== -1 &&
      this.words.indexOf('A6') !== -1 &&
      this.words.indexOf('A7') !== -1
    )
    {
      if (this.profeta != null) {
        this.profeta.checked = true;
        this.profeta.disabled = true;
      }
    }

    if(this.words.indexOf('B6') !== -1 &&
      this.words.indexOf('C7') !== -1 &&
      this.words.indexOf('D8') !== -1
    )
    {
      if (this.eli != null) {
        this.eli.checked = true;
        this.eli.disabled = true;
      }
    }

    if(this.words.indexOf('A9') !== -1 &&
      this.words.indexOf('B9') !== -1 &&
      this.words.indexOf('C9') !== -1 &&
      this.words.indexOf('D9') !== -1 &&
      this.words.indexOf('E9') !== -1 &&
      this.words.indexOf('F9') !== -1
    )
    {
      if (this.israel != null) {
        this.israel.checked = true;
        this.israel.disabled = true;
      }
    }

    if(this.words.indexOf('C1') !== -1 &&
      this.words.indexOf('D1') !== -1 &&
      this.words.indexOf('E1') !== -1 &&
      this.words.indexOf('F1') !== -1 &&
      this.words.indexOf('G1') !== -1
    )
    {
      if (this.llamo != null) {
        this.llamo.checked = true;
        this.llamo.disabled = true;
      }
    }

    if(this.words.indexOf('H1') !== -1 &&
      this.words.indexOf('H2') !== -1 &&
      this.words.indexOf('H3') !== -1 &&
      this.words.indexOf('H4') !== -1
    )
    {
      if (this.casa != null) {
        this.casa.checked = true;
        this.casa.disabled = true;
      }
    }

    if(this.words.indexOf('E11') !== -1 &&
      this.words.indexOf('F11') !== -1 &&
      this.words.indexOf('G11') !== -1 &&
      this.words.indexOf('H11') !== -1 &&
      this.words.indexOf('I11') !== -1
    )
    {
      if (this.temia != null) {
        this.temia.checked = true;
        this.temia.disabled = true;
      }
    }

    if(this.words.indexOf('C2') !== -1 &&
      this.words.indexOf('D3') !== -1 &&
      this.words.indexOf('E4') !== -1 &&
      this.words.indexOf('F5') !== -1 &&
      this.words.indexOf('G6') !== -1
    )
    {
      if (this.habla != null) {
        this.habla.checked = true;
        this.habla.disabled = true;
      }
    }

    if(this.words.indexOf('C4') !== -1 &&
      this.words.indexOf('D5') !== -1 &&
      this.words.indexOf('E6') !== -1 &&
      this.words.indexOf('F7') !== -1 &&
      this.words.indexOf('G8') !== -1 &&
      this.words.indexOf('H9') !== -1
    )
    {
      if (this.siervo != null) {
        this.siervo.checked = true;
        this.siervo.disabled = true;
      }
    }

    if(this.words.indexOf('F8') !== -1 &&
      this.words.indexOf('G7') !== -1 &&
      this.words.indexOf('H6') !== -1 &&
      this.words.indexOf('I5') !== -1 &&
      this.words.indexOf('J4') !== -1 &&
      this.words.indexOf('K3') !== -1
    )
    {
      if (this.samuel != null) {
        this.samuel.checked = true;
        this.samuel.disabled = true;
      }
    }
  }

  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    if(
      this.profeta.checked && this.profeta.disabled &&
      this.siervo.checked && this.siervo.disabled &&
      this.eli.checked && this.eli.disabled &&
      this.habla.checked && this.habla.disabled &&
      this.temia.checked && this.temia.disabled &&
      this.llamo.checked && this.llamo.disabled &&
      this.samuel.checked && this.samuel.disabled &&
      this.casa.checked && this.casa.disabled &&
      this.israel.checked && this.israel.disabled
    ){
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else{
      $('#modalFail').modal('show');
      $('.ayudas').text('Encuentra todas las palabras en la sopa de letras');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
