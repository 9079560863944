import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity49',
  templateUrl: './activity49.component.html',
  styleUrls: ['./activity49.component.css']
})
export class Activity49Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '49'; // se refiere al número de actividad
  activity: Activity = new Activity();

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    $('input[type=text][max]:not([max=""])').on('input', function (ev) {
      var $this = $(this);
      var maxlength = $this.attr('max').length;
      var value = $this.val();
      if (value && value.length >= maxlength) {
        $this.val(value.substr(0, maxlength));
      }
    });
    $(".text-OraJonas").on("keyup ", function () {
      var seleccion;
      var lstfrase;
      lstfrase = $(this).val().toLowerCase();
      seleccion = $(this).attr('seleccion');
      seleccion = seleccion - 1;
      var myArray = ['nave', 'marineros', 'jonás', 'gran pez'];
      for (var j = seleccion; j < (seleccion + 1); j++) {
        if (lstfrase == myArray[j]) {
          $(this).css({ "background-color": "#7a9c3a", "color": "white" });

        } else {
          $(this).css("background-color", "#ffffff00");
        }
      }
    })
    $(".letra").on("keyup ", function () {
      var seleccion2;
      var lstfrase;
      lstfrase = $(this).val().toLowerCase();
      seleccion2 = $(this).attr('seleccion2');
      seleccion2 = seleccion2 - 1;
      var myArray = ['h', 'u', 'i', 'r', 'd', 'e', 'l', 'a', 'p', 'r', 'e', 's', 'e', 'n', 'c', 'i', 'a', 'd', 'e', 'd', 'i', 'o', 's'];
      for (var j = seleccion2; j < (seleccion2 + 1); j++) {
        if (lstfrase == myArray[j]) {
          $(this).css({ "background-color": "#7a9c3a", "color": "white" });

        } else {
          $(this).css({ "background-color": "white", "color": "black" });
        }
      }
    })
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var lstfrase = $('.text-OraJonas');
    var arrayGuardar = [];
    for (var i = 0; i < lstfrase.length; i++) {
      arrayGuardar[i] = lstfrase[i].value.toLowerCase();
    }
    var myArray = ['nave', 'marineros', 'jonás', 'gran pez'];
    var iguales = 0;
    for (var i = 0; i < arrayGuardar.length; i++) {
      for (var j = i; j < (i + 1); j++) {
        if (arrayGuardar[i] == myArray[i])
          iguales++;
      }
    }
    var lstfrase2 = $('.letra');
    var arrayGuardar2 = [];
    for (var i = 0; i < lstfrase2.length; i++) {
      arrayGuardar2[i] = lstfrase2[i].value.toLowerCase();
    }
    var myArray2 = ['h', 'u', 'i', 'r', 'd', 'e', 'l', 'a', 'p', 'r', 'e', 's', 'e', 'n', 'c', 'i', 'a', 'd', 'e', 'd', 'i', 'o', 's'];
    var iguales2 = 0;
    for (var i = 0; i < arrayGuardar2.length; i++) {
      for (var j = i; j < (i + 1); j++) {
        if (arrayGuardar2[i] == myArray2[i])
          iguales2++;
      }
    }
    

    if (iguales == 4 && iguales2 == 23) {// si el contador es igual a 4 ya son 4 true
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else {
      $('#modalFail').modal('show');
      $('.ayudas').text('Verifica que contestaste todo correctamente.');
    }
    
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
