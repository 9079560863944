import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity46',
  templateUrl: './activity46.component.html',
  styleUrls: ['./activity46.component.css']
})
export class Activity46Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '46'; // se refiere al número de actividad
  activity: Activity = new Activity();
  arreglo1 = [];
  resp12 = ['drag2', 'drag3', 'drag5', 'drag4', 'drag1', 'drag6'];
  resp13 = ['drop1', 'drop2', 'drop3', 'drop4', 'drop5', 'drop6'];
  arrTextos = ["Resisto el fuego a las más altas temperaturas.", "Uso joyas y corona porque soy un rey.",
    "Somos tres jóvenes judíos y servimos a Dios.", "Acusamos a los judíos ante el rey.", "Somos los hombres más fuertes del ejército.", "Soy de oro, mi imagen representa a Nabucodonosor."];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;


    $('.draggable-4').draggable({ revert: "invalid" });

    $('.droppable-7').droppable({
      accept: '.draggable-4',
      hoverClass: 'active',
      drop: function (e, ui) {
        var temp = $(this).attr('id');
        $(ui.draggable).draggable("disable");

        $(this).effect("highlight", { color: "#17a2b8" }, 2000,
          function () { $(this).css("border-radius", "90px") }
        );

        if (temp === "drop1") {
          var inputNombre = document.getElementById("text1");
          var temp1 = $(ui.draggable).attr("id");
          self.arrTextos[0] = $(inputNombre).text();
          $(inputNombre).addClass("newText");

          self.arreglo1[0] = temp1 + "-" + temp;

        } else if (temp === "drop2") {
          var inputNombre = document.getElementById("text2");
          var temp1 = $(ui.draggable).attr("id");
          self.arrTextos[1] = $(inputNombre).text();
          $(inputNombre).addClass("newText");

          self.arreglo1[1] = temp1 + "-" + temp;

        } else if (temp === "drop3") {
          var inputNombre = document.getElementById("text3");
          var temp1 = $(ui.draggable).attr("id");
          self.arrTextos[2] = $(inputNombre).text();
          $(inputNombre).addClass("newText");

          self.arreglo1[2] = temp1 + "-" + temp;

        } else if (temp === "drop4") {
          var inputNombre = document.getElementById("text4");
          var temp1 = $(ui.draggable).attr("id");
          self.arrTextos[3] = $(inputNombre).text();
          $(inputNombre).addClass("newText");

          self.arreglo1[3] = temp1 + "-" + temp;
        } else if (temp === "drop5") {
          var inputNombre = document.getElementById("text5");
          var temp1 = $(ui.draggable).attr("id");
          self.arrTextos[4] = $(inputNombre).text();
          $(inputNombre).addClass("newText");

          self.arreglo1[4] = temp1 + "-" + temp;
        } else if (temp === "drop6") {
          var inputNombre = document.getElementById("text6");
          var temp1 = $(ui.draggable).attr("id");
          self.arrTextos[5] = $(inputNombre).text();
          $(inputNombre).addClass("newText");

          self.arreglo1[5] = temp1 + "-" + temp;
        }
      }
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    console.log(this.arreglo1);
    console.log(this.resp12);
    console.log(this.resp13);

    var iguales = 0;

    for (var i = 0; i < 6; i++) {
      var temporalArr = this.resp12[i] + "-" + this.resp13[i];
      for (var j = 0; j < 6; j++)

        if (temporalArr == this.arreglo1[j])
          iguales++;
    }

    if (iguales < 6) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica bien las imágenes y las afirmaciones con las cuales las emparejaste ' + iguales + '/6');

      $("#text1").val("");
      $("#text2").val("");
      $("#text3").val("");
      $("#text4").val("");
      $("#text5").val("");
      $("#text6").val("");
      $("#text7").val("");


      $("#text1").removeClass("newText");
      $("#text2").removeClass("newText");
      $("#text3").removeClass("newText");
      $("#text4").removeClass("newText");
      $("#text5").removeClass("newText");
      $("#text6").removeClass("newText");
      $("#text7").removeClass("newText");

      $('.draggable-4').draggable("enable");
      $('.draggable-4').css({ 'left': "", 'top': "" });

      var tempText = this.arrTextos[0];
      var tempText1 = this.resp13[0];
      var tempText2 = this.resp12[0];

      for (var i = 0; i < 5; i++) {
        this.arrTextos[i] = this.arrTextos[i + 1];
        this.resp12[i] = this.resp12[i + 1];
        //resp13[i] = resp13[i+1];
      }
      this.arrTextos[5] = tempText;
      this.resp12[5] = tempText2;
      //resp13[5] =  tempText1;

      $("#text1").text(this.arrTextos[0]);
      $("#text2").text(this.arrTextos[1]);
      $("#text3").text(this.arrTextos[2]);
      $("#text4").text(this.arrTextos[3]);
      $("#text5").text(this.arrTextos[4]);
      $("#text6").text(this.arrTextos[5]);
      $("#text7").text(this.arrTextos[6]);
    }

    if ((iguales == 6)) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }

  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
