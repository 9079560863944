import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Activity } from '../models/activity';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  
  private collection: AngularFirestoreCollection<Activity>;
  private document: AngularFirestoreDocument<Activity>;
  queryResult: Observable<Activity>;

  constructor(private afs: AngularFirestore) { 
    this.collection = afs.collection<Activity>('activities');
  }
  getAll(){
    return this.afs.collection('activities', ref => ref.orderBy('order')).get();
  }
  getById(id:string){
    this.document = this.afs.doc<Activity>('activities/'+id);
    return this.document.get();
  }
}
