import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
import Konva from 'konva';
declare var $: any;

@Component({
  selector: 'app-activity55',
  templateUrl: './activity55.component.html',
  styleUrls: ['./activity55.component.css']
})
export class Activity55Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '55'; // se refiere al número de actividad
  activity: Activity = new Activity();
  totalColors : any = [];
  Correctas = ['señor', 'sueños', 'josé', 'david', 'recibir', 'maría', 'mujer','hijo','concebido','espíritu','santo','despertó','sueño','ángel','señor','mandado','recibió','mujer'];
  afirmaciones = [];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self=this;

    $(".frase").on("keyup ", function () {
      $('.frase').each(function (i) {
        let lstfrase = $(this).val().toLowerCase();
        self.afirmaciones[i] = lstfrase;
        if (self.afirmaciones[i] == self.Correctas[i]) {
          $(this).css("border-bottom", "3px solid #4caf50");
        } else {
          $(this).css("border-bottom", "");
        }
      });
    });
    var width = window.innerWidth;
    var height = window.innerHeight;

    if(width <= 400) {
      width = 340;
      height = 485;
    }
    else {
      width = 605;
      height = 863;
    }

    var self = this;

    var stage = new Konva.Stage({
      container: 'canvas',
      width: width,
      height: height
    });

    var layer = new Konva.Layer();
    stage.add(layer);

    var background = new Konva.Rect({
      fill: '#FFF',
      name: 'background',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(background);

    var cieloTrazoImg = new Image();
    cieloTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/ESCENARIOS/CON_TRAZO/BLANCO/Cielo.png';

    var cieloTrazo = new Konva.Image({
      image: cieloTrazoImg,
      name: 'cieloTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cieloTrazo);

    var nubesTrazoImg = new Image();
    nubesTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/ESCENARIOS/CON_TRAZO/BLANCO/Nubes.png';

    var nubesTrazo = new Konva.Image({
      image: nubesTrazoImg,
      name: 'nubesTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(nubesTrazo);

    var pastoTrazoImg = new Image();
    pastoTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/ESCENARIOS/CON_TRAZO/BLANCO/Pasto.png';

    var pastoTrazo = new Konva.Image({
      image: pastoTrazoImg,
      name: 'pastoTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(pastoTrazo);

    var cabelloJoseTrazoImg = new Image();
    cabelloJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Cabello.png';

    var cabelloJoseTrazo = new Konva.Image({
      image: cabelloJoseTrazoImg,
      name: 'cabelloJoseTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cabelloJoseTrazo);

    var irisJoseTrazoImg = new Image();
    irisJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Iris.png';

    var irisJoseTrazo = new Konva.Image({
      image: irisJoseTrazoImg,
      name: 'irisJoseTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(irisJoseTrazo);

    var ojosJoseTrazoImg = new Image();
    ojosJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Ojos.png';

    var ojosJoseTrazo = new Konva.Image({
      image: ojosJoseTrazoImg,
      name: 'ojosJoseTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(ojosJoseTrazo);

    var cejasJoseTrazoImg = new Image();
    cejasJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/cejas.png';

    var cejasJoseTrazo = new Konva.Image({
      image: cejasJoseTrazoImg,
      name: 'cejasJoseTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cejasJoseTrazo);

    var pielJoseTrazoImg = new Image();
    pielJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Piel.png';

    var pielJoseTrazo = new Konva.Image({
      image: pielJoseTrazoImg,
      name: 'pielJoseTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(pielJoseTrazo);

    var ropa1JoseTrazoImg = new Image();
    ropa1JoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Ropa1.png';

    var ropa1JoseTrazo = new Konva.Image({
      image: ropa1JoseTrazoImg,
      name: 'ropa1JoseTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(ropa1JoseTrazo);

    var ropa2JoseTrazoImg = new Image();
    ropa2JoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Ropa2.png';

    var ropa2JoseTrazo = new Konva.Image({
      image: ropa2JoseTrazoImg,
      name: 'ropa2JoseTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(ropa2JoseTrazo);

    var cabelloMariaTrazoImg = new Image();
    cabelloMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Cabello.png';

    var cabelloMariaTrazo = new Konva.Image({
      image: cabelloMariaTrazoImg,
      name: 'cabelloMariaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cabelloMariaTrazo);

    var caraMariaTrazoImg = new Image();
    caraMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/TRAZO/Cara.png';

    var caraMariaTrazo = new Konva.Image({
      image: caraMariaTrazoImg,
      name: 'caraMariaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(caraMariaTrazo);

    var irisMariaTrazoImg = new Image();
    irisMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Iris.png';

    var irisMariaTrazo = new Konva.Image({
      image: irisMariaTrazoImg,
      name: 'irisMariaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(irisMariaTrazo);

    var ojosMariaTrazoImg = new Image();
    ojosMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Ojos.png';

    var ojosMariaTrazo = new Konva.Image({
      image: ojosMariaTrazoImg,
      name: 'ojosMariaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(ojosMariaTrazo);

    var pielMariaTrazoImg = new Image();
    pielMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Piel.png';

    var pielMariaTrazo = new Konva.Image({
      image: pielMariaTrazoImg,
      name: 'pielMariaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(pielMariaTrazo);

    var ropa1MariaTrazoImg = new Image();
    ropa1MariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Ropa1.png';

    var ropa1MariaTrazo = new Konva.Image({
      image: ropa1MariaTrazoImg,
      name: 'ropa1MariaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(ropa1MariaTrazo);

    var ropa2MariaTrazoImg = new Image();
    ropa2MariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Ropa2.png';

    var ropa2MariaTrazo = new Konva.Image({
      image: ropa2MariaTrazoImg,
      name: 'ropa2MariaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(ropa2MariaTrazo);

    // Hasta aca van las capas de imagenes y comienzas las de colores

    var colors = new Konva.Stage({
      container: 'colors',
      width: window.innerWidth >= 1000 ? 800 : 350,
      height: window.innerWidth <= 400 ? 200 : 300,
    });

    var layerColors = new Konva.Layer();

    var backgroundColors = new Konva.Rect({
      fill: '#FFF',
      name: 'colors',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layerColors.add(backgroundColors);

    var blue = new Konva.Circle({
      x: stage.width() / 8,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#24B2E6',
      stroke: 'black',
      strokeWidth: 4,
      name: 'blue'
    });

    blue.on('click', function (evt) {
      cieloTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/ESCENARIOS/CON_TRAZO/COLOR/Cielo.png';
      ojosJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/COLOR/Ojos.png';
      ojosMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/COLOR/Ojos.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    blue.on('touchend', function (evt) {
      cieloTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/ESCENARIOS/CON_TRAZO/COLOR/Cielo.png';
      ojosJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/COLOR/Ojos.png';
      ojosMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/COLOR/Ojos.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(blue);

    var blue2 = new Konva.Circle({
      x: stage.width() / 8 * 2,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#CDEAF9',
      stroke: 'black',
      strokeWidth: 4,
      name: 'blue2'
    });

    blue2.on('click', function (evt) {
      nubesTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/ESCENARIOS/CON_TRAZO/COLOR/Nubes.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    blue2.on('touchend', function (evt) {
      nubesTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/ESCENARIOS/CON_TRAZO/COLOR/Nubes.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(blue2);

    var green = new Konva.Circle({
      x: stage.width() / 8 * 3,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#3F7235',
      stroke: 'black',
      strokeWidth: 4,
      name: 'green'
    });

    green.on('click', function (evt) {
      pastoTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/ESCENARIOS/CON_TRAZO/COLOR/Pasto.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    green.on('touchend', function (evt) {
      pastoTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/ESCENARIOS/CON_TRAZO/COLOR/Pasto.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(green);

    var red = new Konva.Circle({
      x: stage.width() / 8 * 4,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#BD4027',
      stroke: 'black',
      strokeWidth: 4,
      name: 'red'
    });

    red.on('click', function (evt) {
      cabelloJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/COLOR/Cabello.png';
      irisJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/COLOR/Iris.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    red.on('touchend', function (evt) {
      cabelloJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/COLOR/Cabello.png';
      irisJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/COLOR/Iris.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(red);

    var red2 = new Konva.Circle({
      x: stage.width() / 8 * 5,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#F8B895',
      stroke: 'black',
      strokeWidth: 4,
      name: 'red2'
    });

    red2.on('click', function (evt) {
      pielJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/COLOR/Piel.png';
      pielMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/COLOR/Piel.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    red2.on('touchend', function (evt) {
      pielJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/COLOR/Piel.png';
      pielMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/COLOR/Piel.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(red2);

    var green2 = new Konva.Circle({
      x: stage.width() / 8 * 6,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#009A6C',
      stroke: 'black',
      strokeWidth: 4,
      name: 'green2'
    });

    green2.on('click', function (evt) {
      ropa1JoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/COLOR/Ropa1.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    green2.on('touchend', function (evt) {
      ropa1JoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/COLOR/Ropa1.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(green2);

    var yellow = new Konva.Circle({
      x: stage.width() / 8 * 7,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#EEEA82',
      stroke: 'black',
      strokeWidth: 4,
      name: 'yellow'
    });

    yellow.on('click', function (evt) {
      ropa2JoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/COLOR/Ropa2.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    yellow.on('touchend', function (evt) {
      ropa2JoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/COLOR/Ropa2.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(yellow);

    var red3 = new Konva.Circle({
      x: stage.width() / 8 * 1,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: '#EC6561',
      stroke: 'black',
      strokeWidth: 4,
      name: 'red3'
    });

    red3.on('click', function (evt) {
      cabelloMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/COLOR/Cabello.png';
      irisMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/COLOR/Iris.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    red3.on('touchend', function (evt) {
      cabelloMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/COLOR/Cabello.png';
      irisMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/COLOR/Iris.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(red3);

    var red4 = new Konva.Circle({
      x: stage.width() / 8 * 2,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: '#EA4C70',
      stroke: 'black',
      strokeWidth: 4,
      name: 'red4'
    });

    red4.on('click', function (evt) {
      ropa1MariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/COLOR/Ropa1.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    red4.on('touchend', function (evt) {
      ropa1MariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/COLOR/Ropa1.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(red4);

    var blue3 = new Konva.Circle({
      x: stage.width() / 8 * 3,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: '#AEDFDC',
      stroke: 'black',
      strokeWidth: 4,
      name: 'blue3'
    });

    blue3.on('click', function (evt) {
      ropa2MariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/COLOR/Ropa2.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    blue3.on('touchend', function (evt) {
      ropa2MariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/COLOR/Ropa2.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(blue3);

    var white = new Konva.Circle({
      x: stage.width() / 8 * 4,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: 'white',
      stroke: 'black',
      strokeWidth: 4,
      name : 'white'
    });

    white.on('click', function (evt) {
      cieloTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/ESCENARIOS/CON_TRAZO/BLANCO/Cielo.png';
      ojosJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Ojos.png';
      ojosMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Ojos.png';
      nubesTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/ESCENARIOS/CON_TRAZO/BLANCO/Nubes.png';
      pastoTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/ESCENARIOS/CON_TRAZO/BLANCO/Pasto.png';
      cabelloJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Cabello.png';
      irisJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Iris.png';
      pielJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Piel.png';
      pielMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Piel.png';
      ropa1JoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Ropa1.png';
      ropa2JoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Ropa2.png';
      cabelloMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Cabello.png';
      irisMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Iris.png';
      ropa1MariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Ropa1.png';
      ropa2MariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Ropa2.png';

      self.totalColors = [];
    });

    white.on('touchend', function (evt) {
      cieloTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/ESCENARIOS/CON_TRAZO/BLANCO/Cielo.png';
      ojosJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Ojos.png';
      ojosMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Ojos.png';
      nubesTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/ESCENARIOS/CON_TRAZO/BLANCO/Nubes.png';
      pastoTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/ESCENARIOS/CON_TRAZO/BLANCO/Pasto.png';
      cabelloJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Cabello.png';
      irisJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Iris.png';
      pielJoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Piel.png';
      pielMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Piel.png';
      ropa1JoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Ropa1.png';
      ropa2JoseTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/JOSE/CON_TRAZO/BLANCO/Ropa2.png';
      cabelloMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Cabello.png';
      irisMariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Iris.png';
      ropa1MariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Ropa1.png';
      ropa2MariaTrazoImg.src = './assets/img/actividades/55NacimJesus2/Partes/MARIA/CON_TRAZO/BLANCO/Ropa2.png';

      self.totalColors = [];
    });

    layerColors.add(white);

    colors.add(layerColors);

  }

  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    console.log(this.totalColors);
    var iguales=0;
    
		for(var i=0;i<this.afirmaciones.length;i++) 
		{
			for(var j=i;j<(i+1);j++) 
			{
				if(this.afirmaciones[i]==this.Correctas[i])  
					iguales++; 
			}
		}
		console.log (iguales); 
    if(this.totalColors.length === 0){
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad coloreando toda la imagen');
    }
    else if(
      this.totalColors.indexOf('blue') !== -1 &&
      this.totalColors.indexOf('blue2') !== -1 &&
      this.totalColors.indexOf('green') !== -1 &&
      this.totalColors.indexOf('red') !== -1 &&
      this.totalColors.indexOf('red2') !== -1 &&
      this.totalColors.indexOf('green2') !== -1 &&
      this.totalColors.indexOf('yellow') !== -1 &&
      this.totalColors.indexOf('red3') !== -1 &&
      this.totalColors.indexOf('red4') !== -1 &&
      this.totalColors.indexOf('blue3') !== -1
    ){
      if(iguales ==18){
        $('#modalOk').modal('show');
        this.guardarActividad();
      }
      else{
        $('#modalFail').modal('show');
        $('.ayudas').text('Completa los versículos');
      }
    }
    else{
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad coloreando toda la imagen');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
