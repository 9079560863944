import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeroeService } from 'src/app/services/heroe.service';
import { Heroe } from 'src/app/models/heroe';
import { NgForm } from '@angular/forms';
import { ConstantsService } from 'src/app/services/constants.service';
declare var $: any;

@Component({
  selector: 'app-edit-heroe',
  templateUrl: './edit-heroe.component.html',
  styleUrls: ['./edit-heroe.component.css']
})
export class EditHeroeComponent implements OnInit, OnChanges {

  // Slides
  slides;
  current = 0;
  prev = 3;
  next = 1;

  @Input() heroe: Heroe;
  displayErros = "none";
  avatars: {nameFile:string}[];

  constructor(private _route: ActivatedRoute,
    public _constants: ConstantsService,
    public _heroeService: HeroeService) {
    this.avatars = _constants.avatars;
  }

  ngOnInit() {
    this.slides = document.querySelectorAll(".item");
  }

  ngOnChanges({ heroe: { currentValue } }: SimpleChanges) {
    this.avatars.forEach((item,index) => item.nameFile === currentValue.nameFileAvatar ? this.current = index : null)
    this.prev = this.current - 1;
    this.next = this.current + 1;

    if (this.next == 4) {
      this.next = 0;
    }

    if (this.prev == -1) {
      this.prev = 3;
    }

    if( this.slides ) {
      this.slides[this.current].classList.add("active");
      this.slides[this.prev].classList.add("prev");
      this.slides[this.next].classList.add("next");
    }
  }

  updateHeroe(form: NgForm) {

    if (form.invalid) {
      this.displayErros = "initial";
      return;
    }
    this._heroeService.updateHeroe(this.heroe).then(() => {
      $('#EditarNinio').modal('hide');
    });
  }

  // Go to prev slide
  gotoPrev = () => this.current > 0 ? this.gotoNum(this.current - 1) : this.gotoNum(this.slides.length - 1);

  // Go to next slide
  gotoNext = () => this.current < 3 ? this.gotoNum(this.current + 1) : this.gotoNum(0);

  private gotoNum(number) {
    this.current = number;
    this.prev = this.current - 1;
    this.next = this.current + 1;

    for (let i = 0; i < this.slides.length; i++) {
      this.slides[i].classList.remove("active");
      this.slides[i].classList.remove("prev");
      this.slides[i].classList.remove("next");
    }

    if (this.next == 4) {
      this.next = 0;
    }

    if (this.prev == -1) {
      this.prev = 3;
    }

    this.slides[this.current].classList.add("active");
    this.slides[this.prev].classList.add("prev");
    this.slides[this.next].classList.add("next");

    this.heroe.nameFileAvatar = this.avatars[this.current].nameFile;
  }
}
