import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
import Konva from 'konva';
declare var $: any;

@Component({
  selector: 'app-activity28',
  templateUrl: './activity28.component.html',
  styleUrls: ['./activity28.component.css']
})
export class Activity28Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '28'; // se refiere al número de actividad
  activity: Activity = new Activity();
  happyHeartArray: any = [];
  sadHeartArray: any = [];
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    var width = 400;
    var height = 900;

    var stage = new Konva.Stage({
      container: 'container',
      width: width,
      height: height,
    });

    var layer = new Konva.Layer();
    stage.add(layer);

    var self = this;

    var happyHeartImg = new Image();
    happyHeartImg.src = './assets/img/actividades/28DavidSaul3/corazon-1.png';

    var sadHeartImg = new Image();
    sadHeartImg.src = './assets/img/actividades/28DavidSaul3/corazon-2.png';

    var happyHeart = new Konva.Image({
      image: happyHeartImg,
      name: 'happy',
      x: 0,
      y: 260,
      width: 300,
      height: 305,
      draggable: false,
    });
    layer.add(happyHeart);

    var sadHeart = new Konva.Image({
      image: sadHeartImg,
      name: 'sad',
      x: 0,
      y: 600,
      width: 300,
      height: 305,
      draggable: false,
    });
    layer.add(sadHeart);

    var injusticia = new Konva.Group({
      name: 'injusticia',
      draggable: true,
    });

    var injusticiaText = new Konva.Text({
      x: 0,
      y: 0,
      text: 'Injusticia',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var injusticiaRect = new Konva.Rect({
      x: 0,
      y: 0,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: injusticiaText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    injusticia.add(injusticiaRect);
    injusticia.add(injusticiaText);

    layer.add(injusticia);

    var miedo = new Konva.Group({
      name: 'miedo',
      draggable: true,
    });

    var miedoText = new Konva.Text({
      x: 130,
      y: 0,
      text: 'Miedo',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var miedoRect = new Konva.Rect({
      x: 130,
      y: 0,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: miedoText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    miedo.add(miedoRect);
    miedo.add(miedoText);

    layer.add(miedo);

    var valor = new Konva.Group({
      name: 'valor',
      draggable: true,
    });

    var valorText = new Konva.Text({
      x: 260,
      y: 0,
      text: 'Valor',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var valorRect = new Konva.Rect({
      x: 260,
      y: 0,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: valorText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    valor.add(valorRect);
    valor.add(valorText);

    layer.add(valor);

    var envidia = new Konva.Group({
      name: 'envidia',
      draggable: true,
    });

    var envidiaText = new Konva.Text({
      x: 0,
      y: 70,
      text: 'Envidia',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var envidiaRect = new Konva.Rect({
      x: 0,
      y: 70,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: envidiaText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    envidia.add(envidiaRect);
    envidia.add(envidiaText);

    layer.add(envidia);

    var respeto = new Konva.Group({
      name: 'respeto',
      draggable: true,
    });

    var respetoText = new Konva.Text({
      x: 130,
      y: 70,
      text: 'Respeto',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var respetoRect = new Konva.Rect({
      x: 130,
      y: 70,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: respetoText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    respeto.add(respetoRect);
    respeto.add(respetoText);

    layer.add(respeto);

    var tristeza = new Konva.Group({
      name: 'tristeza',
      draggable: true,
    });

    var tristezaText = new Konva.Text({
      x: 260,
      y: 70,
      text: 'Tristeza',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var tristezaRect = new Konva.Rect({
      x: 260,
      y: 70,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: tristezaText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    tristeza.add(tristezaRect);
    tristeza.add(tristezaText);

    layer.add(tristeza);

    var amor = new Konva.Group({
      name: 'amor',
      draggable: true,
    });

    var amorText = new Konva.Text({
      x: 0,
      y: 140,
      text: 'Amor',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var amorRect = new Konva.Rect({
      x: 0,
      y: 140,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: amorText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    amor.add(amorRect);
    amor.add(amorText);

    layer.add(amor);

    var justicia = new Konva.Group({
      name: 'justicia',
      draggable: true,
    });

    var justiciaText = new Konva.Text({
      x: 130,
      y: 140,
      text: 'Justicia',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var justiciaRect = new Konva.Rect({
      x: 130,
      y: 140,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: justiciaText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    justicia.add(justiciaRect);
    justicia.add(justiciaText);

    layer.add(justicia);

    var frustracion = new Konva.Group({
      name: 'frustracion',
      draggable: true,
    });

    var frustracionText = new Konva.Text({
      x: 260,
      y: 140,
      text: 'Frustración',
      fontSize: 18,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var frustracionRect = new Konva.Rect({
      x: 260,
      y: 140,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: frustracionText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    frustracion.add(frustracionRect);
    frustracion.add(frustracionText);

    layer.add(frustracion);

    var sencillez = new Konva.Group({
      name: 'sencillez',
      draggable: true,
    });

    var sencillezText = new Konva.Text({
      x: 0,
      y: 210,
      text: 'Sencillez',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var sencillezRect = new Konva.Rect({
      x: 0,
      y: 210,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: sencillezText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    sencillez.add(sencillezRect);
    sencillez.add(sencillezText);

    layer.add(sencillez);

    var alegria = new Konva.Group({
      name: 'alegria',
      draggable: true,
    });

    var alegriaText = new Konva.Text({
      x: 130,
      y: 210,
      text: 'Alegría',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var alegriaRect = new Konva.Rect({
      x: 130,
      y: 210,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: alegriaText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    alegria.add(alegriaRect);
    alegria.add(alegriaText);

    layer.add(alegria);

    var orgullo = new Konva.Group({
      name: 'orgullo',
      draggable: true,
    });

    var orgulloText = new Konva.Text({
      x: 260,
      y: 210,
      text: 'Orgullo',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var orgulloRect = new Konva.Rect({
      x: 260,
      y: 210,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: orgulloText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    orgullo.add(orgulloRect);
    orgullo.add(orgulloText);

    layer.add(orgullo);

    layer.on('dragend', function(evt) {
      var shape = evt.target;
      document.body.style.cursor = 'pointer';

      self.happyHeartArray = self.happyHeartArray.filter((word) => word !== shape.attrs.name);
      self.sadHeartArray = self.sadHeartArray.filter((word) => word !== shape.attrs.name);

      if(shape.attrs.name === 'valor' || shape.attrs.name === 'miedo' || shape.attrs.name === 'injusticia'){
        if(shape.attrs.y >= 50 && shape.attrs.y < 550){
          if(self.happyHeartArray.indexOf(shape.attrs.name) === -1){
            self.happyHeartArray.push(shape.attrs.name);
          }
        }
        else if(shape.attrs.y >= 550){
          if(self.sadHeartArray.indexOf(shape.attrs.name) === -1){
            self.sadHeartArray.push(shape.attrs.name);
          }
        }
      }
      else if(shape.attrs.name === 'envidia' || shape.attrs.name === 'respeto' || shape.attrs.name === 'tristeza'){
        if(shape.attrs.y >= 50 && shape.attrs.y < 500){
          if(self.happyHeartArray.indexOf(shape.attrs.name) === -1){
            self.happyHeartArray.push(shape.attrs.name);
          }
        }
        else if(shape.attrs.y >= 500){
          if(self.sadHeartArray.indexOf(shape.attrs.name) === -1){
            self.sadHeartArray.push(shape.attrs.name);
          }
        }
      }
      else if(shape.attrs.name === 'amor' || shape.attrs.name === 'justicia' || shape.attrs.name === 'frustracion'){
        if(shape.attrs.y >= 50 && shape.attrs.y < 450){
          if(self.happyHeartArray.indexOf(shape.attrs.name) === -1){
            self.happyHeartArray.push(shape.attrs.name);
          }
        }
        else if(shape.attrs.y >= 450){
          if(self.sadHeartArray.indexOf(shape.attrs.name) === -1){
            self.sadHeartArray.push(shape.attrs.name);
          }
        }
      }
      else if(shape.attrs.y >= 50 && shape.attrs.y < 350){
        if(self.happyHeartArray.indexOf(shape.attrs.name) === -1){
          self.happyHeartArray.push(shape.attrs.name);
        }
      }
      else if(shape.attrs.y >= 380){
        if(self.sadHeartArray.indexOf(shape.attrs.name) === -1){
          self.sadHeartArray.push(shape.attrs.name);
        }
      }
    });
  }

  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    if(this.happyHeartArray.length === 0 && this.sadHeartArray.length === 0){
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad arrastrando las palabras a los corazones');
    }
    else if(
      this.happyHeartArray.indexOf('valor') !== -1 &&
      this.happyHeartArray.indexOf('respeto') !== -1 &&
      this.happyHeartArray.indexOf('amor') !== -1 &&
      this.happyHeartArray.indexOf('justicia') !== -1 &&
      this.happyHeartArray.indexOf('sencillez') !== -1 &&
      this.happyHeartArray.indexOf('alegria') !== -1 &&
      this.happyHeartArray.indexOf('orgullo') !== -1 &&
      this.sadHeartArray.indexOf('injusticia') !== -1 &&
      this.sadHeartArray.indexOf('miedo') !== -1 &&
      this.sadHeartArray.indexOf('envidia') !== -1 &&
      this.sadHeartArray.indexOf('tristeza') !== -1 &&
      this.sadHeartArray.indexOf('frustracion') !== -1
    ){
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else{
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad dejando las palabras correctas en cada corazón');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
