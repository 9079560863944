import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
import Konva from 'konva';
declare var $: any;

@Component({
  selector: 'app-activity12',
  templateUrl: './activity12.component.html',
  styleUrls: ['./activity12.component.css']
})
export class Activity12Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '12'; // se refiere al número de actividad
  activity: Activity = new Activity();
  correctArray: any = ['frase6'];
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    let self = this;
    var width = 400;
    var height = 400;

    var stage = new Konva.Stage({
      container: 'canvas',
      width: width,
      height: height,
    });

    var layer = new Konva.Layer();
    stage.add(layer);

    var frase1 = new Konva.Group({
      name: 'frase1',
      draggable: false,
    });

    var frase1Text = new Konva.Text({
      x: 0,
      y: 0,
      text: 'Los hijos de Israel se preguntaron ¿Qué era esa cosa menuda y redonda?',
      fontSize: 14,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 150,
      height: 80,
      padding: 10,
      align: 'center',
    });

    var frase1Rect = new Konva.Rect({
      x: 0,
      y: 0,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 150,
      height: frase1Text.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    frase1.add(frase1Rect);
    frase1.add(frase1Text);

    layer.add(frase1);

    var frase2 = new Konva.Group({
      name: 'frase2',
      draggable: false,
    });

    var frase2Text = new Konva.Text({
      x: 0,
      y: 100,
      text: '¿Qué debían hacer los hijos de Israel en el día sexto?',
      fontSize: 14,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 150,
      height: 80,
      padding: 10,
      align: 'center',
    });

    var frase2Rect = new Konva.Rect({
      x: 0,
      y: 100,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 150,
      height: frase2Text.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    frase2.add(frase2Rect);
    frase2.add(frase2Text);

    layer.add(frase2);

    var frase3 = new Konva.Group({
      name: 'frase3',
      draggable: false,
    });

    var frase3Text = new Konva.Text({
      x: 0,
      y: 200,
      text: '¿Por cuánto tiempo comieron pan los hijos de Israel?',
      fontSize: 14,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 150,
      height: 80,
      padding: 10,
      align: 'center',
    });

    var frase3Rect = new Konva.Rect({
      x: 0,
      y: 200,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 150,
      height: frase3Text.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    frase3.add(frase3Rect);
    frase3.add(frase3Text);

    layer.add(frase3);

    var frase4 = new Konva.Group({
      name: 'frase4',
      draggable: false,
    });

    var frase4Text = new Konva.Text({
      x: 0,
      y: 300,
      text: '¿Qué le sucedió al alimento de quienes no obedecieron las instrucciones dadas?',
      fontSize: 14,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 150,
      height: 80,
      padding: 10,
      align: 'center',
    });

    var frase4Rect = new Konva.Rect({
      x: 0,
      y: 300,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 150,
      height: frase4Text.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    frase4.add(frase4Rect);
    frase4.add(frase4Text);

    layer.add(frase4);

    var frase5 = new Konva.Group({
      name: 'frase5',
      draggable: true,
    });

    var frase5Text = new Konva.Text({
      x: 240,
      y: 0,
      text: 'Crió gusanos y tuvo olor a podrido.',
      fontSize: 14,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 150,
      height: 80,
      padding: 10,
      align: 'center',
    });

    var frase5Rect = new Konva.Rect({
      x: 240,
      y: 0,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 150,
      height: frase5Text.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    frase5.add(frase5Rect);
    frase5.add(frase5Text);

    layer.add(frase5);

    var frase6 = new Konva.Group({
      name: 'frase6',
      draggable: true,
    });

    var frase6Text = new Konva.Text({
      x: 240,
      y: 100,
      text: 'Preparar y recoger el doble de lo que hacían diariamente.',
      fontSize: 14,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 150,
      height: 80,
      padding: 10,
      align: 'center',
    });

    var frase6Rect = new Konva.Rect({
      x: 240,
      y: 100,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 150,
      height: frase6Text.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    frase6.add(frase6Rect);
    frase6.add(frase6Text);

    layer.add(frase6);

    var frase7 = new Konva.Group({
      name: 'frase7',
      draggable: true,
    });

    var frase7Text = new Konva.Text({
      x: 240,
      y: 200,
      text: 'Es el pan que el Señor os da para alimentarse, respondió Moisés.',
      fontSize: 14,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 150,
      height: 80,
      padding: 10,
      align: 'center',
    });

    var frase7Rect = new Konva.Rect({
      x: 240,
      y: 200,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 150,
      height: frase7Text.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    frase7.add(frase7Rect);
    frase7.add(frase7Text);

    layer.add(frase7);

    var frase8 = new Konva.Group({
      name: 'frase8',
      draggable: true,
    });

    var frase8Text = new Konva.Text({
      x: 240,
      y: 300,
      text: 'Por cuarenta años.',
      fontSize: 14,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 150,
      height: 80,
      padding: 10,
      align: 'center',
    });

    var frase8Rect = new Konva.Rect({
      x: 240,
      y: 300,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 150,
      height: frase8Text.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    frase8.add(frase8Rect);
    frase8.add(frase8Text);

    layer.add(frase8);

    var arrow1 = new Konva.Arrow({
      x: 150,
      y: 40,
      points: [0, 0, 80, 0],
      pointerLength: 20,
      pointerWidth: 20,
      fill: 'black',
      stroke: 'black',
      strokeWidth: 4,
    });

    layer.add(arrow1);

    var arrow2 = new Konva.Arrow({
      x: 150,
      y: 140,
      points: [0, 0, 80, 0],
      pointerLength: 20,
      pointerWidth: 20,
      fill: 'black',
      stroke: 'black',
      strokeWidth: 4,
    });

    layer.add(arrow2);

    var arrow3 = new Konva.Arrow({
      x: 150,
      y: 240,
      points: [0, 0, 80, 0],
      pointerLength: 20,
      pointerWidth: 20,
      fill: 'black',
      stroke: 'black',
      strokeWidth: 4,
    });

    layer.add(arrow3);

    var arrow4 = new Konva.Arrow({
      x: 150,
      y: 340,
      points: [0, 0, 80, 0],
      pointerLength: 20,
      pointerWidth: 20,
      fill: 'black',
      stroke: 'black',
      strokeWidth: 4,
    });

    layer.add(arrow4);

    layer.on('dragend', function(evt) {
      var shape = evt.target;
      document.body.style.cursor = 'pointer';

      console.log(shape.attrs);
      self.correctArray = self.correctArray.filter((word) => word !== shape.attrs.name);

      if(shape.attrs.name === 'frase5'){
        if(shape.attrs.y >= 275 && shape.attrs.y <= 335){
            self.correctArray.push(shape.attrs.name);
            frase5Rect.attrs.fill = '#236b29';
        }else{
          frase5Rect.attrs.fill = '#00000070';
        }
      }

      if(shape.attrs.name === 'frase6'){
        if(shape.attrs.y >= -20 && shape.attrs.y <= 40){
            self.correctArray.push(shape.attrs.name);
            frase6Rect.attrs.fill = '#236b29';
        }else{
          frase6Rect.attrs.fill = '#00000070';
        }
      }
      
      if(shape.attrs.name === 'frase7'){
        if(shape.attrs.y >= -215 && shape.attrs.y <= -165){
            self.correctArray.push(shape.attrs.name);
            frase7Rect.attrs.fill = '#236b29';
        }else{
          frase7Rect.attrs.fill = '#00000070';
        }
      }

      if(shape.attrs.name === 'frase8'){
        if(shape.attrs.y >= -122 && shape.attrs.y <= -61){
            self.correctArray.push(shape.attrs.name);
            frase8Rect.attrs.fill = '#236b29';
        }else{
          frase8Rect.attrs.fill = '#00000070';
        }
      }
      console.log('self.correctArray', self.correctArray);
    });
  }

  getInfoHeroe(){
    if (this.idHero) {

      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    if(this.correctArray.length === 0){
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad arrastrando las respuestas al frente de la pregunta');
    }
    else if(
      this.correctArray.indexOf('frase7') !== -1 &&
      this.correctArray.indexOf('frase6') !== -1 &&
      this.correctArray.indexOf('frase8') !== -1 &&
      this.correctArray.indexOf('frase5') !== -1
    ){
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else{
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad arrastrando las respuestas al frente de la pregunta');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
