import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { PagesComponent } from './pages/pages.component';

import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { RegisterComponent } from './pages/register/register.component';
import { HomeComponent } from './pages/home/home.component';
import { NewHeroeComponent } from './pages/new-heroe/new-heroe.component';
import { StatusHeroeComponent } from './pages/status-heroe/status-heroe.component';
import { TerminosycondicionesComponent } from './terminosycondiciones/terminosycondiciones.component';
import { RegisterEmailComponent } from './register-email/register-email.component';
import { LostPasswordComponent } from './lost-password/lost-password.component';
import { ActivityComponent } from './pages/activity/activity.component';
import { AchievementsComponent } from './pages/achievements/achievements.component';
import { Activity1Component } from './pages/activities/activity1/activity1.component';
import { Activity2Component } from './pages/activities/activity2/activity2.component';
import { Activity3Component } from './pages/activities/activity3/activity3.component';
import { Activity4Component } from './pages/activities/activity4/activity4.component';
import { Activity5Component } from './pages/activities/activity5/activity5.component';
import { Activity6Component } from './pages/activities/activity6/activity6.component';
import { Activity7Component } from './pages/activities/activity7/activity7.component';
import { Activity8Component } from './pages/activities/activity8/activity8.component';
import { Activity9Component } from './pages/activities/activity9/activity9.component';
import { Activity10Component } from './pages/activities/activity10/activity10.component';
import { Activity11Component } from './pages/activities/activity11/activity11.component';
import { Activity12Component } from './pages/activities/activity12/activity12.component';
import { Activity13Component } from './pages/activities/activity13/activity13.component';
import { Activity14Component } from './pages/activities/activity14/activity14.component';
import { Activity15Component } from './pages/activities/activity15/activity15.component';
import { Activity16Component } from './pages/activities/activity16/activity16.component';
import { Activity17Component } from './pages/activities/activity17/activity17.component';
import { Activity18Component } from './pages/activities/activity18/activity18.component';
import { Activity19Component } from './pages/activities/activity19/activity19.component';
import { Activity20Component } from './pages/activities/activity20/activity20.component';
import { Activity21Component } from './pages/activities/activity21/activity21.component';
import { Activity22Component } from './pages/activities/activity22/activity22.component';
import { Activity23Component } from './pages/activities/activity23/activity23.component';
import { Activity24Component } from './pages/activities/activity24/activity24.component';
import { Activity25Component } from './pages/activities/activity25/activity25.component';
import { Activity26Component } from './pages/activities/activity26/activity26.component';
import { Activity27Component } from './pages/activities/activity27/activity27.component';
import { Activity28Component } from './pages/activities/activity28/activity28.component';
import { Activity29Component } from './pages/activities/activity29/activity29.component';
import { Activity30Component } from './pages/activities/activity30/activity30.component';
import { Activity31Component } from './pages/activities/activity31/activity31.component';
import { Activity32Component } from './pages/activities/activity32/activity32.component';
import { Activity33Component } from './pages/activities/activity33/activity33.component';
import { Activity34Component } from './pages/activities/activity34/activity34.component';
import { Activity35Component } from './pages/activities/activity35/activity35.component';
import { Activity36Component } from './pages/activities/activity36/activity36.component';
import { Activity37Component } from './pages/activities/activity37/activity37.component';
import { Activity38Component } from './pages/activities/activity38/activity38.component';
import { Activity39Component } from './pages/activities/activity39/activity39.component';
import { Activity40Component } from './pages/activities/activity40/activity40.component';
import { Activity41Component } from './pages/activities/activity41/activity41.component';
import { Activity42Component } from './pages/activities/activity42/activity42.component';
import { Activity43Component } from './pages/activities/activity43/activity43.component';
import { Activity44Component } from './pages/activities/activity44/activity44.component';
import { Activity45Component } from './pages/activities/activity45/activity45.component';
import { Activity46Component } from './pages/activities/activity46/activity46.component';
import { Activity47Component } from './pages/activities/activity47/activity47.component';
import { Activity48Component } from './pages/activities/activity48/activity48.component';
import { Activity49Component } from './pages/activities/activity49/activity49.component';
import { Activity50Component } from './pages/activities/activity50/activity50.component';
import { Activity51Component } from './pages/activities/activity51/activity51.component';
import { Activity52Component } from './pages/activities/activity52/activity52.component';
import { Activity53Component } from './pages/activities/activity53/activity53.component';
import { Activity54Component } from './pages/activities/activity54/activity54.component';
import { Activity55Component } from './pages/activities/activity55/activity55.component';
import { Activity56Component } from './pages/activities/activity56/activity56.component';
import { Activity57Component } from './pages/activities/activity57/activity57.component';
import { Activity58Component } from './pages/activities/activity58/activity58.component';
import { Activity59Component } from './pages/activities/activity59/activity59.component';
import { Activity60Component } from './pages/activities/activity60/activity60.component';
import { Activity61Component } from './pages/activities/activity61/activity61.component';
import { Activity62Component } from './pages/activities/activity62/activity62.component';
import { Activity63Component } from './pages/activities/activity63/activity63.component';
import { Activity64Component } from './pages/activities/activity64/activity64.component';
import { Activity65Component } from './pages/activities/activity65/activity65.component';
import { Activity66Component } from './pages/activities/activity66/activity66.component';
import { Activity67Component } from './pages/activities/activity67/activity67.component';
import { Activity68Component } from './pages/activities/activity68/activity68.component';
import { Activity69Component } from './pages/activities/activity69/activity69.component';
import { Activity70Component } from './pages/activities/activity70/activity70.component';
import { Activity71Component } from './pages/activities/activity71/activity71.component';
import { Activity72Component } from './pages/activities/activity72/activity72.component';
import { Activity73Component } from './pages/activities/activity73/activity73.component';
import { Activity74Component } from './pages/activities/activity74/activity74.component';
import { Activity75Component } from './pages/activities/activity75/activity75.component';
import { Activity76Component } from './pages/activities/activity76/activity76.component';
import { Activity77Component } from './pages/activities/activity77/activity77.component';
import { Activity78Component } from './pages/activities/activity78/activity78.component';
import { Activity79Component } from './pages/activities/activity79/activity79.component';
import { Activity80Component } from './pages/activities/activity80/activity80.component';
import { Activity81Component } from './pages/activities/activity81/activity81.component';
import { Activity82Component } from './pages/activities/activity82/activity82.component';
import { Activity83Component } from './pages/activities/activity83/activity83.component';
import { Activity84Component } from './pages/activities/activity84/activity84.component';
import { Activity85Component } from './pages/activities/activity85/activity85.component';
import { Activity86Component } from './pages/activities/activity86/activity86.component';
import { Activity87Component } from './pages/activities/activity87/activity87.component';
import { Activity88Component } from './pages/activities/activity88/activity88.component';

const redirectLoggedInToIDashboard = () => redirectLoggedInTo(['dashboard']);
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {path:'', component:HomeComponent},
  {path:'home', component:HomeComponent},
  {path:'tyc', component:TerminosycondicionesComponent},
  {path:'emailregister', component:RegisterEmailComponent},
  {path:'lostpassword', component:LostPasswordComponent},
  {
    path:'',
    component:PagesComponent,
    children:[
      {path:'dashboard', component:DashboardComponent},
      {path:'register', component:RegisterComponent},
      {path:'', redirectTo:'/dashboard', pathMatch:'full'},
    ],
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {path:'new-heroe', component:NewHeroeComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'status-heroe/:id', component:StatusHeroeComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'achievement/:id/:id_heroe', component:AchievementsComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity/:id', component:ActivityComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity/:id/:id_heroe', component:ActivityComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'login', component:LoginComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe:redirectLoggedInToIDashboard} },
  {path:'56616C6569656E7465736120636F6E204A65737573B1/actividad/:id', component:AchievementsComponent, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_1', component:Activity1Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_1/:id_heroe', component:Activity1Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_2', component:Activity2Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_2/:id_heroe', component:Activity2Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_3', component:Activity3Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_3/:id_heroe', component:Activity3Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_4', component:Activity4Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_4/:id_heroe', component:Activity4Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_5', component:Activity5Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_5/:id_heroe', component:Activity5Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_6', component:Activity6Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_6/:id_heroe', component:Activity6Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_7', component:Activity7Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_7/:id_heroe', component:Activity7Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_8', component:Activity8Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_8/:id_heroe', component:Activity8Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_9', component:Activity9Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_9/:id_heroe', component:Activity9Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_10', component:Activity10Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_10/:id_heroe', component:Activity10Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_11', component:Activity11Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_11/:id_heroe', component:Activity11Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_12', component:Activity12Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_12/:id_heroe', component:Activity12Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_13', component:Activity13Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_13/:id_heroe', component:Activity13Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_14', component:Activity14Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_14/:id_heroe', component:Activity14Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_15', component:Activity15Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_15/:id_heroe', component:Activity15Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_16', component:Activity16Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_16/:id_heroe', component:Activity16Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_17', component:Activity17Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_17/:id_heroe', component:Activity17Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_18', component:Activity18Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_18/:id_heroe', component:Activity18Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_19', component:Activity19Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_19/:id_heroe', component:Activity19Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_20', component:Activity20Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_20/:id_heroe', component:Activity20Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_21', component:Activity21Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_21/:id_heroe', component:Activity21Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_22', component:Activity22Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_22/:id_heroe', component:Activity22Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_23', component:Activity23Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_23/:id_heroe', component:Activity23Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_24', component:Activity24Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_24/:id_heroe', component:Activity24Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_25', component:Activity25Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_25/:id_heroe', component:Activity25Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_26', component:Activity26Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_26/:id_heroe', component:Activity26Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_27', component:Activity27Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_27/:id_heroe', component:Activity27Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_28', component:Activity28Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_28/:id_heroe', component:Activity28Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_29', component:Activity29Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_29/:id_heroe', component:Activity29Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_30', component:Activity30Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_30/:id_heroe', component:Activity30Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_31', component:Activity31Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_31/:id_heroe', component:Activity31Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_32', component:Activity32Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_32/:id_heroe', component:Activity32Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_33', component:Activity33Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_33/:id_heroe', component:Activity33Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_34', component:Activity34Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_34/:id_heroe', component:Activity34Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_35', component:Activity35Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_35/:id_heroe', component:Activity35Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_36', component:Activity36Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_36/:id_heroe', component:Activity36Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_37', component:Activity37Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_37/:id_heroe', component:Activity37Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_38', component:Activity38Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_38/:id_heroe', component:Activity38Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_39', component:Activity39Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_39/:id_heroe', component:Activity39Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_40', component:Activity40Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_40/:id_heroe', component:Activity40Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_41', component:Activity41Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_41/:id_heroe', component:Activity41Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_42', component:Activity42Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_42/:id_heroe', component:Activity42Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_43', component:Activity43Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_43/:id_heroe', component:Activity43Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_44', component:Activity44Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_44/:id_heroe', component:Activity44Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_45', component:Activity45Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_45/:id_heroe', component:Activity45Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_46', component:Activity46Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_46/:id_heroe', component:Activity46Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_47', component:Activity47Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_47/:id_heroe', component:Activity47Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_48', component:Activity48Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_48/:id_heroe', component:Activity48Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_49', component:Activity49Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_49/:id_heroe', component:Activity49Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_50', component:Activity50Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_50/:id_heroe', component:Activity50Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_51', component:Activity51Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_51/:id_heroe', component:Activity51Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_52', component:Activity52Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_52/:id_heroe', component:Activity52Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_53', component:Activity53Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_53/:id_heroe', component:Activity53Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_54', component:Activity54Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_54/:id_heroe', component:Activity54Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_55', component:Activity55Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_55/:id_heroe', component:Activity55Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_56', component:Activity56Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_56/:id_heroe', component:Activity56Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_57', component:Activity57Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_57/:id_heroe', component:Activity57Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_58', component:Activity58Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_58/:id_heroe', component:Activity58Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_59', component:Activity59Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_59/:id_heroe', component:Activity59Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_60', component:Activity60Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_60/:id_heroe', component:Activity60Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_61', component:Activity61Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_61/:id_heroe', component:Activity61Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_62', component:Activity62Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_62/:id_heroe', component:Activity62Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_63', component:Activity63Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_63/:id_heroe', component:Activity63Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_64', component:Activity64Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_64/:id_heroe', component:Activity64Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_65', component:Activity65Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_65/:id_heroe', component:Activity65Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_66', component:Activity66Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_66/:id_heroe', component:Activity66Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_67', component:Activity67Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_67/:id_heroe', component:Activity67Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_68', component:Activity68Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_68/:id_heroe', component:Activity68Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_69', component:Activity69Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_69/:id_heroe', component:Activity69Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_70', component:Activity70Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_70/:id_heroe', component:Activity70Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_71', component:Activity71Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_71/:id_heroe', component:Activity71Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_72', component:Activity72Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_72/:id_heroe', component:Activity72Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_73', component:Activity73Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_73/:id_heroe', component:Activity73Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_74', component:Activity74Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_74/:id_heroe', component:Activity74Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_75', component:Activity75Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_75/:id_heroe', component:Activity75Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_76', component:Activity76Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_76/:id_heroe', component:Activity76Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_77', component:Activity77Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_77/:id_heroe', component:Activity77Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_78', component:Activity78Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_78/:id_heroe', component:Activity78Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_79', component:Activity79Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_79/:id_heroe', component:Activity79Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_80', component:Activity80Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_80/:id_heroe', component:Activity80Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_81', component:Activity81Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_81/:id_heroe', component:Activity81Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_82', component:Activity82Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_82/:id_heroe', component:Activity82Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_83', component:Activity83Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_83/:id_heroe', component:Activity83Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_84', component:Activity84Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_84/:id_heroe', component:Activity84Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_85', component:Activity85Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_85/:id_heroe', component:Activity85Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_86', component:Activity86Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_86/:id_heroe', component:Activity86Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_87', component:Activity87Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_87/:id_heroe', component:Activity87Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_88', component:Activity88Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'activity_88/:id_heroe', component:Activity88Component, canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }},
  {path:'**', component:NopagefoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:false})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
