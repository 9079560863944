import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity83',
  templateUrl: './activity83.component.html',
  styleUrls: ['./activity83.component.css']
})
export class Activity83Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '83'; // se refiere al número de actividad
  activity: Activity = new Activity();
  correctas = [];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self=this;
  

    $("#accordion").accordion({
      heightStyle: "fill"
    });

    $("#accordion-resizer").resizable({
      minHeight: 400,
      minWidth: 300,
      resize: function () {
        $("#accordion").accordion("refresh");
      }
      //#selectable .ui-selected { background: #F39814; color: transparent; }
    });

    $("#selectable").selectable({
      stop: function () {
        var result = $("#select-result").empty();
        $(".ui-selected", this).each(function () {
          var index = $("#selectable li").index(this);
          self.correctas[0] = index;

          result.append(" #" + (index + 1));
        });
      }
    });

    $("#selectable1").selectable({
      stop: function () {
        var result1 = $("#select-result1").empty();
        $(".ui-selected", this).each(function () {
          var index1 = $("#selectable1 li").index(this);
          self.correctas[1] = index1;
          result1.append(" #" + (index1 + 1));
        });
      }
    });

    $("#selectable2").selectable({
      stop: function () {
        var result2 = $("#select-result2").empty();
        $(".ui-selected", this).each(function () {
          var index2 = $("#selectable2 li").index(this);
          self.correctas[2] = index2;

          result2.append(" #" + (index2 + 1));
          $("#accordion").accordion("refresh");
        });
      }
    });

    $("#selectable3").selectable({
      stop: function () {
        var result2 = $("#select-result3").empty();
        $(".ui-selected", this).each(function () {
          var index2 = $("#selectable3 li").index(this);
          self.correctas[3] = index2;

          result2.append(" #" + (index2 + 1));
          $("#accordion").accordion("refresh");
        });
      }
    });

    $("#selectable4").selectable({
      stop: function () {
        var result2 = $("#select-result4").empty();
        $(".ui-selected", this).each(function () {
          var index2 = $("#selectable4 li").index(this);
          self.correctas[4] = index2;

          result2.append(" #" + (index2 + 1));
          $("#accordion").accordion("refresh");
        });
      }
    });

    $("#selectable5").selectable({
      stop: function () {
        var result2 = $("#select-result5").empty();
        $(".ui-selected", this).each(function () {
          var index2 = $("#selectable5 li").index(this);
          self.correctas[5] = index2;

          result2.append(" #" + (index2 + 1));
          $("#accordion").accordion("refresh");
        });
      }
    });

  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var iguales1 = 0;
    var respuestas = [2, 1, 0, 0, 2, 1];
    console.log(this.correctas)


    for (var i = 0; i < 6; i++) {
      if (this.correctas[i] == respuestas[i]) {
        iguales1++;
      }
    }

    if (iguales1 < 6) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica que las respuestas son las correctas ' + iguales1 + '/6');
      //Limpiar();	
    }

    if (iguales1 == 6) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
