export class User{
    uid:string;
    name:string;
    lastName:string;
    email:string;
    country:string;
    methodAuthenticate?:string;
    urlAvatar?:string;
    terms?:boolean;
    createDate?:number;
    password?:string;
}