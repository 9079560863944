import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity30',
  templateUrl: './activity30.component.html',
  styleUrls: ['./activity30.component.css']
})
export class Activity30Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '30'; // se refiere al número de actividad
  activity: Activity = new Activity();
  correctArray: any = [];
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    let self = this;
    $(document).ready(function () {
      $(".tarjeta-UnicDios").on("click", function () {
          let id = $(this).attr("id").replace('tarjeta', '');
          $(this).css("border-bottom", "3px solid #00ffff");
          //aca iria el cambio de background color de la cajita
          self.correctArray = self.correctArray.filter((word) => word !== id);
          self.correctArray.push(id);
      });
    });
  }

  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    console.log(this.correctArray);
    if(
      this.correctArray[0] === '0' &&
      this.correctArray[1] === '1' &&
      this.correctArray[2] === '2' &&
      this.correctArray[3] === '3' &&
      this.correctArray[4] === '4' &&
      this.correctArray[5] === '5' &&
      this.correctArray[6] === '6' &&
      this.correctArray[7] === '7' &&
      this.correctArray[8] === '8' &&
      this.correctArray[9] === '9' &&
      this.correctArray[10] === '10' &&
      this.correctArray[11] === '11' &&
      this.correctArray[12] === '12' &&
      this.correctArray[13] === '13' &&
      this.correctArray[14] === '14' &&
      this.correctArray[15] === '15' &&
      this.correctArray[16] === '16' &&
      this.correctArray[17] === '17'
    ){
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else{
      this.correctArray = [];
      $(".tarjeta-UnicDios").each(function(){
        $(this).css("border-bottom", "0px");
      });
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad cruzando el camino en orden de inicio a fin');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
