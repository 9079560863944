import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity40',
  templateUrl: './activity40.component.html',
  styleUrls: ['./activity40.component.css']
})

export class Activity40Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '40'; // se refiere al número de actividad
  activity: Activity = new Activity();
  words: any = [];
  sol: any;
  luna: any;
  hombre: any;
  morada: any;
  joven: any;
  creador: any;
  muerte: any;
  guardianes: any;
  temor: any;
  malos: any;
  calle: any;
  vanidad: any;
  camino: any;
  Dios: any;
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    this.sol = document.getElementById('sol') as HTMLInputElement | null;
    this.luna = document.getElementById('luna') as HTMLInputElement | null;
    this.hombre = document.getElementById('hombre') as HTMLInputElement | null;
    this.morada = document.getElementById('morada') as HTMLInputElement | null;
    this.joven = document.getElementById('joven') as HTMLInputElement | null;
    this.creador = document.getElementById('creador') as HTMLInputElement | null;
    this.muerte = document.getElementById('muerte') as HTMLInputElement | null;
    this.guardianes = document.getElementById('guardianes') as HTMLInputElement | null;
    this.temor = document.getElementById('temor') as HTMLInputElement | null;
    this.malos = document.getElementById('malos') as HTMLInputElement | null;
    this.calle = document.getElementById('calle') as HTMLInputElement | null;
    this.vanidad = document.getElementById('vanidad') as HTMLInputElement | null;
    this.camino = document.getElementById('camino') as HTMLInputElement | null;
    this.Dios = document.getElementById('dios') as HTMLInputElement | null;
  }

  check(cell: string) {
    document.getElementById(cell).style.background = 'red';
    this.words.push(cell);

    if(this.words.indexOf('A10') !== -1 &&
      this.words.indexOf('A11') !== -1 &&
      this.words.indexOf('A12') !== -1
    )
    {
      if (this.sol != null) {
        this.sol.checked = true;
        this.sol.disabled = true;
      }
    }

    if(this.words.indexOf('A8') !== -1 &&
      this.words.indexOf('B8') !== -1 &&
      this.words.indexOf('C8') !== -1 &&
      this.words.indexOf('D8') !== -1
    )
    {
      if (this.Dios != null) {
        this.Dios.checked = true;
        this.Dios.disabled = true;
      }
    }

    if(this.words.indexOf('A2') !== -1 &&
      this.words.indexOf('B3') !== -1 &&
      this.words.indexOf('C4') !== -1 &&
      this.words.indexOf('D5') !== -1 &&
      this.words.indexOf('E6') !== -1 &&
      this.words.indexOf('F7') !== -1
    )
    {
      if (this.hombre != null) {
        this.hombre.checked = true;
        this.hombre.disabled = true;
      }
    }

    if(this.words.indexOf('B5') !== -1 &&
      this.words.indexOf('C6') !== -1 &&
      this.words.indexOf('D7') !== -1 &&
      this.words.indexOf('E8') !== -1 &&
      this.words.indexOf('F9') !== -1 &&
      this.words.indexOf('G10') !== -1
    )
    {
      if (this.muerte != null) {
        this.muerte.checked = true;
        this.muerte.disabled = true;
      }
    }

    if(this.words.indexOf('E7') !== -1 &&
      this.words.indexOf('E8') !== -1 &&
      this.words.indexOf('E9') !== -1 &&
      this.words.indexOf('E10') !== -1 &&
      this.words.indexOf('E11') !== -1 &&
      this.words.indexOf('E12') !== -1 &&
      this.words.indexOf('E13') !== -1
    )
    {
      if (this.creador != null) {
        this.creador.checked = true;
        this.creador.disabled = true;
      }
    }

    if(this.words.indexOf('G4') !== -1 &&
      this.words.indexOf('G5') !== -1 &&
      this.words.indexOf('G6') !== -1 &&
      this.words.indexOf('G7') !== -1
    )
    {
      if (this.luna != null) {
        this.luna.checked = true;
        this.luna.disabled = true;
      }
    }

    if(this.words.indexOf('C3') !== -1 &&
      this.words.indexOf('D3') !== -1 &&
      this.words.indexOf('E3') !== -1 &&
      this.words.indexOf('F3') !== -1 &&
      this.words.indexOf('G3') !== -1 &&
      this.words.indexOf('H3') !== -1
    )
    {
      if (this.morada != null) {
        this.morada.checked = true;
        this.morada.disabled = true;
      }
    }

    if(this.words.indexOf('F2') !== -1 &&
      this.words.indexOf('G2') !== -1 &&
      this.words.indexOf('H2') !== -1 &&
      this.words.indexOf('I2') !== -1 &&
      this.words.indexOf('J2') !== -1
    )
    {
      if (this.joven != null) {
        this.joven.checked = true;
        this.joven.disabled = true;
      }
    }

    if(this.words.indexOf('H1') !== -1 &&
      this.words.indexOf('I1') !== -1 &&
      this.words.indexOf('J1') !== -1 &&
      this.words.indexOf('K1') !== -1 &&
      this.words.indexOf('L1') !== -1
    )
    {
      if (this.temor != null) {
        this.temor.checked = true;
        this.temor.disabled = true;
      }
    }

    if(this.words.indexOf('F10') !== -1 &&
      this.words.indexOf('G9') !== -1 &&
      this.words.indexOf('H8') !== -1 &&
      this.words.indexOf('I7') !== -1 &&
      this.words.indexOf('J6') !== -1
    )
    {
      if (this.calle != null) {
        this.calle.checked = true;
        this.calle.disabled = true;
      }
    }

    if(this.words.indexOf('F11') !== -1 &&
      this.words.indexOf('G11') !== -1 &&
      this.words.indexOf('H11') !== -1 &&
      this.words.indexOf('I11') !== -1 &&
      this.words.indexOf('J11') !== -1 &&
      this.words.indexOf('K11') !== -1 &&
      this.words.indexOf('L11') !== -1 &&
      this.words.indexOf('M11') !== -1 &&
      this.words.indexOf('N11') !== -1 &&
      this.words.indexOf('O11') !== -1
    )
    {
      if (this.guardianes != null) {
        this.guardianes.checked = true;
        this.guardianes.disabled = true;
      }
    }

    if(
      this.words.indexOf('J8') !== -1 &&
      this.words.indexOf('K7') !== -1 &&
      this.words.indexOf('L6') !== -1 &&
      this.words.indexOf('M5') !== -1 &&
      this.words.indexOf('N4') !== -1 &&
      this.words.indexOf('O3') !== -1
    )
    {
      if (this.camino != null) {
        this.camino.checked = true;
        this.camino.disabled = true;
      }
    }

    if(
      this.words.indexOf('L3') !== -1 &&
      this.words.indexOf('L4') !== -1 &&
      this.words.indexOf('L5') !== -1 &&
      this.words.indexOf('L6') !== -1 &&
      this.words.indexOf('L7') !== -1 &&
      this.words.indexOf('L8') !== -1 &&
      this.words.indexOf('L9') !== -1
    )
    {
      if (this.vanidad != null) {
        this.vanidad.checked = true;
        this.vanidad.disabled = true;
      }
    }

    if(
      this.words.indexOf('O4') !== -1 &&
      this.words.indexOf('O5') !== -1 &&
      this.words.indexOf('O6') !== -1 &&
      this.words.indexOf('O7') !== -1 &&
      this.words.indexOf('O8') !== -1
    )
    {
      if (this.malos != null) {
        this.malos.checked = true;
        this.malos.disabled = true;
      }
    }
  }

  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    if(
      this.sol.checked && this.sol.disabled &&
      this.luna.checked && this.luna.disabled &&
      this.hombre.checked && this.hombre.disabled &&
      this.morada.checked && this.morada.disabled &&
      this.joven.checked && this.joven.disabled &&
      this.creador.checked && this.creador.disabled &&
      this.muerte.checked && this.muerte.disabled &&
      this.guardianes.checked && this.guardianes.disabled &&
      this.temor.checked && this.temor.disabled &&
      this.malos.checked && this.malos.disabled &&
      this.calle.checked && this.calle.disabled &&
      this.vanidad.checked && this.vanidad.disabled &&
      this.camino.checked && this.camino.disabled &&
      this.Dios.checked && this.Dios.disabled
    ){
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else{
      $('#modalFail').modal('show');
      $('.ayudas').text('Encuentra todas las palabras en la sopa de letras');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
