import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  readonly avatars =[
    // {nameFile:"boyavatar1.png"},
    // {nameFile:"girlavatar2.png"},
    // {nameFile:"boyavatar3.png"},
    // {nameFile:"girlavatar4.png"},
    // {nameFile:"boyavatar5.png"},
    // {nameFile:"girlavatar6.png"}
    {nameFile: 'avatar1.png'},
    {nameFile: 'avatar2.png'},
    {nameFile: 'avatar3.png'},
    {nameFile: 'avatar4.png'},
  ]

  readonly countries = [
    { code:"COL", name:"Colombia" },
    { code:"ARG", name:"Argentina" },
    { code:"BOL", name:"Bolivia" },
    { code:"CHL", name:"Chile" },
    { code:"CRI", name:"Costa Rica" },
    { code:"CUB", name:"Cuba" },
    { code:"ECU", name:"Ecuador" },
    { code:"SLV", name:"El Salvador" },
    { code:"ESP", name:"España" },
    { code:"GTM", name:"Guatemala" },
    { code:"HND", name:"Honduras" },
    { code:"MEX", name:"Mexico" },
    { code:"NIC", name:"Nicaragua" },
    { code:"PAN", name:"Panama" },
    { code:"PRY", name:"Paraguay" },
    { code:"PER", name:"Peru" },
    { code:"PRI", name:"Puerto Rico" },
    { code:"DOM", name:"Rep. Dominican" },
    { code:"URY", name:"Uruguay" },
    { code:"VEN", name:"Venezuela" }
  ]
  constructor() {

  }
}
