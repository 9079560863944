import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Achievement } from 'src/app/models/achievement';
import { Heroe } from 'src/app/models/heroe';
import { User } from 'src/app/models/user.model';
import { AchievementService } from 'src/app/services/achievement.service';
import { ChallengeService } from 'src/app/services/challenge.service';
import { HeroeService } from 'src/app/services/heroe.service';
import { RelationChallengesService } from 'src/app/services/relation-challenges.service';
import { UserService } from 'src/app/services/user.service';
import { Location } from '@angular/common';
import { Activity } from 'src/app/models/activity';
declare var $: any;

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.css']
})

export class AchievementsComponent implements OnInit {
	// @ViewChild('ChooseHero') ChooseHero :ElementRef;
  heroe: Heroe = new Heroe();
	achievement: Achievement = new Achievement();
	user: User = new User();
	activities: Activity[] = [];
	heroActivities: string[] = [];
	loading: boolean = true;
	idHero: string = "";
	idAchievement: string = "";
  sleep:any;

  constructor(private _route: ActivatedRoute,
		public _heroeService: HeroeService,
		public _achievementService: AchievementService,
		public _userService: UserService,
		public _challengeService: ChallengeService,
		private _location: Location,
		private _relHeroeChallenge: RelationChallengesService,
		private router: Router,
		public afAuth: AngularFireAuth) {
      this.idAchievement = this._route.snapshot.paramMap.get('id');
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
      this.sleep = (milliseconds) => {
			return new Promise(resolve => setTimeout(resolve, milliseconds))
		}
     }

  ngOnInit() {

    if(this.idHero === null){
		this.idHero = '';
		$("#ChooseHero").modal('show');		
	}
	else{
		this._heroeService.getHeroe(this.idHero).subscribe(val => {
			const heroeData =  val.data() as Heroe;
			this.heroActivities = heroeData.activities;
		  });

		  this.getActivities();
	}

	let self = this;
    $("#ChooseHero").on('hidden.bs.modal', function() {
		self.getActivities();
	  });
  }

  getActivities(){
	this._achievementService.getById(this.idAchievement)
	.subscribe(val => {
		this.achievement = val.data() as Achievement;
		if(this.achievement !== undefined){
			this.activities = [];
		this.achievement.activities.forEach(element => {
			element.get().then(result => {
				if(result !== undefined){
					let act = result.data() as Activity;
					if(act.online){
						if(this.heroActivities.indexOf(act.id) !== -1){
							act.check = true;
						}
						else{
							act.check = false;
						}
						this.activities.push(act);
					}
				}
			});
			this.loading = false;
		});
	}else{
		this.loading= false;
	}
	});
  }

  backClicked() {
		this.router.navigate(['/status-heroe/' + this.idHero]);
	}

}
