import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity1',
  templateUrl: './activity1.component.html',
  styleUrls: ['./activity1.component.css']
})
export class Activity1Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '1'; // se refiere al número de actividad
  activity: Activity = new Activity();
  pos = -1;
  respuestas = [];
  intervalo = [];
  degreesToRotate;
  correctas = ['drop3:drag1', 'drop5:drag2', 'drop6:drag3', 'drop4:drag4', 'drop2:drag5' , 'drop1:drag6'];
  cont = 1;
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    self.cont = 1;
    var perc = 100;

    $(".draggable-41").draggable({ revert: "invalid" });


    $('.droppable-73').droppable({
      accept: '#drag10',
      hoverClass: 'active',
      drop: function (e, ui) {
        var temp = $(this).attr('id1');
        $(ui.draggable).draggable("disable");
        //$(this).droppable("disable");

        var temp2 = ui.draggable.attr('id1');

        if ((ui.draggable).attr("id1") == "drag1") {
          self.pos = 0
        } else if ((ui.draggable).attr("id1") == "drag2") {
          self.pos = 1
        } else if ((ui.draggable).attr("id1") == "drag3") {
          self.pos = 2
        } else if ((ui.draggable).attr("id1") == "drag4") {
          self.pos = 3
        } else if ((ui.draggable).attr("id1") == "drag5") {
          self.pos = 4
        } else if ((ui.draggable).attr("id1") == "drag6") {
          self.pos = 5
        }

        self.respuestas[self.pos] = temp + ":" + (ui.draggable).attr("id1")
        self.intervalo[self.pos] = setInterval(function () {
          var newDate = new Date();
          var s = newDate.getSeconds();
          var m = newDate.getMinutes();
          var h = newDate.getHours();
          self.degreesToRotate = s * 50;

          s = modifyTime(s);
          m = modifyTime(m);
          function modifyTime(t) {
            if (t < 10) {
              t = "0" + t;
            };
            return t;
          }

          $(ui.draggable).css(
            {
              "transform": 'rotate(' + self.degreesToRotate + 'deg)', "transition": "all 10s", "transition-timing": "ease-in-out"
            });
        }, 100);
        console.log(self.intervalo);
      }
    });

  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    console.log(this.respuestas);
    var iguales = 0;
    for (var j = 0; j < 6; j++) {
      for (var i = 0; i < 6; i++) {
        if (this.correctas[j] == this.respuestas[i]) {
          iguales++
        }
      }
    }
    if (iguales < 6) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica que las imagenes esten organizadas en el orden establecido por el Texto ' + iguales + '/6');
    }

    if ((iguales == 6)) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    for (i = 0; i < 6; i++) {
      clearInterval(this.intervalo[i]);
    }
    this.respuestas = [];
    //$('.draggable-41').draggable("disable");
    $('.draggable-41').css({ 'left': "", 'top': "", "transition": "none", "transition-timing": "none", "rotate": "none" });
    this.degreesToRotate = 0;
    $('.draggable-41').draggable("enable");

    $('.droppable-73').droppable("enable");
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
