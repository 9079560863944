import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity25',
  templateUrl: './activity25.component.html',
  styleUrls: ['./activity25.component.css']
})
export class Activity25Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '25'; // se refiere al número de actividad
  activity: Activity = new Activity();
  eventsClick = 0;
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    var BLockSelect = '';
    var arrayGuardar = [];
    $('.block-DavGol').click(function () {
      if (self.eventsClick < 20) {
        BLockSelect = $(this).attr('text');
        self.eventsClick++;
        for (var i = (self.eventsClick - 1); i < self.eventsClick; i++) {
          arrayGuardar[i] = BLockSelect;
        }

        var myArray = ['EL ', 'SE', 'ÑOR ', 'TE ', 'EN', 'TRE', 'GA', 'RA ', 'EN ', 'MIS ', 'MA', 'NOS, ', 'LA ', 'VIC', 'TO', 'RIA ', 'ES ', 'DEL ', 'SE', 'ÑOR'];

        for (var j = (self.eventsClick - 1); j < self.eventsClick; j++) {
          console.log(self.eventsClick);

          if (arrayGuardar[j] == myArray[j]) {
            $(this).css("background-color", "#4caf50");
          } else {
            $(this).css("background-color", "red");
          }
        }
        $('.text-DavGol').text(arrayGuardar.join(''));

      }
    });
    $('#limpiar').click(function () {
      self.eventsClick = 0;
      BLockSelect = '';
      $('.block-DavGol').css("background-color", "#0000007a");
      arrayGuardar = [];
      $('.text-DavGol').text('');
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    if (this.eventsClick == 20) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    } else {
      $('#modalFail').modal('show');
      $('.ayudas').text('Organiza bien las Palabras.');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
