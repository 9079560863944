import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { UserService } from "src/app/services/user.service";
import * as firebase from "firebase/app";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form:FormGroup;
  displayErros = "none";
  invalidUser = false;

  constructor(public afAuth: AngularFireAuth,
    private router: Router,
    public _userService: UserService
    ) {
      this.form = new FormGroup({
        'email':new FormControl('',[Validators.required, Validators.email]),
        'password':new FormControl('',Validators.required)
      });
  }

  ngOnInit() {
    document.body.classList.add('Login-Inicio')
  }

  login(provider:string = 'google') {

    let authProvider = new auth.GoogleAuthProvider();

    if(provider=="facebook"){
      authProvider = new auth.FacebookAuthProvider();
    }

    this.afAuth.auth.signInWithPopup(authProvider)
    .then( val => {
      this._userService.getUser(val.user.uid).subscribe(val =>{
        if(val.exists){
          this.router.navigate(['/dashboard']);
        }else{
          this.router.navigate(['/register']);
        }
      });
    })
    .catch(errors => {
      console.log(errors);
      
    });
  }

  loginEmail(){

    if(this.form.invalid ){
      this.displayErros="initial";
      return ;
    }

    firebase.auth().signInWithEmailAndPassword(this.form.value['email'], this.form.value['password'])
    .catch(function(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      this.invalidUser=true;
      console.log(error);
    }.bind(this))
    .then(val => {
      this.router.navigate(['/dashboard']);
    });

  }
}
