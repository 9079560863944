import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
import Konva from 'konva';
declare var $: any;

@Component({
  selector: 'app-activity74',
  templateUrl: './activity74.component.html',
  styleUrls: ['./activity74.component.css']
})
export class Activity74Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '74'; // se refiere al número de actividad
  activity: Activity = new Activity();
  totalColors : any = [];
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    var width = window.innerWidth;
    var height = window.innerHeight;

    if (width <= 500) {
      width = 340;
      height = 485;
    }else if(width >= 500 && width <= 1000) {
      width = 405;
      height = 663;
    }else{
      width = 605;
      height = 863;
    }

    var self = this;

    var stage = new Konva.Stage({
      container: 'canvas',
      width: width,
      height: height
    });

    var layer = new Konva.Layer();
    stage.add(layer);

    var background = new Konva.Rect({
      fill: '#FFF',
      name: 'background',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(background);

    var almohadaTrazoImg = new Image();
    almohadaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/TRAZO/Almohada.png';

    var almohadaTrazo = new Konva.Image({
      image: almohadaTrazoImg,
      name: 'almohadaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(almohadaTrazo);

    var camaTrazoImg = new Image();
    camaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/TRAZO/Cama.png';

    var camaTrazo = new Konva.Image({
      image: camaTrazoImg,
      name: 'camaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(camaTrazo);

    var cobija1TrazoImg = new Image();
    cobija1TrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/TRAZO/Cobija_1.png';

    var cobija1Trazo = new Konva.Image({
      image: cobija1TrazoImg,
      name: 'cobija1Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cobija1Trazo);

    var cobija2TrazoImg = new Image();
    cobija2TrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/TRAZO/Cobija_2.png';

    var cobija2Trazo = new Konva.Image({
      image: cobija2TrazoImg,
      name: 'cobija2Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cobija2Trazo);

    var colchonTrazoImg = new Image();
    colchonTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/TRAZO/Colchon.png';

    var colchonTrazo = new Konva.Image({
      image: colchonTrazoImg,
      name: 'colchonTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(colchonTrazo);

    var mesaTrazoImg = new Image();
    mesaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/MESA/TRAZO/Mesa.png';

    var mesaTrazo = new Konva.Image({
      image: mesaTrazoImg,
      name: 'mesaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(mesaTrazo);

    var tazaTrazoImg = new Image();
    tazaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/MESA/TRAZO/Taza.png';

    var tazaTrazo = new Konva.Image({
      image: tazaTrazoImg,
      name: 'tazaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(tazaTrazo);

    var cabelloTrazoImg = new Image();
    cabelloTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/TRAZO/Cabello.png';

    var cabelloTrazo = new Konva.Image({
      image: cabelloTrazoImg,
      name: 'cabelloTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cabelloTrazo);

    var camisaTrazoImg = new Image();
    camisaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/TRAZO/Camisa.png';

    var camisaTrazo = new Konva.Image({
      image: camisaTrazoImg,
      name: 'camisaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(camisaTrazo);

    var caraTrazoImg = new Image();
    caraTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/TRAZO/Cara.png';

    var caraTrazo = new Konva.Image({
      image: caraTrazoImg,
      name: 'caraTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(caraTrazo);

    var pielTrazoImg = new Image();
    pielTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/TRAZO/Piel.png';

    var pielTrazo = new Konva.Image({
      image: pielTrazoImg,
      name: 'pielTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(pielTrazo);

    var colors = new Konva.Stage({
      container: 'colors',
      width: window.innerWidth >= 1000 ? 800 : 350,
      height: window.innerWidth <= 500 ? 150 : 220,
    });

    var layerColors = new Konva.Layer();

    var backgroundColors = new Konva.Rect({
      fill: '#FFF',
      name: 'colors',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layerColors.add(backgroundColors);

    var orange = new Konva.Circle({
      x: stage.width() / 8,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#F18A5F',
      stroke: 'black',
      strokeWidth: 4,
      name: 'orange'
    });

    orange.on('click', function (evt) {
      cabelloTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/CON_TRAZO/COLOR/Cabello.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    orange.on('touchend', function (evt) {
      cabelloTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/CON_TRAZO/COLOR/Cabello.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(orange);

    var orange2 = new Konva.Circle({
      x: stage.width() / 8 * 2,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#FBCDAC',
      stroke: 'black',
      strokeWidth: 4,
      name: 'orange2'
    });

    orange2.on('click', function (evt) {
      pielTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/CON_TRAZO/COLOR/Piel.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    orange2.on('touchend', function (evt) {
      pielTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/CON_TRAZO/COLOR/Piel.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(orange2);

    var green = new Konva.Circle({
      x: stage.width() / 8 * 3,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#64853E',
      stroke: 'black',
      strokeWidth: 4,
      name: 'green'
    });

    green.on('click', function (evt) {
      caraTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/CON_TRAZO/COLOR/Ojos.png';
      cobija2TrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/COLOR/Cobija2.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    green.on('touchend', function (evt) {
      caraTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/CON_TRAZO/COLOR/Ojos.png';
      cobija2TrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/COLOR/Cobija2.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(green);

    var yellow = new Konva.Circle({
      x: stage.width() / 8 * 4,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#FCEC44',
      stroke: 'black',
      strokeWidth: 4,
      name: 'yellow'
    });

    yellow.on('click', function (evt) {
      camisaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/CON_TRAZO/COLOR/Camisa.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    yellow.on('touchend', function (evt) {
      camisaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/CON_TRAZO/COLOR/Camisa.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(yellow);

    var blue = new Konva.Circle({
      x: stage.width() / 8 * 5,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#BCE2EE',
      stroke: 'black',
      strokeWidth: 4,
      name: 'blue'
    });

    blue.on('click', function (evt) {
      almohadaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/COLOR/Almohada.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    blue.on('touchend', function (evt) {
      almohadaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/COLOR/Almohada.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(blue);

    var blue2 = new Konva.Circle({
      x: stage.width() / 8 * 6,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#7E9FA4',
      stroke: 'black',
      strokeWidth: 4,
      name: 'blue2'
    });

    blue2.on('click', function (evt) {
      colchonTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/COLOR/Colchon.png';
      tazaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/MESA/CON_TRAZO/COLOR/Taza.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    blue2.on('touchend', function (evt) {
      colchonTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/COLOR/Colchon.png';
      tazaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/MESA/CON_TRAZO/COLOR/Taza.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(blue2);

    var green2 = new Konva.Circle({
      x: stage.width() / 8 * 7,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#9CC764',
      stroke: 'black',
      strokeWidth: 4,
      name: 'green2'
    });

    green2.on('click', function (evt) {
      cobija1TrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/COLOR/Cobija1.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    green2.on('touchend', function (evt) {
      cobija1TrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/COLOR/Cobija1.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(green2);

    var brown = new Konva.Circle({
      x: stage.width() / 8,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: '#572C1B',
      stroke: 'black',
      strokeWidth: 4,
      name: 'brown'
    });

    brown.on('click', function (evt) {
      mesaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/MESA/CON_TRAZO/COLOR/Mesa.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    brown.on('touchend', function (evt) {
      mesaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/MESA/CON_TRAZO/COLOR/Mesa.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(brown);

    var brown2 = new Konva.Circle({
      x: stage.width() / 8 * 2,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: '#B18662',
      stroke: 'black',
      strokeWidth: 4,
      name: 'brown2'
    });

    brown2.on('click', function (evt) {
      camaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/COLOR/Cama.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    brown2.on('touchend', function (evt) {
      camaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/COLOR/Cama.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(brown2);

    var white = new Konva.Circle({
      x: stage.width() / 8 * 3,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: 'white',
      stroke: 'black',
      strokeWidth: 4,
      name : 'white'
    });

    white.on('click', function (evt) {
      cabelloTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/CON_TRAZO/BLANCO/Cabello.png';
      pielTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/CON_TRAZO/BLANCO/Piel.png';
      caraTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/CON_TRAZO/BLANCO/Ojos.png';
      camisaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/CON_TRAZO/BLANCO/Camisa.png';
      almohadaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/BLANCO/Almohada.png';
      colchonTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/BLANCO/Colchon.png';
      cobija1TrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/BLANCO/Cobija1.png';
      tazaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/MESA/CON_TRAZO/BLANCO/Taza.png';
      cobija2TrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/BLANCO/Cobija2.png';
      mesaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/MESA/CON_TRAZO/BLANCO/Mesa.png';
      camaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/BLANCO/Cama.png';

      self.totalColors = [];
    });

    white.on('touchend', function (evt) {
      cabelloTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/CON_TRAZO/BLANCO/Cabello.png';
      pielTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/CON_TRAZO/BLANCO/Piel.png';
      caraTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/CON_TRAZO/BLANCO/Ojos.png';
      camisaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/PERSONAJE/CON_TRAZO/BLANCO/Camisa.png';
      almohadaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/BLANCO/Almohada.png';
      colchonTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/BLANCO/Colchon.png';
      cobija1TrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/BLANCO/Cobija1.png';
      tazaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/MESA/CON_TRAZO/BLANCO/Taza.png';
      cobija2TrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/BLANCO/Cobija2.png';
      mesaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/MESA/CON_TRAZO/BLANCO/Mesa.png';
      camaTrazoImg.src = './assets/img/actividades/74NuestSanador3/Partes/CAMA/CON_TRAZO/BLANCO/Cama.png';

      self.totalColors = [];
    });

    layerColors.add(white);

    colors.add(layerColors);
    
  }

  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    console.log(this.totalColors);
    if(this.totalColors.length === 0){
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad coloreando toda la imagen');
    }
    else if(
      this.totalColors.indexOf('orange') !== -1 &&
      this.totalColors.indexOf('orange2') !== -1 &&
      this.totalColors.indexOf('blue') !== -1 &&
      this.totalColors.indexOf('yellow') !== -1 &&
      this.totalColors.indexOf('green') !== -1 &&
      this.totalColors.indexOf('brown') !== -1 &&
      this.totalColors.indexOf('brown2') !== -1 &&
      this.totalColors.indexOf('blue2') !== -1 &&
      this.totalColors.indexOf('green2') !== -1
    ){
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else{
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad coloreando toda la imagen');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
