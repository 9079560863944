import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
import Konva from 'konva';
declare var $: any;

@Component({
  selector: 'app-activity38',
  templateUrl: './activity38.component.html',
  styleUrls: ['./activity38.component.css']
})
export class Activity38Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '38'; // se refiere al número de actividad
  activity: Activity = new Activity();
  happyFatherArray: any = [];
  sadMothertArray: any = [];
  arreglo1 = ['0', '0', '0', '0'];
  arreglo2 = ['0', '0', '0', '0'];

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();
    var self = this;
    $('.draggable-4').draggable({ revert: "invalid" });

    $('.droppable-72').droppable({
      accept: '.draggable-4',
      hoverClass: 'active',
      drop: function (e, ui) {
        
        var temp = $(this).attr('id');
        $(ui.draggable).draggable("disable");
        $(this).effect("highlight", { color: "#17a2b8" }, 2000,
          function () { $(this).css("border-radius", "90px") }
        );

        if (temp === "drop1") {
          var temp1 = $(ui.draggable).attr("id");

          self.arreglo1[0] = temp1;

        } else if (temp === "drop2") {
          var temp1 = $(ui.draggable).attr("id");

          self.arreglo2[0] = temp1;

        } else if (temp === "drop3") {
          var temp1 = $(ui.draggable).attr("id");
          self.arreglo1[1] = temp1;

        } else if (temp === "drop4") {
          var temp1 = $(ui.draggable).attr("id");

          self.arreglo2[1] = temp1;

        } else if (temp === "drop5") {
          var temp1 = $(ui.draggable).attr("id");

          self.arreglo1[2] = temp1;
        } else if (temp === "drop6") {
          var temp1 = $(ui.draggable).attr("id");

          self.arreglo2[2] = temp1;
        } else if (temp === "drop7") {
          var temp1 = $(ui.draggable).attr("id");

          self.arreglo1[3] = temp1;
        } else if (temp === "drop8") {
          var temp1 = $(ui.draggable).attr("id");
          self.arreglo2[3] = temp1;
        }
      }
    });
    /*
    var width = 400;
    var height = 650;

    if(window.innerWidth >= 850) {
      width = 600;
      height = 800;
    }

    var stage = new Konva.Stage({
      container: 'container',
      width: width,
      height: height,
    });

    var layer = new Konva.Layer();
    stage.add(layer);

    var self = this;

    var pathsImg = new Image();
    pathsImg.src = './assets/img/actividades/38AlegriaPadres3/Camino.png';

    var paths = new Konva.Image({
      image: pathsImg,
      name: 'paths',
      x: 0,
      y: 260,
      width: 400,
      height: 280,
      draggable: false,
    });
    if(window.innerWidth >= 850) {
      paths = new Konva.Image({
        image: pathsImg,
        name: 'paths',
        x: 0,
        y: 260,
        width: 600,
        height: 480,
        draggable: false,
      });
    }
    layer.add(paths);

    var tableImg = new Image();
    tableImg.src = './assets/img/actividades/38AlegriaPadres3/mesa.png';

    var table = new Konva.Image({
      image: tableImg,
      name: 'table',
      x: 0,
      y: 0,
      width: 400,
      height: 280,
      draggable: false,
    });
    layer.add(table);

    var malgasta = new Konva.Group({
      name: 'malgasta',
      draggable: true,
    });

    var malgastaText = new Konva.Text({
      x: 0,
      y: 0,
      text: 'Malgasta o no valora lo que dan',
      fontSize: 10,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var malgastaRect = new Konva.Rect({
      x: 0,
      y: 0,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: malgastaText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    malgasta.add(malgastaRect);
    malgasta.add(malgastaText);

    layer.add(malgasta);

    var burla = new Konva.Group({
      name: 'burla',
      draggable: true,
    });

    var burlaText = new Konva.Text({
      x: 130,
      y: 0,
      text: 'Se burla del consejo de sus padres',
      fontSize: 10,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var burlaRect = new Konva.Rect({
      x: 130,
      y: 0,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: burlaText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    burla.add(burlaRect);
    burla.add(burlaText);

    layer.add(burla);

    var obedece = new Konva.Group({
      name: 'obedece',
      draggable: true,
    });

    var obedeceText = new Konva.Text({
      x: 260,
      y: 0,
      text: 'Obedece sin colocar excusas',
      fontSize: 10,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var obedeceRect = new Konva.Rect({
      x: 260,
      y: 0,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: obedeceText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    obedece.add(obedeceRect);
    obedece.add(obedeceText);

    layer.add(obedece);

    var prioridades = new Konva.Group({
      name: 'prioridades',
      draggable: true,
    });

    var prioridadesText = new Konva.Text({
      x: 0,
      y: 50,
      text: 'Dios no está en sus prioridades',
      fontSize: 10,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var prioridadesRect = new Konva.Rect({
      x: 0,
      y: 50,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: prioridadesText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    prioridades.add(prioridadesRect);
    prioridades.add(prioridadesText);

    layer.add(prioridades);

    var disciplina = new Konva.Group({
      name: 'disciplina',
      draggable: true,
    });

    var disciplinaText = new Konva.Text({
      x: 130,
      y: 50,
      text: 'No acepta la disciplina',
      fontSize: 10,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var disciplinaRect = new Konva.Rect({
      x: 130,
      y: 50,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: disciplinaText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    disciplina.add(disciplinaRect);
    disciplina.add(disciplinaText);

    layer.add(disciplina);

    var busca = new Konva.Group({
      name: 'busca',
      draggable: true,
    });

    var buscaText = new Konva.Text({
      x: 260,
      y: 50,
      text: 'Busca a Dios y estudia Su Palabra',
      fontSize: 10,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var buscaRect = new Konva.Rect({
      x: 260,
      y: 50,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: buscaText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    busca.add(buscaRect);
    busca.add(buscaText);

    layer.add(busca);

    var acepta = new Konva.Group({
      name: 'acepta',
      draggable: true,
    });

    var aceptaText = new Konva.Text({
      x: 0,
      y: 100,
      text: 'Acepta el llamado de atención',
      fontSize: 10,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var aceptaRect = new Konva.Rect({
      x: 0,
      y: 100,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: aceptaText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    acepta.add(aceptaRect);
    acepta.add(aceptaText);

    layer.add(acepta);

    var escucha = new Konva.Group({
      name: 'escucha',
      draggable: true,
    });

    var escuchaText = new Konva.Text({
      x: 130,
      y: 100,
      text: 'Escucha a sus padres',
      fontSize: 10,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var escuchaRect = new Konva.Rect({
      x: 130,
      y: 100,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: escuchaText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    escucha.add(escuchaRect);
    escucha.add(escuchaText);

    layer.add(escucha);

    layer.on('dragend', function(evt) {
      var shape = evt.target;
      document.body.style.cursor = 'pointer';

      self.happyFatherArray = self.happyFatherArray.filter((word) => word !== shape.attrs.name);
      self.sadMothertArray = self.sadMothertArray.filter((word) => word !== shape.attrs.name);

      console.log('x:', shape.attrs.x);
      console.log('y:', shape.attrs.y);

      if(shape.attrs.name === 'malgasta' || shape.attrs.name === 'prioridades' || shape.attrs.name === 'acepta'){
        if(shape.attrs.y >= 200){
          if(shape.attrs.x >= 10 && shape.attrs.x < 200){
            if(self.happyFatherArray.indexOf(shape.attrs.name) === -1){
              self.happyFatherArray.push(shape.attrs.name);
            }
          }
          else if(shape.attrs.x >= 230){
            if(self.sadMothertArray.indexOf(shape.attrs.name) === -1){
              self.sadMothertArray.push(shape.attrs.name);
            }
          }
        }
      }
      else if(shape.attrs.name === 'burla' || shape.attrs.name === 'disciplina' || shape.attrs.name === 'escucha'){
        if(shape.attrs.y >= 200){
          if(shape.attrs.x >= -100 && shape.attrs.x < 90){
            if(self.happyFatherArray.indexOf(shape.attrs.name) === -1){
              self.happyFatherArray.push(shape.attrs.name);
            }
          }
          else if(shape.attrs.x >= 90){
            if(self.sadMothertArray.indexOf(shape.attrs.name) === -1){
              self.sadMothertArray.push(shape.attrs.name);
            }
          }
        }
      }
      else if(shape.attrs.name === 'obedece' || shape.attrs.name === 'busca'){
        if(shape.attrs.y >= 200){
          if(shape.attrs.x >= -240 && shape.attrs.x < -50){
            if(self.happyFatherArray.indexOf(shape.attrs.name) === -1){
              self.happyFatherArray.push(shape.attrs.name);
            }
          }
          else if(shape.attrs.x >= -50){
            if(self.sadMothertArray.indexOf(shape.attrs.name) === -1){
              self.sadMothertArray.push(shape.attrs.name);
            }
          }
        }
      }
    });
    */
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    /*console.log('happy', this.happyFatherArray);
    console.log('sad', this.sadMothertArray);
    if (this.happyFatherArray.length === 0 && this.sadMothertArray.length === 0) {
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad arrastrando las frases a los caminos correspondientes');
    }
    else if (
      this.happyFatherArray.indexOf('obedece') !== -1 &&
      this.happyFatherArray.indexOf('busca') !== -1 &&
      this.happyFatherArray.indexOf('acepta') !== -1 &&
      this.happyFatherArray.indexOf('escucha') !== -1 &&
      this.sadMothertArray.indexOf('malgasta') !== -1 &&
      this.sadMothertArray.indexOf('burla') !== -1 &&
      this.sadMothertArray.indexOf('prioridades') !== -1 &&
      this.sadMothertArray.indexOf('disciplina') !== -1
    ) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else {
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad dejando las frases correspondientes en cada camino');
    }*/
    var correctos1=['drag6', 'drag3', 'drag8', 'drag7'];
	  var correctos2=['drag4', 'drag5', 'drag2', 'drag1'];

    console.log(this.arreglo1);
    console.log(this.arreglo2);

    var iguales = 0;
    var iguales1 = 0;

    for (var i = 0; i < 4; i++) {
      for (var j = 0; j < 4; j++) {

        if (this.arreglo1[i] == correctos1[j]) {
          iguales++;
        }
      }
    }

    for (var i = 0; i < 4; i++) {
      for (var j = 0; j < 4; j++) {
        if (this.arreglo2[i] == correctos2[j]) {
          iguales1++;
        }
      }
    }
    console.log(iguales);
    console.log(iguales1)


    if (iguales < 4) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica bien las acciones que te llevan a la imagen de un padre alegre ' + iguales + '/4');
      $('.draggable-4').animate({'left':"0px",'top': "0px"});
      $('.draggable-4').draggable("enable");
    } else if (iguales1 < 4) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica bien las acciones que te llevan a la imagen de una madre triste ' + iguales1 + '/4');
      $('.draggable-4').animate({'left':"0px",'top': "0px"});
      $('.draggable-4').draggable("enable");
    }

    if ((iguales == 4 && iguales1 == 4)) {
      $('#modalOk').modal('show');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
