import { Injectable } from '@angular/core';
import { Challenge } from 'src/app/models/challenge';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChallengeService {

  private collection: AngularFirestoreCollection<Challenge>;
  private document: AngularFirestoreDocument<Challenge>;
  queryResult: Observable<Challenge>;

  constructor(private afs: AngularFirestore) { 
    this.collection = afs.collection<Challenge>('challenges');
  }
  
  getAllByAchievementId(idAchievement:string){
    return this.afs.collection('challenges', ref => ref.where('id_piece', '==', idAchievement).orderBy('order')).valueChanges();
  }

  getById(id:string){
    this.document = this.afs.doc<Challenge>('challenges/'+id);
    this.queryResult = this.document.valueChanges();
    return this.queryResult
  }

}
