import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
import Konva from 'konva';
declare var $: any;

@Component({
  selector: 'app-activity63',
  templateUrl: './activity63.component.html',
  styleUrls: ['./activity63.component.css']
})
export class Activity63Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '63'; // se refiere al número de actividad
  activity: Activity = new Activity();
  candles: any = [];
  Correctas = ['luz', 'mundo', 'monte', 'esconder'];
  afirmaciones = [];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var width = 350;
    var height = 1400;

    var self = this;

    var stage = new Konva.Stage({
      container: 'canvas',
      width: width,
      height: height,
    });

    var layer = new Konva.Layer();
    stage.add(layer);

    var shareImg = new Image();
    shareImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';

    var share = new Konva.Image({
      image: shareImg,
      name: 'share',
      x: 0,
      y: 150,
      width: 170,
      height: 300,
      draggable: false,
    });
    layer.add(share);

    var compartir = new Konva.Group({
      name: 'compartir',
      draggable: false,
    });

    var compartirText = new Konva.Text({
      x: 25,
      y: 300,
      text: 'Compartir',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var compartirRect = new Konva.Rect({
      x: 25,
      y: 300,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: compartirText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    compartir.add(compartirRect);
    compartir.add(compartirText);

    layer.add(compartir);

    var lieImg = new Image();
    lieImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';

    var lie = new Konva.Image({
      image: lieImg,
      name: 'lie',
      x: 170,
      y: 150,
      width: 170,
      height: 300,
      draggable: false,
    });
    layer.add(lie);

    var mentir = new Konva.Group({
      name: 'mentir',
      draggable: false,
    });

    var mentirText = new Konva.Text({
      x: 195,
      y: 300,
      text: 'Mentir',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var mentirRect = new Konva.Rect({
      x: 195,
      y: 300,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: mentirText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    mentir.add(mentirRect);
    mentir.add(mentirText);

    layer.add(mentir);

    var spoilImg = new Image();
    spoilImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';

    var spoil = new Konva.Image({
      image: spoilImg,
      name: 'spoil',
      x: 0,
      y: 380,
      width: 170,
      height: 300,
      draggable: false,
    });
    layer.add(spoil);

    var estropear = new Konva.Group({
      name: 'estropear',
      draggable: false,
    });

    var estropearText = new Konva.Text({
      x: 25,
      y: 520,
      text: 'Estropear',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var estropearRect = new Konva.Rect({
      x: 25,
      y: 520,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: estropearText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    estropear.add(estropearRect);
    estropear.add(estropearText);

    layer.add(estropear);

    var envyImg = new Image();
    envyImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';

    var envy = new Konva.Image({
      image: envyImg,
      name: 'envy',
      x: 170,
      y: 380,
      width: 170,
      height: 300,
      draggable: false,
    });
    layer.add(envy);

    var envidiar = new Konva.Group({
      name: 'envidiar',
      draggable: false,
    });

    var envidiarText = new Konva.Text({
      x: 195,
      y: 520,
      text: 'Envidiar',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var envidiarRect = new Konva.Rect({
      x: 195,
      y: 520,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: envidiarText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    envidiar.add(envidiarRect);
    envidiar.add(envidiarText);

    layer.add(envidiar);

    var studyImg = new Image();
    studyImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';

    var study = new Konva.Image({
      image: studyImg,
      name: 'study',
      x: 0,
      y: 620,
      width: 170,
      height: 300,
      draggable: false,
    });
    layer.add(study);

    var estudiar = new Konva.Group({
      name: 'estudiar',
      draggable: false,
    });

    var estudiarText = new Konva.Text({
      x: 25,
      y: 750,
      text: 'Estudiar',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var estudiarRect = new Konva.Rect({
      x: 25,
      y: 750,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: estudiarText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    estudiar.add(estudiarRect);
    estudiar.add(estudiarText);

    layer.add(estudiar);

    var obeyImg = new Image();
    obeyImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';

    var obey = new Konva.Image({
      image: obeyImg,
      name: 'obey',
      x: 170,
      y: 620,
      width: 170,
      height: 300,
      draggable: false,
    });
    layer.add(obey);

    var obedecer = new Konva.Group({
      name: 'obedecer',
      draggable: false,
    });

    var obedecerText = new Konva.Text({
      x: 195,
      y: 750,
      text: 'Obedecer',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var obedecerRect = new Konva.Rect({
      x: 195,
      y: 750,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: obedecerText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    obedecer.add(obedecerRect);
    obedecer.add(obedecerText);

    layer.add(obedecer);

    var preyImg = new Image();
    preyImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';

    var prey = new Konva.Image({
      image: preyImg,
      name: 'prey',
      x: 0,
      y: 850,
      width: 170,
      height: 300,
      draggable: false,
    });
    layer.add(prey);

    var orar = new Konva.Group({
      name: 'orar',
      draggable: false,
    });

    var orarText = new Konva.Text({
      x: 25,
      y: 980,
      text: 'Orar por otros',
      fontSize: 15,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var orarRect = new Konva.Rect({
      x: 25,
      y: 980,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: orarText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    orar.add(orarRect);
    orar.add(orarText);

    layer.add(orar);

    var helpImg = new Image();
    helpImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';

    var help = new Konva.Image({
      image: helpImg,
      name: 'help',
      x: 170,
      y: 850,
      width: 170,
      height: 300,
      draggable: false,
    });
    layer.add(help);

    var ayudar = new Konva.Group({
      name: 'ayudar',
      draggable: false,
    });

    var ayudarText = new Konva.Text({
      x: 195,
      y: 980,
      text: 'Ayudar',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var ayudarRect = new Konva.Rect({
      x: 195,
      y: 980,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: ayudarText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    ayudar.add(ayudarRect);
    ayudar.add(ayudarText);

    layer.add(ayudar);

    var hateImg = new Image();
    hateImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';

    var hate = new Konva.Image({
      image: hateImg,
      name: 'hate',
      x: 0,
      y: 1080,
      width: 170,
      height: 300,
      draggable: false,
    });
    layer.add(hate);

    var odiar = new Konva.Group({
      name: 'odiar',
      draggable: false,
    });

    var odiarText = new Konva.Text({
      x: 25,
      y: 1200,
      text: 'Odiar',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var odiarRect = new Konva.Rect({
      x: 25,
      y: 1200,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: odiarText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    odiar.add(odiarRect);
    odiar.add(odiarText);

    layer.add(odiar);

    var wastingTimeImg = new Image();
    wastingTimeImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';

    var wastingTime = new Konva.Image({
      image: wastingTimeImg,
      name: 'wastingTime',
      x: 170,
      y: 1080,
      width: 170,
      height: 300,
      draggable: false,
    });
    layer.add(wastingTime);

    var perderTiempo = new Konva.Group({
      name: 'perderTiempo',
      draggable: false,
    });

    var perderTiempoText = new Konva.Text({
      x: 195,
      y: 1200,
      text: 'Perder el tiempo',
      fontSize: 15,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 120,
      height: 50,
      padding: 10,
      align: 'center',
    });

    var perderTiempoRect = new Konva.Rect({
      x: 195,
      y: 1200,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#00000070',
      width: 120,
      height: perderTiempoText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    perderTiempo.add(perderTiempoRect);
    perderTiempo.add(perderTiempoText);

    layer.add(perderTiempo);

    var handImg = new Image();
    handImg.src = './assets/img/actividades/63LaLuzMundo2/Mano.png';

    var hand = new Konva.Image({
      image: handImg,
      name: 'hand',
      x: 100,
      y: 20,
      width: 170,
      height: 160,
      draggable: true,
    });
    layer.add(hand);

    layer.on('dragend', function(evt) {
      var shape = evt.target;
      document.body.style.cursor = 'pointer';

      self.candles = self.candles.filter((word) => word !== shape.attrs.name);

      console.log(evt);
      console.log(shape);

      if(shape.attrs.y >= 85 && shape.attrs.y <= 150 ){
        if(shape.attrs.x >=-0 && shape.attrs.x < 130){
          shareImg.src = './assets/img/actividades/63LaLuzMundo2/Vela.png';
          self.candles.push('compartir');
        }
        else if(shape.attrs.x >= 230 && shape.attrs.x < 330){
          lieImg.src = './assets/img/actividades/63LaLuzMundo2/Vela.png';
          self.candles.push('mentir');
        }
      }
      else if(shape.attrs.y >= 290 && shape.attrs.y <= 380 ){
        if(shape.attrs.x >= 0 && shape.attrs.x < 130){
          spoilImg.src = './assets/img/actividades/63LaLuzMundo2/Vela.png';
          self.candles.push('estropear');
        }
        else if(shape.attrs.x >= 230 && shape.attrs.x < 330){
          envyImg.src = './assets/img/actividades/63LaLuzMundo2/Vela.png';
          self.candles.push('envidiar');
        }
      }
      else if(shape.attrs.y >= 525 && shape.attrs.y <= 630 ){
        if(shape.attrs.x >= 0 && shape.attrs.x < 130){
          studyImg.src = './assets/img/actividades/63LaLuzMundo2/Vela.png';
          self.candles.push('estudiar');
        }
        else if(shape.attrs.x >= 230 && shape.attrs.x < 330){
          obeyImg.src = './assets/img/actividades/63LaLuzMundo2/Vela.png';
          self.candles.push('obedecer');
        }
      }
      else if(shape.attrs.y >= 765 && shape.attrs.y <= 845 ){
        if(shape.attrs.x >= 0 && shape.attrs.x < 130){
          preyImg.src = './assets/img/actividades/63LaLuzMundo2/Vela.png';
          self.candles.push('orar');
        }
        else if(shape.attrs.x >= 230 && shape.attrs.x < 330){
          helpImg.src = './assets/img/actividades/63LaLuzMundo2/Vela.png';
          self.candles.push('ayudar');
        }
      }
      else if(shape.attrs.y >= 1000 && shape.attrs.y <= 1080 ){
        if(shape.attrs.x >= 0 && shape.attrs.x < 130){
          hateImg.src = './assets/img/actividades/63LaLuzMundo2/Vela.png';
          self.candles.push('odiar');
        }
        else if(shape.attrs.x >= 230 && shape.attrs.x < 330){
          wastingTimeImg.src = './assets/img/actividades/63LaLuzMundo2/Vela.png';
          self.candles.push('perder');
        }
      }
    });

    $('#apagarVelas').click(function(){
      console.log('apagar velas');
      self.candles = [];
      shareImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';
      lieImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';
      spoilImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';
      envyImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';
      studyImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';
      obeyImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';
      preyImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';
      helpImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';
      hateImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';
      wastingTimeImg.src = './assets/img/actividades/63LaLuzMundo2/Vela-apagada.png';
    });
    $(".text-luzmundo").on("keyup ", function () {
      $('.text-luzmundo').each(function (i) {
        var lstfrase = $(this).val().toLowerCase();
        self.afirmaciones[i] = lstfrase;
        if (self.afirmaciones[i] == self.Correctas[i]) {
          $(this).css("border-bottom", "3px solid #4caf50");
        } else {
          $(this).css("border-bottom", "");
        }
      });
      console.log(self.afirmaciones);
    });
  }

  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    let self = this;

    console.log(self.candles);

    if(this.candles.indexOf('compartir') !== -1 &&
    this.candles.indexOf('estudiar') !== -1 &&
    this.candles.indexOf('obedecer') !== -1 &&
    this.candles.indexOf('orar') !== -1 &&
    this.candles.indexOf('ayudar') !== -1 &&
    this.candles.indexOf('mentir') === -1 &&
    this.candles.indexOf('estropear') === -1 &&
    this.candles.indexOf('envidiar') === -1 &&
    this.candles.indexOf('odiar') === -1 &&
    this.candles.indexOf('peder') === -1
    ) {
      if($('#luz').val().toLowerCase() === 'luz' &&
        $('#mundo').val().toLowerCase() === 'mundo' &&
        $('#monte').val().toLowerCase() === 'monte' &&
        $('#ocultar').val().toLowerCase() === 'esconder'
      ){
        $('#modalOk').modal('show');
        this.guardarActividad();
      }
      else{
        $('#modalFail').modal('show');
        $('.ayudas').text('Completa el versículo');
      }
    }
    else {
      $('#modalFail').modal('show');
      $('.ayudas').text('Prende todas las velas correctas');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
