export class Activity {
    id: string;
    key: string;
    online: boolean;
    order: number;
    title:string;
    img:string;
    description:string;
    bible_chapter: string;
    subtitle: string;
    check?: boolean;
}