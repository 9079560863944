import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity76',
  templateUrl: './activity76.component.html',
  styleUrls: ['./activity76.component.css']
})
export class Activity76Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '76'; // se refiere al número de actividad
  activity: Activity = new Activity();
  arreglo1 = [];
  resp12 = ['V', 'F', 'F', 'V', 'V', 'F', 'V'];
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;


    var arreglo1 = [];


    self.arreglo1[6] = "F";
    self.arreglo1[5] = "F";
    self.arreglo1[4] = "F";
    self.arreglo1[3] = "F";
    self.arreglo1[2] = "F";
    self.arreglo1[1] = "F";
    self.arreglo1[0] = "F";
    $('#id1').click(function () {

      $(this).switchClass("circle0", "circle01", 100).effect("highlight", { color: "#17a2b8" }, 2000,
        function () {
        }
      );
      $(this).switchClass("block12", "block1", 1000);
      self.arreglo1[0] = "V";
    });

    $('#id2').click(function () {

      $(this).switchClass("circle1", "circle11", 100).effect("highlight", { color: "#17a2b8" }, 2000,
        function () {
        }
      );
      $(this).switchClass("block22", "block2", 1000);
      self.arreglo1[1] = "V";;
    });

    $('#id3').click(function () {

      $(this).switchClass("circle2", "circle22", 100).effect("highlight", { color: "#17a2b8" }, 2000,
        function () {
        }
      );
      $(this).switchClass("block32", "block3", 1000);
      self.arreglo1[2] = "V";
    });

    $('#id4').click(function () {

      $(this).switchClass("circle3", "circle33", 100).effect("highlight", { color: "#17a2b8" }, 2000,
        function () {
        }
      );
      $(this).switchClass("block42", "block4", 1000);
      self.arreglo1[3] = "V";
    });
    $('#id5').click(function () {

      $(this).switchClass("circle4", "circle44", 100).effect("highlight", { color: "#17a2b8" }, 2000,
        function () {
        }
      );
      $(this).switchClass("block52", "block5", 1000);
      self.arreglo1[4] = "V";
    });

    $('#id6').click(function () {

      $(this).switchClass("circle5", "circle55", 100).effect("highlight", { color: "#17a2b8" }, 2000,
        function () {
        }
      );
      $(this).switchClass("block62", "block6", 1000);
      self.arreglo1[5] = "V";
    });

    $('#id7').click(function () {

      $(this).switchClass("circle6", "circle66", 100).effect("highlight", { color: "#17a2b8" }, 2000,
        function () {
        }
      );
      $(this).switchClass("block72", "block7", 1000);
      self.arreglo1[6] = "V";
    });

  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var iguales = 0;
    var iguales1 = 0;
    for (var i = 0; i < 7; i++) {
      if (this.resp12[i] === 'V') {
        if (this.resp12[i] == this.arreglo1[i])
          iguales++;
      } else {
        if (this.resp12[i] == this.arreglo1[i])
          iguales1++;
      }
    }

    if (iguales1 < 3) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica bien las frases falsas sobre Jesús ' + iguales1 + '/3');
      $('#id6').switchClass("circle55", "circle5", 100);
      $('#id6').switchClass("block6", "block62", 1000);

      $('#id7').switchClass("circle66", "circle6", 100);
      $('#id7').switchClass("block7", "block72", 1000);

      $('#id5').switchClass("circle44", "circle4", 100);
      $('#id5').switchClass("block5", "block52", 1000);

      $('#id4').switchClass("circle33", "circle3", 100);
      $('#id4').switchClass("block4", "block42", 1000);

      $('#id3').switchClass("circle22", "circle2", 100);
      $('#id3').switchClass("block3", "block32", 1000);

      $('#id2').switchClass("circle11", "circle1", 100);
      $('#id2').switchClass("block2", "block22", 1000);

      $('#id1').switchClass("circle01", "circle0", 100);
      $('#id1').switchClass("block1", "block12", 1000);

      this.arreglo1[6] = "F";
      this.arreglo1[5] = "F";
      this.arreglo1[4] = "F";
      this.arreglo1[3] = "F";
      this.arreglo1[2] = "F";
      this.arreglo1[1] = "F";
      this.arreglo1[0] = "F";
    } else if (iguales < 4) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica bien las frases verdaderas sobre Jesús ' + iguales + '/4');
      $('#id6').switchClass("circle55", "circle5", 100);
      $('#id6').switchClass("block6", "block62", 1000);

      $('#id7').switchClass("circle66", "circle6", 100);
      $('#id7').switchClass("block7", "block72", 1000);

      $('#id5').switchClass("circle44", "circle4", 100);
      $('#id5').switchClass("block5", "block52", 1000);

      $('#id4').switchClass("circle33", "circle3", 100);
      $('#id4').switchClass("block4", "block42", 1000);

      $('#id3').switchClass("circle22", "circle2", 100);
      $('#id3').switchClass("block3", "block32", 1000);

      $('#id2').switchClass("circle11", "circle1", 100);
      $('#id2').switchClass("block2", "block22", 1000);

      $('#id1').switchClass("circle01", "circle0", 100);
      $('#id1').switchClass("block1", "block12", 1000);

      this.arreglo1[6] = "F";
      this.arreglo1[5] = "F";
      this.arreglo1[4] = "F";
      this.arreglo1[3] = "F";
      this.arreglo1[2] = "F";
      this.arreglo1[1] = "F";
      this.arreglo1[0] = "F";
    }

    if ((iguales == 4 && iguales1 == 3)) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
