import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity87',
  templateUrl: './activity87.component.html',
  styleUrls: ['./activity87.component.css']
})
export class Activity87Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '87'; // se refiere al número de actividad
  activity: Activity = new Activity();
  $secuencias = [3, 8, 2];
  $secuencias2 = [6, 5, 7, 1, 4];

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    var $gallery = $("#gallery"), $drops = $(".drops-87"), $drops2 = $(".drops2-87");
    var $secuencia;
    var $secuencia2;
    $("li", $gallery).draggable({
      revert: "invalid",//devolver elemento posicion inicial
      stack: ".prags_87",//sobreponer elemto arrastable
      containment: "document",
      helper: "clone",
      snap: ".drops-87",//ajustar a la imagen de encaje
      snapMode: "out",//ajustar dentro del contorno
      snapTolerance: 30,//distancia de ajuste
    })

    $drops.droppable({
      accept: function (item) {
        $secuencia = self.$secuencias[0];
        return $secuencia == item.data("drag"); // identificador donde el arrastrable es igual al objetivo
      },
      activeClass: "ui-state-default",
      helper: "original",
      hoverClass: "ui-state-hover",
      drop: function (event, ui) {
        for (var i = 0; i < self.$secuencias.length; i++) {//identificar la pieza contra las opciones
          if (self.$secuencias[i] == $secuencia) {//si encunentra una similitud
            self.$secuencias.splice(i, 1); //elimina la opcio por si hay un mismo arrastre
          
          }
        }
        deleteImage(ui.draggable);

      }
    });


    $drops2.droppable({
      accept: function (item) {
        $secuencia2 = self.$secuencias2[0];
        return $secuencia2 == item.data("drag"); // identificador donde el arrastrable es igual al objetivo

      },
      activeClass: "ui-state-default",
      helper: "original",
      hoverClass: "ui-state-hover",
      drop: function (event, ui) {
        for (var i = 0; i < self.$secuencias2.length; i++) {//identificar la pieza contra las opciones
          if (self.$secuencias2[i] == $secuencia2) {//si encunentra una similitud
            self.$secuencias2.splice(i, 1); //elimina la opcio por si hay un mismo arrastre
            
          }
        }
        deleteImage2(ui.draggable);

      }
    });


    // Image deletion function

    function deleteImage($item) {
      $item.fadeOut(function () {
        var $list = $("ul", $drops).length ?
          $("ul", $drops) :
          $("<ul class='row justify-content-center gallery'/>").appendTo($drops);
        $item.appendTo($list).fadeIn();

        $('ul').css({ "padding": "0", "list-style": "none" });
      });
    }

    function deleteImage2($item) {
      $item.fadeOut(function () {
        var $list = $("ul", $drops2).length ?
          $("ul", $drops2) :
          $("<ul class='row justify-content-center gallery'/>").appendTo($drops2);
        $item.appendTo($list).fadeIn();

        $('ul').css({ "padding": "0", "list-style": "none" });
      });
    }

  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
   
    if (this.$secuencias.length === 0 && this.$secuencias2.length === 0) {// si ya no hay mas opciones disponibles finaiza

      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else {

      $('#modalFail').modal('show');
      $('.ayudas').text('Verifica que esten ubicados correctamente.');
    }

    
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
