import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity24',
  templateUrl: './activity24.component.html',
  styleUrls: ['./activity24.component.css']
})
export class Activity24Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '24'; // se refiere al número de actividad
  activity: Activity = new Activity();
  eventsClick = 0;
  iguales = 0;
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    var completo = false;

    $('#Comprobar').click(function () {
      var lstfrase = $('.frase');
      self.iguales = 0;

      var arrayGuardarFrase = [];
      for (var i = 0; i < lstfrase.length; i++) {
        arrayGuardarFrase[i] = lstfrase[i].value.toLowerCase();
      }
      var myArrayCorrecto = ['samuel', 'señor', 'revelado', 'palabra', 'señor', 'siervo'];

      for (var i = 0; i < arrayGuardarFrase.length; i++) {
        for (var j = i; j < (i + 1); j++) {
          if (arrayGuardarFrase[i] == myArrayCorrecto[i])
            self.iguales++;
        }
      }
      if (self.iguales == 6) {
        $('#modalOk').modal('show');
        completo = true;
      } else {
        $('#modalFail').modal('show');
        $('.ayudas').css('font-size', "20px")
        $('.ayudas').text('verifica que las palabras correspondan con cada una de las frases. ' + self.iguales + '/6');
      }
    });
    $(function () {

      $('.draggable-41').draggable({ revert: "valid" });
      $('.draggable-42').draggable({ revert: true });


      $('.droppable-72').droppable({
        hoverClass: 'active',
        drop: function (e, ui) {
          var temp = $(this).attr('id');

          $(this).effect("highlight", { color: "#17a2b8" }, 2000,
            function () { $(this).css("border-radius", "100px") }
          );

          if (temp === "drop1") {
            var inputNombre = $('#nombre1');
            $(inputNombre).val(verificarDRAG1(ui));
            $(inputNombre).css("border-radius", "100px");
            $(inputNombre).css("background-color", "#17a2b8");
          } else if (temp === "drop2") {
            var inputNombre = $('#nombre2');
            $(inputNombre).val(verificarDRAG1(ui));
            $(inputNombre).css("border-radius", "100px");
            $(inputNombre).css("background-color", "#17a2b8");
          } else if (temp === "drop3") {
            var inputNombre = $('#nombre3');
            $(inputNombre).val(verificarDRAG1(ui));
            $(inputNombre).css("border-radius", "100px");
            $(inputNombre).css("background-color", "#17a2b8");
          } else if (temp === "drop4") {
            var inputNombre = $('#nombre4');
            $(inputNombre).val(verificarDRAG1(ui));
            $(inputNombre).css("border-radius", "100px");
            $(inputNombre).css("background-color", "#17a2b8");
          } else if (temp === "drop5") {
            var inputNombre = $('#nombre5');
            $(inputNombre).val(verificarDRAG1(ui));
            $(inputNombre).css("border-radius", "100px");
            $(inputNombre).css("background-color", "#17a2b8");
          } else if (temp === "drop6") {
            var inputNombre = $('#nombre6');
            $(inputNombre).val(verificarDRAG1(ui));
            $(inputNombre).css("border-radius", "100px");
            $(inputNombre).css("background-color", "#17a2b8");
          }
        }
      });

    });

    function verificarDRAG1(ui) {
      var text = ui.draggable.attr("id");
      if (text === "drag1") {
        return "SEÑOR";
      } else if (text === "drag2") {
        return "SIERVO";
      } else if (text === "drag3") {
        return "SAMUEL";
      } else if (text === "drag4") {
        return "REVELADO";
      } else if (text === "drag5") {
        return "SEÑOR";
      } else if (text === "drag6") {
        return "PALABRA";
      }
    }
    var EsferaSelect = '';
    var arrayGuardar = [];

    $('.esfera-samuel').click(function () {
      if (self.eventsClick < 9) {
        EsferaSelect = $(this).attr('valor-esfera');
        self.eventsClick++;
        for (var i = (self.eventsClick - 1); i < self.eventsClick; i++) {
          arrayGuardar[i] = EsferaSelect;
        }
        var myArray = ['UN ', 'FI', 'EL ', 'PRO', 'FE', 'TA ', 'DEL ', 'SE', 'ÑOR'];

        for (var j = (self.eventsClick - 1); j < self.eventsClick; j++) {
          if (arrayGuardar[j] == myArray[j]) {
            $(this).css("background-color", "#4caf50");
          } else {
            $(this).css("background-color", "red");
          }
        }
        $('.text-samuel').val(arrayGuardar.join(''));
      }
    });
    $('#limpiar').click(function () {
      self.eventsClick = 0;
      EsferaSelect = '';
      $('.esfera-samuel').css("background-color", "#00000070");
      arrayGuardar = [];
      $('.text-samuel').val('');
    })

  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    if (this.eventsClick != 9) {
      $('#modalFail').modal('show');
      $('.ayudas').text('Organiza bien Las silabas.' + this.eventsClick + '/9');
    }

    if (this.iguales !== 6) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('verifica que las palabras en la parte superior correspondan con cada una de las frases. ' + this.iguales + '/6');
    }

    if (this.eventsClick == 9 && this.iguales == 6) {
      $('#modalOk').modal('show');
      this.guardarActividad();
      var myArray = ['UN', 'FIEL', 'PROFETA', 'DEL', 'SEÑOR'];
      $('.text-samuel').val('');
      $('.text-samuel').val(myArray.join(' ')).effect("highlight", { color: "#17a2b8" }, 1000,
        function () {
          $(this).css("border-radius", "100px")
        }
      );
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
