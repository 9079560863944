import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity42',
  templateUrl: './activity42.component.html',
  styleUrls: ['./activity42.component.css']
})
export class Activity42Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '42'; // se refiere al número de actividad
  activity: Activity = new Activity();
  NoVacios = [];
  afirmaciones = [];
  lstfrase2: any;
  Correctas = ['señor', 'vuelve', 'señor', 'consolado', 'redimido', 'salvación', 'dios'];
  Correctas2 = ['retaguardia', 'vanguardia'];


  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    $(".frase-BueNuevas").on("keyup ", function () {
      $('.frase-BueNuevas').each(function (i) {
        let lstfrase = $(this).val().toLowerCase();
        self.afirmaciones[i] = lstfrase;
        if (self.afirmaciones[i] == self.Correctas[i]) {
          $(this).css("border-bottom", "3px solid #4caf50");
        } else {
          $(this).css("border-bottom", "");
        }
      });
      console.log(self.afirmaciones);
    });

    $(".frase2-BueNuevas").on("keyup ", function () {
      $('.frase2-BueNuevas').each(function (i) {
        self.lstfrase2 = $(this).val().toLowerCase();
        self.NoVacios[i] = self.lstfrase2;
        if (self.NoVacios[i] == self.Correctas2[i]) {
          $(this).css("border-bottom", "3px solid #4caf50");
        } else {
          $(this).css("border-bottom", " ");
        }
      });
      console.log(self.NoVacios);
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    
    var iguales = 0;
    var iguales_part2 = 0;
    for (var i = 0; i < this.afirmaciones.length; i++) {
      for (var j = i; j < (i + 1); j++) {
        if (this.afirmaciones[i] == this.Correctas[i])
          iguales++;
      }
    }
    
    for (var i = 0; i < this.NoVacios.length; i++) {
      for (var j = i; j < (i + 1); j++) {
        if (this.NoVacios[i] == this.Correctas2[i])
          iguales_part2++;
      }
    }
    console.log(iguales_part2+" y "+iguales);

    if (iguales == 7 && iguales_part2 == 2) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    } else {
      $('#modalFail').modal('show');
      $('.ayudas').html('verifica que los campos sean los correctos.<br>Parte 1: ' + iguales + '/7<br>Parte 2: ' + iguales_part2 + '/2');
    };
   
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
