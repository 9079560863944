export class Heroe {
    id:string;
    uid_user:string;
    name:string;
    lastName:string;
    age:number;
    birthDay?:string;
    nameFileAvatar?:string;
    createDate?:number;
    progressbarGrl?:number=0;
    activities: string[] = [];
}
