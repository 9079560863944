import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
import Konva from 'konva';
declare var $: any;

@Component({
  selector: 'app-activity80',
  templateUrl: './activity80.component.html',
  styleUrls: ['./activity80.component.css']
})
export class Activity80Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '80'; // se refiere al número de actividad
  activity: Activity = new Activity();
  totalColors : any = [];
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    var width = window.innerWidth;
    var height = window.innerHeight;

    if (width <= 500) {
      width = 340;
      height = 485;
    }else if(width >= 500 && width <= 1000) {
      width = 405;
      height = 663;
    }else{
      width = 605;
      height = 863;
    }

    var self = this;

    var stage = new Konva.Stage({
      container: 'canvas',
      width: width,
      height: height
    });

    var layer = new Konva.Layer();
    stage.add(layer);

    // var backgroundImg = new Image();
    // backgroundImg.src = './assets/img/actividades/80JesusResuc2/Imagen.png';

    var background = new Konva.Rect({
      fill: '#FFF',
      name: 'background',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(background);

    var cieloTrazoImg = new Image();
    cieloTrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/TRAZO/Cielo.png';

    var cieloTrazo = new Konva.Image({
      image: cieloTrazoImg,
      name: 'cieloTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cieloTrazo);

    var heavenImg = new Image();
    heavenImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Cielo.png';

    var heaven = new Konva.Image({
      image: heavenImg,
      name: 'heaven',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(heaven);

    var cueva1TrazoImg = new Image();
    cueva1TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/TRAZO/Cueva_1.png';

    var cueva1Trazo = new Konva.Image({
      image: cueva1TrazoImg,
      name: 'cueva1Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cueva1Trazo);

    var cave1Img = new Image();
    cave1Img.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Cueva_1.png';

    var cave1 = new Konva.Image({
      image: cave1Img,
      name: 'cave1',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cave1);

    var cueva2TrazoImg = new Image();
    cueva2TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/TRAZO/Cueva_2.png';

    var cueva2Trazo = new Konva.Image({
      image: cueva2TrazoImg,
      name: 'cueva2Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cueva2Trazo);

    var cave2Img = new Image();
    cave2Img.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Cueva_2.png';

    var cave2 = new Konva.Image({
      image: cave2Img,
      name: 'cave2',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cave2);

    var cueva3TrazoImg = new Image();
    cueva3TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/TRAZO/Cueva_3.png';

    var cueva3Trazo = new Konva.Image({
      image: cueva3TrazoImg,
      name: 'cueva3Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cueva3Trazo);

    var cave3Img = new Image();
    cave3Img.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Cueva_3.png';

    var cave3 = new Konva.Image({
      image: cave3Img,
      name: 'cave3',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cave3);

    var rocaTrazoImg = new Image();
    rocaTrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/TRAZO/Roca.png';

    var rocaTrazo = new Konva.Image({
      image: rocaTrazoImg,
      name: 'rocaTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(rocaTrazo);

    var stoneImg = new Image();
    stoneImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Roca.png';

    var stone = new Konva.Image({
      image: stoneImg,
      name: 'stone',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(stone);

    var sueloTrazoImg = new Image();
    sueloTrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/TRAZO/Suelo.png';

    var sueloTrazo = new Konva.Image({
      image: sueloTrazoImg,
      name: 'sueloTrazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(sueloTrazo);

    var groundImg = new Image();
    groundImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Suelo.png';

    var ground = new Konva.Image({
      image: groundImg,
      name: 'ground',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(ground);

    var skinImg = new Image();
    skinImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Piel.png';

    var skin = new Konva.Image({
      image: skinImg,
      name: 'skin',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(skin);

    var skin2Img = new Image();
    skin2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Piel.png';

    var skin2 = new Konva.Image({
      image: skin2Img,
      name: 'skin2',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(skin2);

    var boca1TrazoImg = new Image();
    boca1TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/TRAZO/Boca.png';

    var boca1Trazo = new Konva.Image({
      image: boca1TrazoImg,
      name: 'boca1Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(boca1Trazo);

    var mouthImg = new Image();
    mouthImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Boca.png';

    var mouth = new Konva.Image({
      image: mouthImg,
      name: 'mouth',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(mouth);

    var mouth2Img = new Image();
    mouth2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Boca.png';

    var mouth2 = new Konva.Image({
      image: mouth2Img,
      name: 'mouth2',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(mouth2);

    var cabello1TrazoImg = new Image();
    cabello1TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/TRAZO/Cabello.png';

    var cabello1Trazo = new Konva.Image({
      image: cabello1TrazoImg,
      name: 'cabello1Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cabello1Trazo);

    var hairImg = new Image();
    hairImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Cabello.png';

    var hair = new Konva.Image({
      image: hairImg,
      name: 'hair',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(hair);

    var hair2Img = new Image();
    hair2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Cabello.png';

    var hair2 = new Konva.Image({
      image: hair2Img,
      name: 'hair2',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(hair2);

    var cejasNariz1TrazoImg = new Image();
    cejasNariz1TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/TRAZO/Cejas_nariz.png';

    var cejasNariz1Trazo = new Konva.Image({
      image: cejasNariz1TrazoImg,
      name: 'cejasNariz1Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cejasNariz1Trazo);

    var cinturon1TrazoImg = new Image();
    cinturon1TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/TRAZO/Cinturon.png';

    var cinturon1Trazo = new Konva.Image({
      image: cinturon1TrazoImg,
      name: 'cinturon1Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cinturon1Trazo);

    var beltImg = new Image();
    beltImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Cinturon.png';

    var belt = new Konva.Image({
      image: beltImg,
      name: 'belt',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(belt);

    var belt2Img = new Image();
    belt2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Cinturon.png';

    var belt2 = new Konva.Image({
      image: belt2Img,
      name: 'belt2',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(belt2);

    var dientes1TrazoImg = new Image();
    dientes1TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/TRAZO/Dientes.png';

    var dientes1Trazo = new Konva.Image({
      image: dientes1TrazoImg,
      name: 'dientes1Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(dientes1Trazo);

    var teethImg = new Image();
    teethImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Dientes.png';

    var teeth = new Konva.Image({
      image: teethImg,
      name: 'teeth',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(teeth);

    var teeth2Img = new Image();
    teeth2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Dientes.png';

    var teeth2 = new Konva.Image({
      image: teeth2Img,
      name: 'teeth2',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(teeth2);

    var iris1TrazoImg = new Image();
    iris1TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/TRAZO/Iris.png';

    var iris1Trazo = new Konva.Image({
      image: iris1TrazoImg,
      name: 'iris1Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(iris1Trazo);

    var irisImg = new Image();
    irisImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Iris.png';

    var iris = new Konva.Image({
      image: irisImg,
      name: 'iris',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(iris);

    var iris2Img = new Image();
    iris2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Iris2.png';

    var iris2 = new Konva.Image({
      image: iris2Img,
      name: 'iris2',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(iris2);

    var lengua1TrazoImg = new Image();
    lengua1TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/TRAZO/Lengua.png';

    var lengua1Trazo = new Konva.Image({
      image: lengua1TrazoImg,
      name: 'lengua1Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(lengua1Trazo);

    var tongueImg = new Image();
    tongueImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Lengua.png';

    var tongue = new Konva.Image({
      image: tongueImg,
      name: 'tongue',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(tongue);

    var tongue2Img = new Image();
    tongue2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Lengua.png';

    var tongue2 = new Konva.Image({
      image: tongue2Img,
      name: 'tongue2',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(tongue2);

    var ojos1TrazoImg = new Image();
    ojos1TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/TRAZO/Ojos.png';

    var ojos1Trazo = new Konva.Image({
      image: ojos1TrazoImg,
      name: 'ojos1Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(ojos1Trazo);

    var eyesImg = new Image();
    eyesImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Ojos.png';

    var eyes = new Konva.Image({
      image: eyesImg,
      name: 'eyes',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(eyes);

    var eyes2Img = new Image();
    eyes2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Ojos.png';

    var eyes2 = new Konva.Image({
      image: eyes2Img,
      name: 'eyes2',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(eyes2);

    var piel1TrazoImg = new Image();
    piel1TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/TRAZO/Piel.png';

    var piel1Trazo = new Konva.Image({
      image: piel1TrazoImg,
      name: 'piel1Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(piel1Trazo);

    var ropa1TrazoImg = new Image();
    ropa1TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/TRAZO/Ropa.png';

    var ropa1Trazo = new Konva.Image({
      image: ropa1TrazoImg,
      name: 'ropa1Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(ropa1Trazo);

    var clothesImg = new Image();
    clothesImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Ropa.png';

    var clothes = new Konva.Image({
      image: clothesImg,
      name: 'clothes',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(clothes);

    var clothes2Img = new Image();
    clothes2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Ropa.png';

    var clothes2 = new Konva.Image({
      image: clothes2Img,
      name: 'clothes2',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(clothes2);

    var boca2TrazoImg = new Image();
    boca2TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/TRAZO/Boca.png';

    var boca2Trazo = new Konva.Image({
      image: boca2TrazoImg,
      name: 'boca2Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(boca2Trazo);

    var cabello2TrazoImg = new Image();
    cabello2TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/TRAZO/Cabello.png';

    var cabello2Trazo = new Konva.Image({
      image: cabello2TrazoImg,
      name: 'cabello2Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cabello2Trazo);

    var cejasNariz2TrazoImg = new Image();
    cejasNariz2TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/TRAZO/Cejas_nariz.png';

    var cejasNariz2Trazo = new Konva.Image({
      image: cejasNariz2TrazoImg,
      name: 'cejasNariz2Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cejasNariz2Trazo);

    var cinturon2TrazoImg = new Image();
    cinturon2TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/TRAZO/Cinturon.png';

    var cinturon2Trazo = new Konva.Image({
      image: cinturon2TrazoImg,
      name: 'cinturon2Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(cinturon2Trazo);

    var dientes2TrazoImg = new Image();
    dientes2TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/TRAZO/Dientes.png';

    var dientes2Trazo = new Konva.Image({
      image: dientes2TrazoImg,
      name: 'dientes2Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(dientes2Trazo);

    var iris2TrazoImg = new Image();
    iris2TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/TRAZO/Iris.png';

    var iris2Trazo = new Konva.Image({
      image: iris2TrazoImg,
      name: 'iris2Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(iris2Trazo);

    var lengua2TrazoImg = new Image();
    lengua2TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/TRAZO/Lengua.png';

    var lengua2Trazo = new Konva.Image({
      image: lengua2TrazoImg,
      name: 'lengua2Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(lengua2Trazo);

    var ojos2TrazoImg = new Image();
    ojos2TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/TRAZO/Ojos.png';

    var ojos2Trazo = new Konva.Image({
      image: ojos2TrazoImg,
      name: 'ojos2Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(ojos2Trazo);

    var piel2TrazoImg = new Image();
    piel2TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/TRAZO/Piel.png';

    var piel2Trazo = new Konva.Image({
      image: piel2TrazoImg,
      name: 'piel2Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(piel2Trazo);

    var ropa2TrazoImg = new Image();
    ropa2TrazoImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/TRAZO/Ropa.png';

    var ropa2Trazo = new Konva.Image({
      image: ropa2TrazoImg,
      name: 'ropa2Trazo',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layer.add(ropa2Trazo);

    var colors = new Konva.Stage({
      container: 'colors',
      width: window.innerWidth >= 1000 ? 800 : 350,
      height: window.innerWidth <= 500 ? 150 : 280,
    });

    var layerColors = new Konva.Layer();

    var backgroundColors = new Konva.Rect({
      fill: '#FFF',
      name: 'colors',
      x: 0,
      y: 0,
      width: width,
      height: height,
      draggable: false
    });
    layerColors.add(backgroundColors);

    var red = new Konva.Circle({
      x: stage.width() / 8,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#df3f5a',
      stroke: 'black',
      strokeWidth: 4,
      name: 'red'
    });

    red.on('click', function (evt) {
      clothesImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/COLOR/Ropa.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    red.on('touchend', function (evt) {
      clothesImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/COLOR/Ropa.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(red);

    var orange = new Konva.Circle({
      x: stage.width() / 8 * 2,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#f28a5f',
      stroke: 'black',
      strokeWidth: 4,
      name: 'orange'
    });

    orange.on('click', function (evt) {
      hairImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/COLOR/Cabello.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    orange.on('touchend', function (evt) {
      hairImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/COLOR/Cabello.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(orange);

    var yellow = new Konva.Circle({
      x: stage.width() / 8 * 3,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#ffef3d',
      stroke: 'black',
      strokeWidth: 4,
      name: 'yellow'
    });

    yellow.on('click', function (evt) {
      beltImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/COLOR/Cinturon.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    yellow.on('touchend', function (evt) {
      beltImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/COLOR/Cinturon.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(yellow);

    var blue = new Konva.Circle({
      x: stage.width() / 8 * 4,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#3b7ecd',
      stroke: 'black',
      strokeWidth: 4,
      name: 'blue'
    });

    blue.on('click', function (evt) {
      irisImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/COLOR/Iris.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    blue.on('touchend', function (evt) {
      irisImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/COLOR/Iris.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(blue);

    var green = new Konva.Circle({
      x: stage.width() / 8 * 5,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#6daa17',
      stroke: 'black',
      strokeWidth: 4,
      name: 'green'
    });

    green.on('click', function (evt) {
      iris2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/COLOR/Iris.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    green.on('touchend', function (evt) {
      iris2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/COLOR/Iris.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(green);

    var brown = new Konva.Circle({
      x: stage.width() / 8 * 6,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#572b1b',
      stroke: 'black',
      strokeWidth: 4,
      name: 'brown'
    });

    brown.on('click', function (evt) {
      hair2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/COLOR/Cabello.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    brown.on('touchend', function (evt) {
      hair2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/COLOR/Cabello.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(brown);

    var skinColor = new Konva.Circle({
      x: stage.width() / 8 * 7,
      y: stage.height() / 12,
      radius: stage.width() / 20,
      fill: '#ffccab',
      stroke: 'black',
      strokeWidth: 4,
      name: 'skinColor'
    });

    skinColor.on('click', function (evt) {
      skinImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/COLOR/Piel.png';
      skin2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/COLOR/Piel.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    skinColor.on('touchend', function (evt) {
      skinImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/COLOR/Piel.png';
      skin2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/COLOR/Piel.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(skinColor);

    var black = new Konva.Circle({
      x: stage.width() / 8,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: 'black',
      stroke: 'black',
      strokeWidth: 4,
      name: 'black'
    });

    black.on('click', function (evt) {
      cave1Img.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/COLOR/Cueva_1.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    black.on('touchend', function (evt) {
      cave1Img.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/COLOR/Cueva_1.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(black);

    var brown2 = new Konva.Circle({
      x: stage.width() / 8 * 2,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: '#b39287',
      stroke: 'black',
      strokeWidth: 4,
      name: 'brown2'
    });

    brown2.on('click', function (evt) {
      cave3Img.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/COLOR/Cueva_3.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    brown2.on('touchend', function (evt) {
      cave3Img.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/COLOR/Cueva_3.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(brown2);

    var brown3 = new Konva.Circle({
      x: stage.width() / 8 * 3,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: '#724c44',
      stroke: 'black',
      strokeWidth: 4,
      name: 'brown3'
    });

    brown3.on('click', function (evt) {
      cave2Img.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/COLOR/Cueva_2.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    brown3.on('touchend', function (evt) {
      cave2Img.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/COLOR/Cueva_2.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(brown3);

    var red2 = new Konva.Circle({
      x: stage.width() / 8 * 4,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: '#fa442b',
      stroke: 'black',
      strokeWidth: 4,
      name: 'red2'
    });

    red2.on('click', function (evt) {
      belt2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/COLOR/Cinturon.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    red2.on('touchend', function (evt) {
      belt2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/COLOR/Cinturon.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(red2);

    var blue2 = new Konva.Circle({
      x: stage.width() / 8 * 5,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: '#00b0ce',
      stroke: 'black',
      strokeWidth: 4,
      name: 'blue2'
    });

    blue2.on('click', function (evt) {
      clothes2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/COLOR/Ropa.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    blue2.on('touchend', function (evt) {
      clothes2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/COLOR/Ropa.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(blue2);

    var blue3 = new Konva.Circle({
      x: stage.width() / 8 * 6,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: '#93bff2',
      stroke: 'black',
      strokeWidth: 4,
      name: 'blue3'
    });

    blue3.on('click', function (evt) {
      heavenImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/COLOR/Cielo.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    blue3.on('touchend', function (evt) {
      heavenImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/COLOR/Cielo.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(blue3);

    var brown4 = new Konva.Circle({
      x: stage.width() / 8 * 7,
      y: stage.height() / 12 * 2,
      radius: stage.width() / 20,
      fill: '#b18661',
      stroke: 'black',
      strokeWidth: 4,
      name: 'brown4'
    });

    brown4.on('click', function (evt) {
      stoneImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/COLOR/Roca.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    brown4.on('touchend', function (evt) {
      stoneImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/COLOR/Roca.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(brown4);

    var yellow2 = new Konva.Circle({
      x: stage.width() / 8 * 1,
      y: stage.height() / 12 * 3,
      radius: stage.width() / 20,
      fill: '#ffe5ad',
      stroke: 'black',
      strokeWidth: 4,
      name: 'yellow2'
    });

    yellow2.on('click', function (evt) {
      groundImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/COLOR/Suelo.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    yellow2.on('touchend', function (evt) {
      groundImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/COLOR/Suelo.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(yellow2);

    var orange2 = new Konva.Circle({
      x: stage.width() / 8 * 2,
      y: stage.height() / 12 * 3,
      radius: stage.width() / 20,
      fill: '#f26849',
      stroke: 'black',
      strokeWidth: 4,
      name: 'orange2'
    });

    orange2.on('click', function (evt) {
      tongueImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/COLOR/Lengua.png';
      tongue2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/COLOR/Lengua.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    orange2.on('touchend', function (evt) {
      tongueImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/COLOR/Lengua.png';
      tongue2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/COLOR/Lengua.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(orange2);

    var brown5 = new Konva.Circle({
      x: stage.width() / 8 * 3,
      y: stage.height() / 12 * 3,
      radius: stage.width() / 20,
      fill: '#4c3326',
      stroke: 'black',
      strokeWidth: 4,
      name: 'brown5'
    });

    brown5.on('click', function (evt) {
      mouthImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/COLOR/Boca.png';
      mouth2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/COLOR/Boca.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    brown5.on('touchend', function (evt) {
      mouthImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/COLOR/Boca.png';
      mouth2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/COLOR/Boca.png';
      self.totalColors = self.totalColors.filter((word) => word !== evt.target.attrs.name);
      self.totalColors.push(evt.target.attrs.name);
    });

    layerColors.add(brown5);

    var white = new Konva.Circle({
      x: stage.width() / 8 * 4,
      y: stage.height() / 12 * 3,
      radius: stage.width() / 20,
      fill: 'white',
      stroke: 'black',
      strokeWidth: 4,
      name : 'white'
    });

    white.on('click', function (evt) {
      heavenImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Cielo.png';
      cave1Img.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Cueva_1.png';
      cave2Img.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Cueva_2.png';
      cave3Img.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Cueva_3.png';
      stoneImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Roca.png';
      groundImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Suelo.png';

      mouthImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Boca.png';
      hairImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Cabello.png';
      beltImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Cinturon.png';
      teethImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Dientes.png';
      irisImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Iris.png';
      tongueImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Lengua.png';
      eyesImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Ojos.png';
      skinImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Piel.png';
      clothesImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Ropa.png';

      mouth2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Boca.png';
      hair2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Cabello.png';
      belt2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Cinturon.png';
      teeth2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Dientes.png';
      iris2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Iris2.png';
      tongue2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Lengua.png';
      eyes2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Ojos.png';
      skin2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Piel.png';
      clothes2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Ropa.png';

      self.totalColors = [];
    });

    white.on('touchend', function (evt) {
      heavenImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Cielo.png';
      cave1Img.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Cueva_1.png';
      cave2Img.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Cueva_2.png';
      cave3Img.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Cueva_3.png';
      stoneImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Roca.png';
      groundImg.src = './assets/img/actividades/80JesusResuc2/Partes/ESCENARIO/SIN_TRAZO/BLANCO/Suelo.png';

      mouthImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Boca.png';
      hairImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Cabello.png';
      beltImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Cinturon.png';
      teethImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Dientes.png';
      irisImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Iris.png';
      tongueImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Lengua.png';
      eyesImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Ojos.png';
      skinImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Piel.png';
      clothesImg.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE1/SIN_TRAZO/BLANCO/Ropa.png';

      mouth2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Boca.png';
      hair2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Cabello.png';
      belt2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Cinturon.png';
      teeth2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Dientes.png';
      iris2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Iris2.png';
      tongue2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Lengua.png';
      eyes2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Ojos.png';
      skin2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Piel.png';
      clothes2Img.src = './assets/img/actividades/80JesusResuc2/Partes/PERSONAJE2/SIN_TRAZO/BLANCO/Ropa.png';

      self.totalColors = [];
    });

    layerColors.add(white);

    colors.add(layerColors);    
  }

  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    console.log(this.totalColors);
    if(this.totalColors.length === 0){
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad coloreando toda la imagen');
    }
    else if(
      this.totalColors.indexOf('red') !== -1 &&
      this.totalColors.indexOf('orange') !== -1 &&
      this.totalColors.indexOf('yellow') !== -1 &&
      this.totalColors.indexOf('blue') !== -1 &&
      this.totalColors.indexOf('green') !== -1 &&
      this.totalColors.indexOf('brown') !== -1 &&
      this.totalColors.indexOf('skinColor') !== -1 &&
      this.totalColors.indexOf('black') !== -1 &&
      this.totalColors.indexOf('brown2') !== -1 &&
      this.totalColors.indexOf('brown3') !== -1 &&
      this.totalColors.indexOf('red2') !== -1 &&
      this.totalColors.indexOf('blue2') !== -1 &&
      this.totalColors.indexOf('blue3') !== -1 &&
      this.totalColors.indexOf('brown4') !== -1 &&
      this.totalColors.indexOf('yellow2') !== -1 &&
      this.totalColors.indexOf('orange2') !== -1 &&
      this.totalColors.indexOf('brown5') !== -1
    ){
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else{
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad coloreando toda la imagen');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
