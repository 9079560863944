import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity20',
  templateUrl: './activity20.component.html',
  styleUrls: ['./activity20.component.css']
})
export class Activity20Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '20'; // se refiere al número de actividad
  activity: Activity = new Activity();
  banderaResp = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;

    let widgetActual = 1;
    $(".ui-widget-content").click(function () {

      var temp = $(this).attr('id');
      console.log(temp);
      if (self.banderaResp[widgetActual - 1] == 0)
        $("#" + "t" + widgetActual).val("");

      $("#" + "t" + widgetActual).prop('readonly', true);

      let var1 = temp[temp.length - 1];
      widgetActual = var1;

      console.log(var1);
      document.getElementById("t" + var1).focus();

      $("#" + "t" + var1).effect("highlight", { color: "#b81795" }, 1000,
        function () { $("#" + "t" + var1).css("border-radius", "90px") }
      );

      $("#" + "t" + var1).prop('readonly', false);
    });

    $('.tex-rut1').on('input', function () {
      var temp = $(this).attr('id');
      let resp = $(this).val();

      if (temp == "t1") {
        if (resp.toLowerCase() == "amargura" && resp.length == 8) {
          self.banderaResp[0] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[0] = 0;
        }

      }

      if (temp == "t2") {
        if ((resp.toLowerCase() == "belen" || resp.toLowerCase() == "belén") && resp.length == 5) {
          self.banderaResp[1] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[1] = 0;
        }
      }

      if (temp == "t3") {
        if (resp.toLowerCase() == "cebada" && resp.length == 6) {
          self.banderaResp[2] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[2] = 0;
        }
      }

      if (temp == "t4") {
        if (resp.toLowerCase() == "desamparada" && resp.length == 11) {
          self.banderaResp[3] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[3] = 0;
        }
      }

      if (temp == "t5") {
        if (resp.toLowerCase() == "elimelec" && resp.length == 8) {
          self.banderaResp[4] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[4] = 0;
        }
      }

      if (temp == "t6") {
        if ((resp.toLowerCase() == "fe" || resp.toLowerCase() == "fé") && resp.length == 2) {
          self.banderaResp[5] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[5] = 0;
        }
      }

      if(temp=="t7"){
        if((resp.toLowerCase()== "gobernaban"|| resp.toLowerCase()== "gobiernan"|| resp.toLowerCase()== "gobernar") && resp.length > 7){
          self.banderaResp[6]=1;
          $("#"+"s"+widgetActual).addClass("ui-selected");
          $(this).css("color","#0d5b46");
          $(this).css("background-color","#d3d4c5");
          $("#"+"t"+widgetActual).prop('readonly',true);
        }
        else{
          $("#"+"s"+widgetActual).removeClass("ui-selected");
          $(this).css("color","crimson");
          self.banderaResp[6]=0;
        }
      }

      if (temp == "t8") {
        if (resp.toLowerCase() == "hijas" && resp.length == 5) {
          self.banderaResp[7] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[7] = 0;
        }
      }

      if (temp == "t9") {
        if (resp.toLowerCase() == "israel" && resp.length == 6) {
          self.banderaResp[8] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[8] = 0;
        }
      }

      if (temp == "t0") {
        if (resp.toLowerCase() == "juntas" && resp.length == 6) {
          self.banderaResp[9] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[9] = 0;
        }
      }

      if (temp == "tA") {
        if (resp.toLowerCase() == "las" && resp.length == 3) {
          self.banderaResp[10] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);;
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[10] = 0;
        }
      }

      if (temp == "tB") {
        if (resp.toLowerCase() == "mara" && resp.length == 4) {
          self.banderaResp[11] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[11] = 0;
        }
      }

      if (temp == "tC") {
        if ((resp.toLowerCase() == "noemí" || resp.toLowerCase() == "noemi") && resp.length == 5) {
          self.banderaResp[12] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[12] = 0;
        }
      }

      if (temp == "tD") {
        if ((resp.toLowerCase() == "orfa") && resp.length == 4) {
          self.banderaResp[13] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[13] = 0;
        }
      }

      if (temp == "tE") {
        if ((resp.toLowerCase() == "pueblo") && resp.length == 6) {
          self.banderaResp[14] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[14] = 0;
        }
      }

      if (temp == "tF") {
        if ((resp.toLowerCase() == "quelión") && resp.length == 7) {
          self.banderaResp[15] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[15] = 0;
        }
      }

      if (temp == "tG") {
        if ((resp.toLowerCase() == "rut") && resp.length == 3) {
          self.banderaResp[16] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[16] = 0;
        }
      }

      if (temp == "tH") {
        if ((resp.toLowerCase() == "separará") && resp.length == 8) {
          self.banderaResp[17] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[17] = 0;
        }
      }

      if (temp == "tI") {
        if ((resp.toLowerCase() == "todopoderoso") && resp.length == 12) {
          self.banderaResp[18] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[18] = 0;
        }
      }

      if (temp == "tJ") {
        if ((resp.toLowerCase() == "un beso") && resp.length == 7) {
          self.banderaResp[19] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[19] = 0;
        }
      }

      if (temp == "tK") {
        if ((resp.toLowerCase() == "vacías") && resp.length == 6) {
          self.banderaResp[20] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[20] = 0;
        }
      }

      if (temp == "tL") {
        if ((resp.toLowerCase() == "voz en cuello") && resp.length == 13) {
          self.banderaResp[21] = 1;
          $("#" + "s" + widgetActual).addClass("ui-selected");
          $(this).css("color", "#0d5b46");
          $(this).css("background-color", "#d3d4c5");
          $("#" + "t" + widgetActual).prop('readonly', true);
        }
        else {
          $("#" + "s" + widgetActual).removeClass("ui-selected");
          $(this).css("color", "crimson");
          self.banderaResp[21] = 0;
        }
      }
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var igual = 0;

    for (var i = 0; i < this.banderaResp.length; i++) {
      if (this.banderaResp[i] == 1)
        igual++;
    }

    if (igual < 22) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica que hayas respondido todas las preguntas o que las letras estan todas iluminadas ' + igual + '/22');
    }

    if (igual == 22) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }

  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
