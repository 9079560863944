import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity56',
  templateUrl: './activity56.component.html',
  styleUrls: ['./activity56.component.css']
})
export class Activity56Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '56'; // se refiere al número de actividad
  activity: Activity = new Activity();
  arreglo1 = [];
  resp12 = ['2', '3', '4', '1'];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self=this;
    $(".draggable-4").draggable({ containment: "#containment-wrapper", scroll: true });

    $('.draggable-4').draggable({ revert: true });


    $('.droppable-7').droppable({
      hoverClass: 'active',
      drop: function (e, ui) {
        var temp = $(this).attr('id');

        $(this).effect("highlight", { color: "#17a2b8" }, 2000,
          function () { $(this).css("border-radius", "90px") }
        );

        if (temp === "drop1") {
          var inputNombre = $('#input1');
          var temp1 = verificarDRAG(ui);
          inputNombre.val(temp1);
          $(inputNombre).addClass("cuadrosTRUE")

          self.arreglo1[0] = temp1;

        } else if (temp === "drop2") {
          var inputNombre = $('#input2');
          var temp1 = verificarDRAG(ui)
          inputNombre.val(temp1);
          $(inputNombre).addClass("cuadrosTRUE")

          self.arreglo1[1] = temp1;

        } else if (temp === "drop3") {
          var inputNombre = $('#input3');
          var temp1 = verificarDRAG(ui)
          inputNombre.val(temp1);
          $(inputNombre).addClass("cuadrosTRUE")

          self.arreglo1[2] = temp1;

        } else if (temp === "drop4") {
          var inputNombre = $('#input4');
          var temp1 = verificarDRAG(ui);
          inputNombre.val(temp1);
          $(inputNombre).addClass("cuadrosTRUE")

          self.arreglo1[3] = temp1;
        }

      }
    });
    function verificarDRAG(ui) {
      var text = ui.draggable.attr("id");
      if (text === "drag1") {
        return "1";
      } else if (text === "drag2") {
        return "2";
      } else if (text === "drag3") {
        return "3";
      } else if (text === "drag4") {
        return "4";
      }
    }

    function Limpiar(e, ui) {
      $("#input1").val("");
      $("#input2").val("");
      $("#input3").val("");
      $("#input4").val("");
      $("#input1").removeClass("cuadrosTRUE");
      $("#input2").removeClass("cuadrosTRUE");
      $("#input3").removeClass("cuadrosTRUE");
      $("#input4").removeClass("cuadrosTRUE");

    }
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    console.log(this.arreglo1)
    var iguales = 0;
    for (var i = 0; i < 4; i++) {
      if (this.resp12[i] == this.arreglo1[i])
        iguales++;
    }
    if (iguales < 4) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica bien las afirmaciones para que concuerden con las enseñanzas que te dejan. ' + iguales + '/4');
      $("#input1").val("");
      $("#input2").val("");
      $("#input3").val("");
      $("#input4").val("");
      $("#input1").removeClass("cuadrosTRUE");
      $("#input2").removeClass("cuadrosTRUE");
      $("#input3").removeClass("cuadrosTRUE");
      $("#input4").removeClass("cuadrosTRUE");
    }
    if ((iguales == 4)) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
