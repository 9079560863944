import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity50',
  templateUrl: './activity50.component.html',
  styleUrls: ['./activity50.component.css']
})
export class Activity50Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '50'; // se refiere al número de actividad
  activity: Activity = new Activity();
  ballena = false;
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    var $drags = $(".drag_50"), $drops = $('.drop_50'), $pieza;//elemetos arrastrables y objetivos
    //var $opciones = ['sabio', 'alegra', 'padre', 'necio', 'entristece', 'madre', 'versiculo']//nombre de las 7 opciones
    var self = this;
    $drags.draggable({
      revert: "invalid",//devolver elemento posicion inicial
      stack: ".drag_50",//sobreponer elemto arrastable
      snap: ".drop_50",//ajustar a la imagen de encaje
      snapMode: "inner",//ajustar dentro del contorno
      snapTolerance: 30,//distancia de ajuste
    });

    $drops.droppable({
      accept: function (item) {
        return $(this).data("pieza") == item.data("pieza"); // identificador donde el arrastrable es igual al objetivo
      },
      activeClass: "ui-state-default",
      hoverClass: "ui-state-hover",
      drop: function (event, ui) {
        self.ballena = true;
      }
    });
    $(".palabras-block").attr('active', 'false');
    $(".palabras-block").click(function () {
      var estado = $(this).attr('active');
      if (estado == 'false') {
        $(this).css("background-color", "#e3b631");
        $(this).attr('active', 'true');
      } else {
        if (estado == 'true') {
          $(this).css("background-color", "");
          $(this).attr('active', 'false');
        }
      }
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var estados = [];
    var completado = 0;
    var correctas = ['false', 'false', 'false', 'true', 'false', 'false', 'true'];
    $('.palabras-block').each(function (i) {

      this.lstestados = $(this).attr('active');
      estados[i] = this.lstestados;
      //console.log(estados);
      if (estados[i] == correctas[i] && estados[i] == 'true') {
        $(this).css("background-color", "#4caf50");
        completado++;
      } else {
        $(this).css("background-color", "red ");

      }
    });
    if (this.ballena == true) {
      if (completado == 2) {
        $('#modalOk').modal('show');
        this.guardarActividad();
      } else {
        $('#modalFail').modal('show');
        $('.ayudas').text('verifica que los hayas seleccinado las acciones correctas.' + completado + '/2');
      }
    } else {
      $('#modalFail').modal('show');
      $('.ayudas').text('Ubica a jonás en la ballena');
    }

  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
