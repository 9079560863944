import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
import Konva from 'konva';
declare var $: any;

@Component({
  selector: 'app-activity22',
  templateUrl: './activity22.component.html',
  styleUrls: ['./activity22.component.css']
})
export class Activity22Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '22'; // se refiere al número de actividad
  activity: Activity = new Activity();
  heaven: any = [];
  path: any = [];
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    var width = 400;
    var height = 750;

    var self = this;

    var stage = new Konva.Stage({
      container: 'canvas',
      width: width,
      height: height,
    });

    var layer = new Konva.Layer();
    stage.add(layer);

    var backgroundImg = new Image();
    backgroundImg.src = './assets/img/actividades/22GratitudAna3/Camino.png';

    var background = new Konva.Image({
      image: backgroundImg,
      name: 'bascule',
      x: 0,
      y: 0,
      width: 400,
      height: 500,
      draggable: false,
    });
    layer.add(background);

    var basketImg = new Image();
    basketImg.src = './assets/img/actividades/22GratitudAna3/Canasta.png';

    var basket = new Konva.Image({
      image: basketImg,
      name: 'basket',
      x: 0,
      y: 500,
      width: 400,
      height: 263,
      draggable: false,
    });
    layer.add(basket);

    var path1 = new Konva.Group({
      name: 'path1',
      draggable: false,
    });
    var path1Rect = new Konva.Rect({
      x: 0,
      y: 400,
      width: 400,
      height: 100,
      // fill: 'grey',
      name: 'path1',
    });
    path1.add(path1Rect);

    layer.add(path1);

    var path2 = new Konva.Group({
      name: 'path2',
      draggable: false,
    });
    var path2Rect = new Konva.Rect({
      x: 0,
      y: 250,
      width: 100,
      height: 150,
      // fill: 'grey',
      name: 'path2',
    });
    path2.add(path2Rect);

    layer.add(path2);

    var path3 = new Konva.Group({
      name: 'path3',
      draggable: false,
    });
    var path3Rect = new Konva.Rect({
      x: 250,
      y: 190,
      width: 170,
      height: 30,
      // fill: 'grey',
      name: 'path3',
    });
    path3.add(path3Rect);

    layer.add(path3);

    var path4 = new Konva.Group({
      name: 'path4',
      draggable: false,
    });
    var path4Rect = new Konva.Rect({
      x: 110,
      y: 205,
      width: 140,
      height: 30,
      // fill: 'grey',
      name: 'path4',
    });
    path4.add(path4Rect);

    layer.add(path4);

    var path5 = new Konva.Group({
      name: 'path5',
      draggable: false,
    });
    var path5Rect = new Konva.Rect({
      x: 100,
      y: 235,
      width: 80,
      height: 30,
      // fill: 'grey',
      name: 'path5',
    });
    path5.add(path5Rect);

    layer.add(path5);

    var alegria = new Konva.Group({
      name: 'alegria',
      draggable: true,
    });

    var alegriaText = new Konva.Text({
      x: 10,
      y: 520,
      text: 'alegría',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 180,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var alegriaRect = new Konva.Rect({
      x: 10,
      y: 520,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#4caf5080',
      width: 180,
      height: alegriaText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    alegria.add(alegriaRect);
    alegria.add(alegriaText);

    layer.add(alegria);

    var tristeza = new Konva.Group({
      name: 'tristeza',
      draggable: true,
    });

    var tristezaText = new Konva.Text({
      x: 200,
      y: 520,
      text: 'tristeza',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 180,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var tristezaRect = new Konva.Rect({
      x: 200,
      y: 520,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#342361b8',
      width: 180,
      height: tristezaText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    tristeza.add(tristezaRect);
    tristeza.add(tristezaText);

    layer.add(tristeza);

    var paz = new Konva.Group({
      name: 'paz',
      draggable: true,
    });

    var pazText = new Konva.Text({
      x: 10,
      y: 570,
      text: 'paz',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 180,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var pazRect = new Konva.Rect({
      x: 10,
      y: 570,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#4caf5080',
      width: 180,
      height: pazText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    paz.add(pazRect);
    paz.add(pazText);

    layer.add(paz);

    var llanto = new Konva.Group({
      name: 'llanto',
      draggable: true,
    });

    var llantoText = new Konva.Text({
      x: 200,
      y: 570,
      text: 'llanto',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 180,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var llantoRect = new Konva.Rect({
      x: 200,
      y: 570,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#342361b8',
      width: 180,
      height: llantoText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    llanto.add(llantoRect);
    llanto.add(llantoText);

    layer.add(llanto);

    var adoracion = new Konva.Group({
      name: 'adoracion',
      draggable: true,
    });

    var adoracionText = new Konva.Text({
      x: 10,
      y: 620,
      text: 'adoración',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 180,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var adoracionRect = new Konva.Rect({
      x: 10,
      y: 620,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#4caf5080',
      width: 180,
      height: adoracionText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    adoracion.add(adoracionRect);
    adoracion.add(adoracionText);

    layer.add(adoracion);

    var afliccion = new Konva.Group({
      name: 'afliccion',
      draggable: true,
    });

    var afliccionText = new Konva.Text({
      x: 200,
      y: 620,
      text: 'aflicción',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 180,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var afliccionRect = new Konva.Rect({
      x: 200,
      y: 620,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#342361b8',
      width: 180,
      height: afliccionText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    afliccion.add(afliccionRect);
    afliccion.add(afliccionText);

    layer.add(afliccion);

    var desanimo = new Konva.Group({
      name: 'desanimo',
      draggable: true,
    });

    var desanimoText = new Konva.Text({
      x: 100,
      y: 670,
      text: 'desánimo',
      fontSize: 20,
      fontFamily: 'Calibri',
      fill: '#FFF',
      width: 180,
      height: 40,
      padding: 10,
      align: 'center',
    });

    var desanimoRect = new Konva.Rect({
      x: 100,
      y: 670,
      stroke: '#00000070',
      strokeWidth: 5,
      fill: '#342361b8',
      width: 180,
      height: desanimoText.height(),
      shadowColor: 'black',
      shadowBlur: 10,
      shadowOffsetX: 10,
      shadowOffsetY: 10,
      shadowOpacity: 0.2,
      cornerRadius: 10
    });

    desanimo.add(desanimoRect);
    desanimo.add(desanimoText);

    layer.add(desanimo);

    layer.on('dragend', function(evt) {
      var shape = evt.target;
      document.body.style.cursor = 'pointer';

      self.heaven = self.heaven.filter((word) => word !== shape.attrs.name);
      self.path = self.path.filter((word) => word !== shape.attrs.name);

      let exist = false;
      layer.children.forEach(function (group) {
        // do not check intersection with itself
        if (group === shape) {
          return;
        }
        else if(group.attrs.name.indexOf('path') !== -1) {
          if (haveIntersection(group.getClientRect(), shape.getClientRect())) {
            exist = true;
          }
        }
      });

      if(exist){
        self.path.push(shape.attrs.name);
      }
      else{
        self.heaven.push(shape.attrs.name);
      }

      console.log('cielo:', self.heaven);
      console.log('camino:', self.path);

    });

    function haveIntersection(r1, r2) {
      r2.height -= 20;
      return !(
        r2.x > r1.x + r1.width ||
        r2.x + r2.width < r1.x ||
        r2.y > r1.y + r1.height ||
        r2.y + r2.height < r1.y
      );
    }
  }

  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    if(this.heaven.length === 0 && this.path.length === 0){
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad arrastrando las palabras a la imagen');
    }
    else if(
      this.heaven.indexOf('alegria') !== -1 &&
      this.heaven.indexOf('adoracion') !== -1 &&
      this.heaven.indexOf('paz') !== -1 &&
      this.path.indexOf('tristeza') !== -1 &&
      this.path.indexOf('llanto') !== -1 &&
      this.path.indexOf('afliccion') !== -1 &&
      this.path.indexOf('desanimo') !== -1
    ){
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else{
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad dejando las palabras correctas en el cielo o en el camino');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
