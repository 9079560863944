import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity67',
  templateUrl: './activity67.component.html',
  styleUrls: ['./activity67.component.css']
})
export class Activity67Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '67'; // se refiere al número de actividad
  activity: Activity = new Activity();
  afirmaciones: any = [];
  Correctas: any = ['o', 'r', 'o', 'm', 'o', 'c', 'h', 'i', 'l', 'a', 't', 'u', 'n', 'i', 'c', 'a', 's', 'b', 'a', 's', 't', 'ó', 'n', 'a', 'l', 'i', 'm', 'e', 'n', 't', 'o'];

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    let self = this;
    $(document).ready(function () {
      //$("#ElegirNino").modal("show");
      let iguales = 0;
      let estado = '';
      $(".letra").on("keyup ", function () {
        $('.letra').each(function (i) {
          let palabras = $(this).val().toLowerCase();
          self.afirmaciones[i] = palabras;
          if (self.afirmaciones[i] == self.Correctas[i]) {
            $(this).css("border-bottom", "3px solid #4caf50");
          } else {
            $(this).css("border-bottom", "");
          }
        });
      });
      console.log(self.Correctas.length);
      $('input[type=text][max]:not([max=""])').on('input', function (ev) {
        var $this = $(this);
        var maxlength = $this.attr('max').length;
        var value = $this.val();
        if (value && value.length >= maxlength) {
          $this.val(value.substr(0, maxlength));
        }
      });
    });

  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    let self = this;
    let iguales = 0;
    $('.letra').each(function (i) {
      if (self.afirmaciones[i] == self.Correctas[i]) {
        iguales++;
      }
    });
    var numeracion = $('.text-Prim-Jesus');
    var secuencia = ['3', '2', '5', '1', '4'];
    var arraynumeracion = [];
    var iguales2 = 0;
    for (var i = 0; i < numeracion.length; i++) {
      arraynumeracion[i] = numeracion[i].value;
    }
    for (var i = 0; i < arraynumeracion.length; i++) {
      for (var j = i; j < (i + 1); j++) {
        if (arraynumeracion[i] == secuencia[i])
          iguales2++;

      }
    }
    console.log(iguales);
    if (iguales == self.Correctas.length) {
     
      
      if (iguales2==5) {
       
        $('#modalOk').modal('show');
        this.guardarActividad();
      } else {
        $('#modalFail').modal('show');
        $('.ayudas').text('Verifica que la enumeración de las preguntas es correcta. ' + iguales2 + '/' + secuencia.length);
      
      }
    } else {
      
      $('#modalFail').modal('show');
        $('.ayudas').text('Verifica que los campos sean los correctos. ' + iguales + '/' + self.Correctas.length);
    };
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
