export const environment = {
    production: true,
    firebase: {
      apiKey: "AIzaSyCmHOV3e854jBiV69f7fCiA5y1pdQ_Ak_U",
      authDomain: "valientesconjesus-ef0d7.firebaseapp.com",
      databaseURL: "https://valientesconjesus-ef0d7.firebaseio.com",
      projectId: "valientesconjesus-ef0d7",
      storageBucket: "valientesconjesus-ef0d7.appspot.com",
      messagingSenderId: "279046303376",
      appId: "1:279046303376:web:b79671285c798c6391b215",
      measurementId: "G-PB61C11KZE"
    }
  };
  