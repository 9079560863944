import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public user;
  constructor(public afAuth: AngularFireAuth, private router: Router) { }

  ngOnInit() {
    this.user= this.afAuth.user;
    this.afAuth.user.subscribe( val => this.user = val );
  }
  logout() {
    this.afAuth.auth.signOut().then( val => this.router.navigate(['/login'])  );
  }
}
