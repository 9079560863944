import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity32',
  templateUrl: './activity32.component.html',
  styleUrls: ['./activity32.component.css']
})
export class Activity32Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '32'; // se refiere al número de actividad
  activity: Activity = new Activity();
  afirmaciones = [];
  lstestados:any;
  Correctas = ['varón', 'dios', 've', 'aceite', 'acreedor', 'hijos', 'quede'];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;


    $(".frase").on("keyup ", function () {
      $('.frase').each(function (i) {
        let lstfrase = $(this).val().toLowerCase();
        self.afirmaciones[i] = lstfrase;
        if (self.afirmaciones[i] == self.Correctas[i]) {
          $(this).css("border-bottom", "3px solid #4caf50");
        } else {
          $(this).css("border-bottom", "");
        }
      });
    });
    $(".palabrasviuda-block").attr('active', 'false');
    $(".palabrasviuda-block").click(function () {
      var estado = $(this).attr('active');
      if (estado == 'false') {
        $(this).css("background-color", "#e3b631");
        $(this).attr('active', 'true');
      } else {
        if (estado == 'true') {
          $(this).css("background-color", "");
          $(this).attr('active', 'false');
        }
      }
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var iguales=0;
    
		for(var i=0;i<this.afirmaciones.length;i++) 
		{
			for(var j=i;j<(i+1);j++) 
			{
				if(this.afirmaciones[i]==this.Correctas[i])  
					iguales++; 
			}
		}
		console.log (iguales); 
    var estados = [];
    var completado = 0;
    var correctas = ['true', 'false', 'true', 'false', 'true'];
    $('.palabrasviuda-block').each(function(i) {     
     
      this.lstestados = $(this).attr('active');
      estados[i] = this.lstestados;
      //console.log(estados);
      if (estados[i] == correctas[i] && estados[i] == 'true') {
        $(this).css("background-color", "#4caf50");
        completado++;
      } else {
        $(this).css("background-color", "red ");

      }
    });
		if (iguales==7) {
      
      if(completado == 3){
        $('#modalOk').modal('show');
        this.guardarActividad();
      }else{
        $('#modalFail').modal('show');
        $('.ayudas').text('verifica que los hayas seleccinado las acciones corrctas.'+completado+'/3');
      }
		}else{
			$('#modalFail').modal('show');
			$('.ayudas').text('verifica que los campos sean los correctos.'+iguales+'/7');
		};
   
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
