import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;


@Component({
  selector: 'app-activity8',
  templateUrl: './activity8.component.html',
  styleUrls: ['./activity8.component.css']
})

export class Activity8Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '8'; // se refiere al número de actividad
  activity: Activity = new Activity();
  afirmaciones = [];
  Correctas = ['arco', 'nubes', 'veré', 'pacto', 'dios', 'ser', 'vivo', 'tierra'];
  words: any = [];
  tierra: any;
  hombre: any;
  holocausto: any;
  corazon: any;
  altar: any;
  olor: any;
  grato: any;
  siega: any;
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();
    this.tierra = document.getElementById('tierra') as HTMLInputElement | null;
    this.holocausto = document.getElementById('holocausto') as HTMLInputElement | null;
    this.hombre = document.getElementById('hombre') as HTMLInputElement | null;
    this.corazon = document.getElementById('corazon') as HTMLInputElement | null;
    this.altar = document.getElementById('altar') as HTMLInputElement | null;
    this.olor = document.getElementById('olor') as HTMLInputElement | null;
    this.grato = document.getElementById('grato') as HTMLInputElement | null;
    this.siega = document.getElementById('siega') as HTMLInputElement | null;
    //Acá iría el código de la actividad
    var self = this;
    $(".frase").on("keyup ", function () {
      $('.frase').each(function (i) {
        var lstfrase = $(this).val().toLowerCase();
        self.afirmaciones[i] = lstfrase;
        if (self.afirmaciones[i] == self.Correctas[i]) {
          $(this).css("border-bottom", "3px solid #4caf50");
        } else {
          $(this).css("border-bottom", "");
        }
      });
      console.log(self.afirmaciones);
    });
  }
  check(cell: string) {
    document.getElementById(cell).style.background = 'red';
    this.words.push(cell);

    if(this.words.indexOf('A2') !== -1 &&
      this.words.indexOf('A3') !== -1 &&
      this.words.indexOf('A4') !== -1 &&
      this.words.indexOf('A5') !== -1 &&
      this.words.indexOf('A6') !== -1 &&
      this.words.indexOf('A7') !== -1
    )
    {
      if (this.hombre != null) {
        this.hombre.checked = true;
        this.hombre.disabled = true;
      }
    }

    if(this.words.indexOf('B1') !== -1 &&
      this.words.indexOf('B2') !== -1 &&
      this.words.indexOf('B3') !== -1 &&
      this.words.indexOf('B4') !== -1 &&
      this.words.indexOf('B5') !== -1 &&
      this.words.indexOf('B6') !== -1
    )
    {
      if (this.tierra != null) {
        this.tierra.checked = true;
        this.tierra.disabled = true;
      }
    }

    if(this.words.indexOf('C1') !== -1 &&
      this.words.indexOf('C2') !== -1 &&
      this.words.indexOf('C3') !== -1 &&
      this.words.indexOf('C4') !== -1 &&
      this.words.indexOf('C5') !== -1 &&
      this.words.indexOf('C6') !== -1 &&
      this.words.indexOf('C7') !== -1 &&
      this.words.indexOf('C8') !== -1 &&
      this.words.indexOf('C9') !== -1 &&
      this.words.indexOf('C10') !== -1
    )
    {
      if (this.holocausto != null) {
        this.holocausto.checked = true;
        this.holocausto.disabled = true;
      }
    }

    if(this.words.indexOf('D1') !== -1 &&
      this.words.indexOf('D2') !== -1 &&
      this.words.indexOf('D3') !== -1 &&
      this.words.indexOf('D4') !== -1 &&
      this.words.indexOf('D5') !== -1 &&
      this.words.indexOf('D6') !== -1 &&
      this.words.indexOf('D7') !== -1
    )
    {
      if (this.corazon != null) {
        this.corazon.checked = true;
        this.corazon.disabled = true;
      }
    }

    if(this.words.indexOf('E1') !== -1 &&
      this.words.indexOf('E2') !== -1 &&
      this.words.indexOf('E3') !== -1 &&
      this.words.indexOf('E4') !== -1 &&
      this.words.indexOf('E5') !== -1 
    )
    {
      if (this.altar != null) {
        this.altar.checked = true;
        this.altar.disabled = true;
      }
    }

    if(this.words.indexOf('E7') !== -1 &&
      this.words.indexOf('E8') !== -1 &&
      this.words.indexOf('E9') !== -1 &&
      this.words.indexOf('E10') !== -1
    )
    {
      if (this.olor != null) {
        this.olor.checked = true;
        this.olor.disabled = true;
      }
    }

    if(this.words.indexOf('F3') !== -1 &&
      this.words.indexOf('F4') !== -1 &&
      this.words.indexOf('F5') !== -1 &&
      this.words.indexOf('F6') !== -1 &&
      this.words.indexOf('F7') !== -1 
    )
    {
      if (this.grato != null) {
        this.grato.checked = true;
        this.grato.disabled = true;
      }
    }

    if(this.words.indexOf('G4') !== -1 &&
      this.words.indexOf('G5') !== -1 &&
      this.words.indexOf('G6') !== -1 &&
      this.words.indexOf('G7') !== -1 &&
      this.words.indexOf('G8') !== -1
    )
    {
      if (this.siega != null) {
        this.siega.checked = true;
        this.siega.disabled = true;
      }
    }
  }
  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var iguales = 0;
    for (var i = 0; i < this.afirmaciones.length; i++) {
      for (var j = i; j < (i + 1); j++) {
        if (this.afirmaciones[i] == this.Correctas[i])
          iguales++;
      }
    }

    if (iguales == 8) {
      if(
        this.hombre.checked && this.hombre.disabled &&
        this.tierra.checked && this.tierra.disabled &&
        this.holocausto.checked && this.holocausto.disabled &&
        this.corazon.checked && this.corazon.disabled &&
        this.altar.checked && this.altar.disabled &&
        this.olor.checked && this.olor.disabled &&
        this.grato.checked && this.grato.disabled &&
        this.siega.checked && this.siega.disabled 
      ){
        $('#modalOk').modal('show');
        this.guardarActividad();
      }else {
        $('#modalFail').modal('show');
        $('.ayudas').text('Encuentra todas las palabras.');
      };
    } else {
      $('#modalFail').modal('show');
      $('.ayudas').html('verifica que los campos sean los correctos.<br>Parte 1: ' + iguales + '/8');
    };
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
