import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity68',
  templateUrl: './activity68.component.html',
  styleUrls: ['./activity68.component.css']
})
export class Activity68Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '68'; // se refiere al número de actividad
  activity: Activity = new Activity();
  arreglo1 = [];
  resp12 = ["JUAN", "JACOBO", "JACOBO", "SIMÓN", "JÚDAS", "MATEO", "PEDRO", "ANDRÉS"];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    function Capturar1() {
      var inputNombre = $('#nombre');
      inputNombre.value = "DYP";
    }
    var presionado = true;
    var presionado1 = true;
    var presionado2 = true;
    var presionado3 = true;

    $('.draggable-4').draggable({ revert: "valid" });
    function verificarDRAG(ui) {
      var text = ui.draggable.attr("id");
      if (text === "drag1") {
        return "TOMÁS";
      } else if (text === "drag2") {
        return "TADEO";
      } else if (text === "drag3") {
        return "SIMÓN";
      } else if (text === "drag4") {
        return "JACOBO";
      } else if (text === "drag5") {
        return "PEDRO";
      } else if (text === "drag6") {
        return "MATEO";
      } else if (text === "drag7") {
        return "JÚDAS";
      } else if (text === "drag8") {
        return "JUAN";
      } else if (text === "drag9") {
        return "JACOBO";
      } else if (text === "drag10") {
        return "FELIPE";
      } else if (text === "drag11") {
        return "BARTOLOMÉ";
      } else if (text === "drag12") {
        return "ANDRÉS";
      } else if (text === "drag0") {
        return "JÉSUS";
      }
    }
    $('.droppable-7').droppable({

      hoverClass: 'active',
      drop: function (e, ui) {
        var temp = $(this).attr('id');


        if (temp === "drop1") {
          var inputNombre = $('#nombre1');

          var temp1 = verificarDRAG(ui);
          inputNombre.val(temp1);
          self.arreglo1[0] = temp1;

          $(inputNombre).css("border-radius", "100px");
          $(inputNombre).css("background-color", "#aab664");
          $(inputNombre).css("font-weight", "700");
          $(inputNombre).css("font-size", "1.3 rem");
          $(inputNombre).css("font-style", "italic");

        } else if (temp === "drop2") {
          var inputNombre = $('#nombre2');

          var temp1 = verificarDRAG(ui);
          inputNombre.val(temp1);
          self.arreglo1[1] = temp1;

          $(inputNombre).css("border-radius", "100px");
          $(inputNombre).css("background-color", "#aab676");
          $(inputNombre).css("font-weight", "700");
          $(inputNombre).css("font-size", "1.3 rem");
          $(inputNombre).css("font-style", "italic");

        } else if (temp === "drop3") {
          var inputNombre = $('#nombre3');

          var temp1 = verificarDRAG(ui);
          inputNombre.val(temp1);
          self.arreglo1[2] = temp1;

          $(inputNombre).css("border-radius", "100px");
          $(inputNombre).css("background-color", "#aab688");
          $(inputNombre).css("font-weight", "700");
          $(inputNombre).css("font-size", "1.3 rem");
          $(inputNombre).css("font-style", "italic");

        } else if (temp === "drop4") {
          var inputNombre = $('#nombre4');

          temp1 = verificarDRAG(ui);
          inputNombre.val(temp1);
          self.arreglo1[3] = temp1;

          $(inputNombre).css("border-radius", "100px");
          $(inputNombre).css("background-color", "#6ab6aa");
          $(inputNombre).css("font-weight", "700");
          $(inputNombre).css("font-size", "1.3 rem");
          $(inputNombre).css("font-style", "italic");


        } else if (temp === "drop5") {
          var inputNombre = $('#nombre5');

          var temp1 = verificarDRAG(ui);
          inputNombre.val(temp1);
          self.arreglo1[4] = temp1;

          $(inputNombre).css("border-radius", "100px");
          $(inputNombre).css("background-color", "#6ab6bb");
          $(inputNombre).css("font-weight", "700");
          $(inputNombre).css("font-size", "1.3 rem");
          $(inputNombre).css("font-style", "italic");

        } else if (temp === "drop6") {
          var inputNombre = $('#nombre6');

          var temp1 = verificarDRAG(ui);
          inputNombre.val(temp1);
          self.arreglo1[5] = temp1;

          $(inputNombre).css("border-radius", "100px");
          $(inputNombre).css("background-color", "#aab6cc");
          $(inputNombre).css("font-weight", "700");
          $(inputNombre).css("font-size", "1.3 rem");
          $(inputNombre).css("font-style", "italic");

        } else if (temp === "drop7") {
          var inputNombre = $('#nombre7');

          var temp1 = verificarDRAG(ui);
          inputNombre.val(temp1);
          self.arreglo1[6] = temp1;

          $(inputNombre).css("border-radius", "100px");
          $(inputNombre).css("background-color", "#aab6dd");
          $(inputNombre).css("font-weight", "700");
          $(inputNombre).css("font-size", "1.3 rem");
          $(inputNombre).css("font-style", "italic");

        } else if (temp === "drop8") {
          var inputNombre = $('#nombre8');

          var temp1 = verificarDRAG(ui);
          inputNombre.val(temp1);
          self.arreglo1[7] = temp1;

          $(inputNombre).css("border-radius", "100px");
          $(inputNombre).css("background-color", "#aab6ff");
          $(inputNombre).css("font-weight", "700");
          $(inputNombre).css("font-size", "1.3 rem");
          $(inputNombre).css("font-style", "italic");

        }
      }

    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    console.log(this.arreglo1)

    var iguales = 0;

    if (this.resp12[0] == this.arreglo1[0]) {
      iguales++;
    } else if (this.resp12[0] == this.arreglo1[1]) {
      iguales++;
    }

    if (this.resp12[1] == this.arreglo1[1]) {
      iguales++;
    } else if (this.resp12[1] == this.arreglo1[0]) {
      iguales++;
    }

    if (this.resp12[2] == this.arreglo1[2]) {
      iguales++;
    }

    if (this.resp12[3] == this.arreglo1[3]) {
      iguales++;
    }

    if (this.resp12[4] == this.arreglo1[4]) {
      iguales++;
    }

    if (this.resp12[5] == this.arreglo1[5]) {
      iguales++;
    }

    if (this.resp12[6] == this.arreglo1[6]) {
      iguales++;
    } else if (this.resp12[6] == this.arreglo1[7]) {
      iguales++;
    }

    if (this.resp12[7] == this.arreglo1[7]) {
      iguales++;
    } else if (this.resp12[7] == this.arreglo1[6]) {
      iguales++;
    }

    if (iguales < 8) {
      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px")
      $('.ayudas').text('Verifica los nombres de los Apóstoles que cumplan con las afirmaciones en la oraciones. ' + iguales + '/8');
    }

    if ((iguales == 8)) {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
