import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity72',
  templateUrl: './activity72.component.html',
  styleUrls: ['./activity72.component.css']
})
export class Activity72Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '72'; // se refiere al número de actividad
  activity: Activity = new Activity();
  arreglo1 = [];
  arreglo2 = [true, true, true, true];
  resp12 = [' 15', ' 64', ' 54', ' 90'];

  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;

  
    var presionado = true;
    var presionado1 = true;
    var presionado2 = true;
    var presionado3 = true;

    $('.droppable-7').droppable({
      hoverClass: 'active',
      drop: function (e, ui) {
        var temp = $(this).attr('id');
        var text = ui.draggable.text().split(/:/);
        $(this).text("");
        var textdest1 = parseInt(temp);

        $(this).append("=   " + text[0]).addClass("rec-oper2").effect("bounce", { times: 5, direction: "up", distance: 50 }, 800,
          function () {
            $(this).css("background", "radial-gradient(transparent, gold 150%)");
            $(this).css("vertical-align", "middle");
            $(this).css("center", "0");
            $(this).css("font-size", "1.9rem");
          });

        if (temp === "drop1") {
          $("#select-result1").text("Jesús bendijo cinco panes y dos peces logrando " + text[1].toUpperCase()).addClass("rec-oper2");
          self.arreglo1[0] = text[0];

        } else if (temp === "drop2") {
          $("#select-result2").text("A pesar de Jesús esconderse, la gente lo... " + text[1].toUpperCase()).addClass("rec-oper2");
          self.arreglo1[1] = text[0];
        } else if (temp === "drop3") {
          $("#select-result3").text("Al ver tanta gente, Jesús tuvo ... " + text[1].toUpperCase()).addClass("rec-oper2");
          self.arreglo1[2] = text[0];
        } else {
          $("#select-result4").text("Cuando todos comieron y quedaron satisfechos, de lo que sobró se recogieron..." + text[1].toUpperCase()).addClass("rec-oper2");
          self.arreglo1[3] = text[0];
        }
      }
    });
    $('.draggable-4').draggable({ revert: true });

    $("#button").click(function () {
      if (presionado) {
        $("#effect1").switchClass("palabras-block5", "palabras-block9", 1000);
        presionado = false;
        self.arreglo2[0] = false;

      }
      else {
        $("#effect1").switchClass("palabras-block9", "palabras-block5", 1000);
        presionado = true;
        self.arreglo2[0] = true;
      }
    });

    $("#button1").click(function () {
      if (presionado1) {
        $("#effect2").switchClass("palabras-block6", "palabras-block10", 1000);
        presionado1 = false;
        self.arreglo2[1] = false;
      }
      else {
        $("#effect2").switchClass("palabras-block10", "palabras-block6", 1000);
        presionado1 = true;
        self.arreglo2[1] = true;
      }
    });

    $("#button2").click(function () {
      if (presionado2) {
        $("#effect3").switchClass("palabras-block7", "palabras-block11", 1000);
        presionado2 = false;
        self.arreglo2[2] = false;
      }
      else {
        $("#effect3").switchClass("palabras-block11", "palabras-block7", 1000);
        presionado2 = true;
        self.arreglo2[2] = true;
      }
    });

    $("#button3").click(function () {
      if (presionado3) {
        $("#effect4").switchClass("palabras-block8", "palabras-block12", 1000);
        presionado3 = false;
        self.arreglo2[3] = false;
      }
      else {
        $("#effect4").switchClass("palabras-block12", "palabras-block8", 1000);
        presionado3 = true;
        self.arreglo2[3] = true;
      }
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    console.log(this.arreglo1);
    console.log(this.arreglo2);

    var iguales = 0;
    var iguales1 = 0;
    var iguales2 = 0;

    if (this.resp12[0] === this.arreglo1[0]) {
      iguales++;
    }

    if (this.resp12[1] === this.arreglo1[1]) {
      iguales++;
    }

    if (this.resp12[2] === this.arreglo1[2]) {
      iguales++;
    }

    if (this.resp12[3] === this.arreglo1[3]) {
      iguales++;
    }

    if (false === this.arreglo2[0]) {
      iguales1++;
    }
    if (true === this.arreglo2[1]) {
      iguales2++;
    }

    if (true === this.arreglo2[2]) {
      iguales2++;
    }

    if (false === this.arreglo2[3]) {
      iguales1++;
    }

    if(iguales<4){

      $('#modalFail').modal('show');
      $('.ayudas').css('font-size',"20px")
      $('.ayudas').text('Verifica que las respuestas númericas correspondan con las operaciones en la parte superior. '+iguales+'/4' );
      if(iguales1<2){
        $('#modalFail').modal('show');
        $('.ayudas').css('font-size',"20px")
        $('.ayudas').text('Verifica que las frases seleccionadas son las correctas: '+iguales1+'/2'+" \n; y las no seleccionadas son las indicadas: "+iguales2+'/2' );
      }
    }

    if((iguales1==2&&iguales==4)&&iguales2==2){
      this.guardarActividad();
      $('#modalOk').modal('show');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
