import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
import Konva from 'konva';
declare var $: any;

@Component({
  selector: 'app-activity9',
  templateUrl: './activity9.component.html',
  styleUrls: ['./activity9.component.css']
})
export class Activity9Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '9'; // se refiere al número de actividad
  activity: Activity = new Activity();
  letters = ['LL', 'A', 'M', 'A', 'M', 'I', 'E', 'N', 'T', 'O'];
  title: string =  '';
  question: string = '';
  answerOk: boolean = false;
  answer: string = '';
  selected: number = 0;
  sentence : string = '';

  questions = [
    {
      title: 'Empieza por LL',
      question: 'Ese capítulo nos narra la historia de un:',
      answer: 'Llamado',
      rotation: 5,
      correct: false
    },
    {
      title: 'Empieza por A',
      question: 'Dios le dijo a Moisés que reuniera a los:',
      answer: 'Ancianos',
      rotation: 17,
      correct: false
    },
    {
      title: 'Empieza por M',
      question: 'Es llamado por Dios en medio de una zarza ardiendo:',
      answer: 'Moisés',
      rotation: 10,
      correct: false
    },
    {
      title: 'Empieza por A',
      question: 'También es el Dios de:',
      answer: 'Abrahán',
      rotation: 3,
      correct: false
    },
    {
      title: 'Empieza por M',
      question: 'Dios promete que en la nueva tierra tendrán:',
      answer: 'Miel',
      rotation: 15,
      correct: false
    },
    {
      title: 'Empieza por I',
      question: 'Dios les dice los hijos de:',
      answer: 'Israel',
      rotation: 8,
      correct: false
    },
    {
      title: 'Empieza por E',
      question: '¿Dónde estaba el pueblo de Dios?:',
      answer: 'Egipto',
      rotation: 20,
      correct: false
    },
    {
      title: 'Empieza por N',
      question: 'La respuesta que Dios espera del rey que tiene a su pueblo es:',
      answer: 'No',
      rotation: 7,
      correct: false
    },
    {
      title: 'Empieza por T',
      question: 'Su pueblo será llevado a otra:',
      answer: 'Tierra',
      rotation: 25,
      correct: false
    },
    {
      title: 'Empieza por O',
      question: 'Pertenecían a Jetro suegro de Moisés:',
      answer: 'Ovejas',
      rotation: 12,
      correct: false
    }
  ]
  
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
      this.idHero = this._route.snapshot.paramMap.get('id_heroe');
     }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    var width = 400;
    var height = 420;
    var self = this;

    Konva.angleDeg = false;
    var angularVelocity = 6;
    var angularVelocities = [];
    var lastRotation = 0;
    var controlled = false;
    var numWedges = 10;
    var angularFriction = 0.2;
    var target, activeWedge, stage, layer, wheel, pointer;
    var finished = false;
    
    var colors = [
      [228,41,100], //rojo
      [51,66,137], //azul oscuro
      [82,179,214], //azul cielo
      [131,162,63], //verde
      [246,222,42] // amarillo
    ]

    function getColor(n: number) {
      if(n >= 5){
        n -= 5;
      }
      return colors[n];
    }

    function getLetter(n: number){
      return self.letters[n];
    }

    function addWedge(n) {
      var s = getColor(n);
      var letter = getLetter(n);
      var r = s[0];
      var g = s[1];
      var b = s[2];
      var angle = (2 * Math.PI) / numWedges;

      var startColor = 'rgb(' + r + ',' + g + ',' + b + ')';
      var endColor = 'rgb(' + r + ',' + g + ',' + b + ')';

      var wedge = new Konva.Group({
        rotation: (2 * n * Math.PI) / numWedges,
      });

      var wedgeBackground = new Konva.Wedge({
        radius: 200,
        angle: angle,
        fillRadialGradientStartPoint: {x: 0, y: 0},
        fillRadialGradientStartRadius: 0,
        fillRadialGradientEndPoint: {x: 0, y: 0},
        fillRadialGradientEndRadius: 700,
        fillRadialGradientColorStops: [0, startColor, 1, endColor],
        id: n
      });

      wedge.add(wedgeBackground);

      var text = new Konva.Text({
        text: letter,
        fontFamily: 'Calibri',
        fontSize: 60,
        fontStyle: 'bold',
        fill: '#52b3d5',
        align: 'center',
        stroke: 'white',
        strokeWidth: 2,
        rotation: (Math.PI + angle) / 2,
        x: 170,
        y: 30,
        listening: false,
      });

      wedge.add(text);
      text.cache();

      wheel.add(wedge);
    }

    function animate(frame) {
      // handle wheel spin
      var angularVelocityChange =
        (angularVelocity * frame.timeDiff * (1 - angularFriction)) / 1000;
      angularVelocity -= angularVelocityChange;

      // activate / deactivate wedges based on point intersection
      var shape = stage.getIntersection({
        x: stage.width() / 2,
        y: 100,
      });

      if (controlled) {
        if (angularVelocities.length > 10) {
          angularVelocities.shift();
        }

        angularVelocities.push(
          ((wheel.rotation() - lastRotation) * 1000) / frame.timeDiff
        );
      } else {
        var diff = (frame.timeDiff * angularVelocity) / 1000;
        if (diff > 0.001) {
          wheel.rotate(diff);
        } else if (!finished && !controlled) {
          if (shape) {
            self.selected = shape.attrs.id;
            self.title = self.questions[self.selected].title;
            self.question = self.questions[self.selected].question;
            self.answerOk = self.questions[self.selected].correct;
            if(self.questions[self.selected].correct) {
              self.answer = self.questions[self.selected].answer;
            }
            else {
              self.answer = '';
            }
          }
          finished = true;
        }
      }
      lastRotation = wheel.rotation();

      if (shape) {
        if (shape && (!activeWedge || shape._id !== activeWedge._id)) {
          pointer.y(20);

          new Konva.Tween({
            node: pointer,
            duration: 0.3,
            y: 30,
            easing: Konva.Easings.ElasticEaseOut,
          }).play();

          if (activeWedge) {
            activeWedge.fillPriority('radial-gradient');
          }
          shape.fillPriority('fill');
          activeWedge = shape;
        }
      }
    }

    function init() {
      stage = new Konva.Stage({
        container: 'container',
        width: width,
        height: height,
      });
      layer = new Konva.Layer();
      wheel = new Konva.Group({
        x: stage.width() / 2,
        y: 210,
      });

      for (var n = 0; n < numWedges; n++) {
        addWedge(n);
      }
      pointer = new Konva.Wedge({
        fillRadialGradientStartPoint: {x: 0, y: 0},
        fillRadialGradientStartRadius: 0,
        fillRadialGradientEndPoint: {x: 0, y: 0},
        fillRadialGradientEndRadius: 30,
        fillRadialGradientColorStops: [0, 'white', 1, 'red'],
        stroke: 'white',
        strokeWidth: 2,
        lineJoin: 'round',
        angle: 1,
        radius: 30,
        x: stage.width() / 2,
        y: 33,
        rotation: -90,
        shadowColor: 'black',
        shadowOffsetX: 3,
        shadowOffsetY: 3,
        shadowBlur: 2,
        shadowOpacity: 0.5,
      });

      // add components to the stage
      layer.add(wheel);
      layer.add(pointer);
      stage.add(layer);

      var anim = new Konva.Animation(animate, layer);

      // wait one second and then spin the wheel
      setTimeout(function () {
        anim.start();
      }, 1000);
    }
    init();

    $('#girar').click(function(){
      let rotation = 0;
      for (let i = 0; i < self.questions.length; i++) {
        if(!self.questions[i].correct){
          rotation = self.questions[i].rotation;
          break;
        }
      }
      angularVelocity = 0;
      controlled = true;
      finished = false;
      wheel.rotation(
        rotation
      );
      controlled = false;
      angularVelocity = 20;
      angularVelocities = [];
    });
  }

  keyup(): void {
    if(this.answer.toLocaleLowerCase() === this.questions[this.selected].answer.toLocaleLowerCase()){
      this.answerOk = true;
      this.questions[this.selected].correct = true;
      this.sentence = '';
      for (let i = 0; i < this.questions.length; i++) {
        if(this.questions[i].correct){
          this.sentence += this.letters[i];
        }
        else{
          this.sentence += '_';
        }
      }
    }
    else{
      this.answerOk = false;
    }
  }

  getInfoHeroe(){
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
      .subscribe(val => {
        this.heroe = val.data() as Heroe;
      });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity(){
    this._activityService.getById(this.idActivity)
			.subscribe(val => {
				this.activity = val.data() as Activity;
			});
  }

  finalizar() : void {
    let sumCorrect = 0;
    for (let i = 0; i < this.questions.length; i++) {
      if(this.questions[i].correct){
        sumCorrect++
      }
    }

    if(sumCorrect === this.questions.length){
      $('#modalOk').modal('show');
      this.guardarActividad();
    }
    else{
      $('#modalFail').modal('show');
      $('.ayudas').text('Completa la actividad respondiendo todas las preguntas de la ruleta de manera correcta');
    }
  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if(this.idHero !== null){
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData =  val.data() as Heroe;
        let activities = heroeData.activities;
        if(activities.indexOf(this.idActivity) === -1){
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
