import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity60',
  templateUrl: './activity60.component.html',
  styleUrls: ['./activity60.component.css']
})
export class Activity60Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '60'; // se refiere al número de actividad
  activity: Activity = new Activity();
  productOrder=['0','0','0','0','0','0'];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;
    $(function () {

      var inputNombre1 = $('#nombre1');
      inputNombre1.val('1');

      var inputNombre2 = $('#nombre2');
      inputNombre2.val('2');

      var inputNombre3 = $('#nombre3');
      inputNombre3.val('3');

      var inputNombre4 = $('#nombre4');
      inputNombre4.val('4');

      var inputNombre5 = $('#nombre5');
      inputNombre5.val('5');

      var inputNombre6 = $('#nombre6');
      inputNombre6.val('6');


      $("#sortable").sortable({
        update: function (event, ui) {
           self.productOrder = $(this).sortable('toArray').toString();

        }
      }).disableSelection();

    });

  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
   //CORREGIDO
				//-----------------------
				var iguales = 0;
				//console.log(productOrder.indexOf(2));

				var resp = ['3',',','2',',','1',',','5',',','6',',','4'];

				//console.log(this.productOrder);

				for(var i=0;i<11;i++) 
				{	
					if(this.productOrder[i]!=","){
						if(this.productOrder[i]==resp[i]){
							iguales++;
						}
					}
				}

				if(iguales<6){
					$('#modalFail').modal('show');
					$('.ayudas').css('font-size',"20px")
					$('.ayudas').text('verifica que las posiciones sean correctas. '+iguales+'/6' );
				}
				else{
					$('#modalOk').modal('show');
          this.guardarActividad();
				}

  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
