import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { NgForm } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
declare var $ : any;

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent implements OnInit {

  user : User = new User();
  enableFiels = true;
  @Input() uid : string;
  displayErros = "none";
  countries = [
    { code:"COL", name:"Colombia" },
    { code:"ARG", name:"Argentina" },
    { code:"BOL", name:"Bolivia" },
    { code:"CHL", name:"Chile" },
    { code:"CRI", name:"Costa Rica" },
    { code:"CUB", name:"Cuba" },
    { code:"ECU", name:"Ecuador" },
    { code:"SLV", name:"El Salvador" },
    { code:"ESP", name:"España" },
    { code:"GTM", name:"Guatemala" },
    { code:"HND", name:"Honduras" },
    { code:"MEX", name:"Mexico" },
    { code:"NIC", name:"Nicaragua" },
    { code:"PAN", name:"Panama" },
    { code:"PRY", name:"Paraguay" },
    { code:"PER", name:"Peru" },
    { code:"PRI", name:"Puerto Rico" },
    { code:"DOM", name:"Rep. Dominican" },
    { code:"URY", name:"Uruguay" },
    { code:"VEN", name:"Venezuela" }
  ]
  constructor(public afAuth: AngularFireAuth, public _userService: UserService) { 

  }

  ngOnInit() {
    this.uid = this.afAuth.auth.currentUser.uid; 
    this._userService.getUser(this.uid)
      .subscribe(val =>{
        this.user = val.data() as User; 
        if(val === undefined){
        }
        if(this.user.methodAuthenticate == "email"){
          this.enableFiels=false;
        }
      });
  }

  saveUser(form:NgForm){
    
    if(form.invalid){
      this.displayErros="initial";
      return ;
    }
    this._userService.updateUser(this.user).then(() => {
      $('#exampleModalCenter4').modal('hide');
      location.reload();
    });
  }

}
