import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/models/activity';
import { Heroe } from 'src/app/models/heroe';
import { ActivityService } from 'src/app/services/activities.service';
import { HeroeService } from 'src/app/services/heroe.service';
declare var $: any;

@Component({
  selector: 'app-activity62',
  templateUrl: './activity62.component.html',
  styleUrls: ['./activity62.component.css']
})
export class Activity62Component implements OnInit {
  heroes: Heroe[] = [];
  heroe: Heroe = new Heroe();
  idHero: string = "";
  idActivity: string = '62'; // se refiere al número de actividad
  activity: Activity = new Activity();
  resp1 = ['j', 'e', 's', 'ú', 's'];
  resp2 = ['d', 'i', 'a', 'b', 'l', 'o'];
  iguales = [0, 0, 0, 0, 0];
  iguales1 = [0, 0, 0, 0, 0, 0];
  iguales2 = [0, 0, 0, 0, 0];
  iguales3 = [0, 0, 0, 0, 0];
  banderax = [true, true, true, true, true, true, true, true, true, true, true, true];
  banderaAnuncio = [true, true, true, true, true, true, true, true, true, true, true, true];
  constructor(public afAuth: AngularFireAuth,
    public _heroeService: HeroeService,
    private _route: ActivatedRoute,
    private router: Router,
    public _activityService: ActivityService,) {
    this.idHero = this._route.snapshot.paramMap.get('id_heroe');
  }

  ngOnInit() {
    //Obtener la información del heroe
    this.getInfoHeroe();
    //Obtener la información de la actividad
    this.getInfoActivity();

    //Acá iría el código de la actividad
    var self = this;

    $(".pal1").change(function () {
      let letinput = $(this).attr('id');
      let var1 = letinput[letinput.length - 1];

      if ($(this).val() == self.resp1[var1 - 1] || $(this).val().toLowerCase() == self.resp1[var1 - 1] || (var1 == 4 && ($(this).val().toLowerCase() == "u" || $(this).val().toLowerCase() == "ú"))) {

        $(this).css("border-bottom", "3px solid lightgreen");
        self.iguales[var1 - 1] = 1;

      } else {
        self.iguales[var1 - 1] = 0;
        $(this).css("border-bottom", "3px solid red");
      }
    });

    $(".pal2").change(function () {
      let letinput = $(this).attr('id');
      let var1 = letinput[letinput.length - 1];

      if ($(this).val() == self.resp2[var1 - 1] || $(this).val().toLowerCase() == self.resp2[var1 - 1]) {
        $(this).css("border-bottom", "3px solid lightgreen")
        self.iguales1[var1 - 1] = 1;
      }
      else {
        self.iguales1[var1 - 1] = 0;
        $(this).css("border-bottom", "3px solid red");
      }
    });

    $(".pal3").change(function () {
      let letinput = $(this).attr('id');
      let var1 = letinput[letinput.length - 1];


      if ($(this).val() == self.resp1[var1 - 1] || $(this).val().toLowerCase() == self.resp1[var1 - 1] || (var1 == 4 && ($(this).val().toLowerCase() == "u" || $(this).val().toLowerCase() == "ú"))) {
        $(this).css("border-bottom", "3px solid lightgreen")
        self.iguales2[var1 - 1] = 1;
      }
      else {
        self.iguales2[var1 - 1] = 0;
        $(this).css("border-bottom", "3px solid red");
      }
    });

    $(".pal4").change(function () {
      let letinput = $(this).attr('id');
      let var1 = letinput[letinput.length - 1];


      if ($(this).val() == self.resp1[var1 - 1] || $(this).val().toLowerCase() == self.resp1[var1 - 1] || (var1 == 4 && ($(this).val().toLowerCase() == "u" || $(this).val().toLowerCase() == "ú"))) {
        $(this).css("border-bottom", "3px solid lightgreen")
        self.iguales3[var1 - 1] = 1;
      }
      else {
        self.iguales3[var1 - 1] = 0;
        $(this).css("border-bottom", "3px solid red");
      }
    });

    $('.circ').click(function () {
      let val = parseInt($(this).attr("id"));

      if (self.banderax[val - 1] == true) {
        $(this).css("background", "#c9ffb2");
        $(this).css("text-align", "center");
        $(this).css("vertical-align", "middle");
        $(this).css("align-items", "center");
        $(this).css("font-size", "24px");
        $("#input" + val + val).val("X");
        $("#input" + val + val).css("color", "blue");

        self.banderax[val - 1] = false;
      } else {
        $(this).css("background", "#ffffff00");
        $("#input" + val + val).val("");
        self.banderax[val - 1] = true;
      }
    });

    $(".spans").click(function () {
      let val = $(this).attr("id");
      let pos = val[2];

      if (self.banderaAnuncio[pos - 1] == true) {
        $(this).css("text-decoration-line", "underline");
        $(this).css("text-decoration-color", "yellow");
        $(this).css("background-color", "lightblue");
        $(this).css("border-radius", "100px")
        $(this).css("font-size", "18px")
        $(this).css("color", "#c9ffb2");
        $(this).css("background", "repeating-radial-gradient(#8094f0,transparent 95%)");
        self.banderaAnuncio[pos - 1] = false;
      } else {
        $(this).css("background", "#ffffff00");
        $(this).css("text-decoration-line", "");
        $(this).css("font-size", "11px");
        $(this).css("color", "");
        self.banderaAnuncio[pos - 1] = true;
      }
    });
  }

  getInfoHeroe() {
    if (this.idHero) {
      this._heroeService.getHeroe(this.idHero)
        .subscribe(val => {
          this.heroe = val.data() as Heroe;
        });
    } else {
      let uid = this.afAuth.auth.currentUser.uid;
      $("#ChooseHero").modal('show');
      this._heroeService.getHeroesByUserId_reactive(this.afAuth.auth.currentUser.uid).subscribe((val: any) => {
        this.heroes = val;
      });
    }
  }

  getInfoActivity() {
    this._activityService.getById(this.idActivity)
      .subscribe(val => {
        this.activity = val.data() as Activity;
      });
  }

  finalizar(): void {
    var igual = 0;
    var res1 = 0;
    var res2 = 0;
    var res3 = 0;
    var res4 = 0;

    console.log(this.iguales);
    console.log(this.iguales1);
    console.log(this.iguales2);
    console.log(this.iguales3);

    for (var i = 0; i < this.iguales.length; i++) {
      if (this.iguales[i] == 1)
        res1++;
    }
    for (var i = 0; i < this.iguales1.length; i++) {
      if (this.iguales1[i] == 1)
        res2++;
    }
    for (var i = 0; i < this.iguales2.length; i++) {
      if (this.iguales2[i] == 1)
        res3++;
    }
    for (var i = 0; i < this.iguales3.length; i++) {
      if (this.iguales3[i] == 1)
        res4++;
    }
    console.log(res1 + res2 + res3 + res4);
    igual = res1 + res2 + res3 + res4;

    if (igual < 21) {
      var sum = res1 + res2 + res3 + res4;

      $('#modalFail').modal('show');
      $('.ayudas').css('font-size', "20px");
      $('.ayudas').text('Verifica que hayas escrito la letras válidas en la primera sección. '+igual+'/21' );

    }
    else {
      $('#modalOk').modal('show');
      this.guardarActividad();
    }

  }

  //funcion para guardar la actividad en la base de datos del niño
  guardarActividad(): void {
    var audio = new Audio('assets/audio/KidsCheering.mp3');
    audio.play();
    if (this.idHero !== null) {
      this._heroeService.getHeroe(this.idHero).subscribe(val => {
        const heroeData = val.data() as Heroe;
        let activities = heroeData.activities;
        if (activities.indexOf(this.idActivity) === -1) {
          activities.push(this.idActivity);
          let heroe = val.ref;
          heroe.update({ "activities": activities });
        }
      });
    }
  }

}
